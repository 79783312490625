// @ts-nocheck

import React, {FunctionComponent, useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Col, DatePicker, Form, Input, InputNumber, message, Row, Spin} from "antd";
import {
    ClientService,
    ImportDetailRequest,
    ImportRequest,
    ItemResponse,
    SupplierResponse
} from "@cubetiq/computer-web-sdk";
import SelectCustom from "../SelectCustom";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ModalCustom from "../ModalCustom";
import SerialNumberForm from "../../pages/import/form/SerialNumberForm";
import moment from "moment";


const Container = styled.div`
  margin-top: 30px;
  min-width: 1000px;
  .ant-input-number-input {
    text-align: center;
  }
`

const ImportButton = styled(Button)`
  background-color: #00bfff;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-family: 'Poppins', sans-serif;
  width: 120px;
  transition: 0.2s;

  &:hover {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }
  
  &:focus {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }
  
  &:active {
    transform: scale(0.9);
  }
`


const ButtonSubmit = styled(Button)`
  width: 100%;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 5px;
  border: none;
  background-color: #19aa8d;
  color: white;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  transition: 0.2s;

  &:hover {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }

  &:focus {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.9);
  }
`


const SerialButton = styled(Button)`
  
  transition: 0.2s;
  width: 100%;
  
  &:active {
    transform: scale(0.9);
  }
  
`
const { TextArea } = Input

const client = ClientService.getInstance()
const ImportForm: FunctionComponent = () => {

    const form = Form.useForm()[0]

    const onReset = () => {
        form.resetFields()
    }

    const [suppliers, setSuppliers] = useState<Array<SupplierResponse>>([])
    const [items, setItems] = useState<Array<ItemResponse>>([])
    const [isCreate, setIsCreate] = useState(false)
    const [date, setDate] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)

    const onIsCreateHandle = (value: number) => {
        setIsCreate(value > 0)
    }

    useEffect(() => {
        client.getAllSupplierService().then(r => {
            setSuppliers(r.data)
        })
        client.getAllItemsService({paged: false}).then(r => {
            setItems(r.data)
        })
    }, [])


    const onFinish = (values: any) => {


        setIsLoading(true)

        const details: Array<ImportDetailRequest> = []

        Array.from(values["items"]).forEach((it:any,index)=> {
            const itemId = JSON.parse(it["item"])["id"]
            const req : ImportDetailRequest = {
                itemId: itemId,
                qty: it["qty"],
                amount: it["cost"],
                serials: serials[index]
            }
            details.push(req)
        })

        const request: ImportRequest = {
            importDate: date,
            invoiceNumber: values["invoiceNumber"],
            memo: values["memo"],
            qty: values["qty"],
            supplierCode: JSON.parse(values["supplier"])["code"],
            details: details
        }





        client.createImportService(request).then(r => {
            message.success("Create successfully").then()
            onReset()
            setIsCreate(false)
        }).catch((r)=> {
            console.log(r)
        }).finally(()=> setIsLoading(false))

    }

    const onFinishFailed = (errorInfo: any) => {

    }

    const onDateChange = (date: any, dateString: any) => {
        setDate(dateString)
    }


    const [serials, setSerials] = useState<any[]>([])
    const [isSerial, setIsSerial] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined)
    const [serialLimit,setSerialLimit] = useState<number[]>([])

    const onIsSerialHandle = () => {
        setIsSerial(!isSerial)
    }

    const onFinishSerialNumber = (values: any) => {
        if (currentIndex !== undefined) {
            serials[currentIndex] = values["serials"]
            setSerials([...serials])
            onIsSerialHandle()
        }
    }


    const ModalSerial = () => {
        if (currentIndex === undefined) return <></>
        return (
            <ModalCustom visible={isSerial}
                         onCancel={onIsSerialHandle}
                         footer={null}
                         children={<SerialNumberForm
                             limit={serialLimit[currentIndex]}
                             onFinish={onFinishSerialNumber}
                             values={serials[currentIndex] ?? ['']}/>}/>
        )
    }





    return (
        <Container>
            <ModalSerial/>
            <Form onFinish={onFinish}
                  layout={"vertical"}
                  onFinishFailed={onFinishFailed}
                  form={form}>


                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item name={"invoiceNumber"}
                                   required
                                   label={"Invoice"}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item name={"importDate"}
                                   required
                                   rules={[
                                       {
                                           required: true, message: "Please choose date"
                                       }
                                   ]}
                                   label={"Import Date"}>
                            <DatePicker onChange={onDateChange}
                                        style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item name={"supplier"}
                                   rules={[
                                       {
                                           required: true, message: "Please choose supplier"
                                       }
                                   ]}
                                   label={"Supplier"}>
                            <SelectCustom data={suppliers}/>
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={24}>
                        <Form.Item name={"memo"}
                                   label={"Descriptions"}>
                            <TextArea/>
                        </Form.Item>
                    </Col>

                </Row>




                <Form.List name="items">
                    {(fields, {add, remove}) => (
                        <>
                            <Row>
                                <Col md={24} lg={24}>
                                    <Form.Item>
                                        <ImportButton children={"Add Item"}
                                                      onClick={() => {
                                                          add()
                                                          onIsCreateHandle(fields.length + 1)
                                                      }}
                                                      icon={<PlusCircleOutlined style={{fontSize: 18}}/>}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {fields.map((field,index) => (
                                <div key={field.key}>

                                    <Row gutter={[15, 15]}>
                                        <Col span={14}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'item']}
                                                fieldKey={[field.fieldKey, 'item']}
                                                rules={[{required: true, message: 'Please choose items to import!'}]}
                                            >
                                                <SelectCustom data={items} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={3}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'serial']}
                                                fieldKey={[field.fieldKey, 'serial']}>
                                                <SerialButton children={"Serial"}
                                                        onClick={() => {
                                                            setCurrentIndex(index)
                                                            onIsSerialHandle()
                                                        }}/>
                                            </Form.Item>
                                        </Col>

                                        <Col span={3}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'qty']}
                                                fieldKey={[field.fieldKey, 'qty']}
                                                rules={[{required: true, message: 'Please input quantity !'}]}
                                                initialValue={1}
                                            >
                                                <InputNumber placeholder="Quantity"
                                                             onChange={v => {
                                                                 //Set Limit for create serial by items qty
                                                                 serialLimit[index] = Number(v)
                                                                 setSerialLimit([...serialLimit])

                                                                 //Remove serial when limit has been change
                                                                 serials[index] = Array(Number(v)).fill("")
                                                                 setSerials([...serials])

                                                                 return v
                                                             }}
                                                             min={1}
                                                             style={{width: "100%"}}/>
                                            </Form.Item>
                                        </Col>


                                        <Col span={3}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'cost']}
                                                fieldKey={[field.fieldKey, 'cost']}
                                                rules={[{required: true, message: 'Please input cost'}]}
                                            >
                                                <InputNumber placeholder="Cost"
                                                             prefix={"$"}
                                                             min={0}
                                                             style={{width: "100%"}}/>

                                            </Form.Item>
                                        </Col>

                                        <Col span={1} style={{paddingTop: 5}}>
                                            <MinusCircleOutlined onClick={() => {
                                                remove(field.name)
                                                serials[index] = []
                                                setSerials([...serials])
                                                onIsCreateHandle(fields.length - 1)
                                            }}/>
                                        </Col>
                                    </Row>

                                </div>
                            ))}
                        </>
                    )}
                </Form.List>


                {
                    isCreate &&
                    <Row>
                        <Col md={24} lg={24}>
                            <Spin spinning={isLoading} size={"default"}>
                                <ButtonSubmit htmlType={"submit"} children={"Create"}/>
                            </Spin>
                        </Col>
                    </Row>
                }

            </Form>
        </Container>
    )
}

export default ImportForm