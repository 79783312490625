import {DeleteFilled, EditFilled, PlusCircleFilled, QuestionCircleOutlined} from "@ant-design/icons"
import {BrandResponse, CategoryResponse, ClientService} from "@cubetiq/computer-web-sdk"
import {Button, message, Modal, Popconfirm, Table, Tooltip} from "antd"
import {ColumnType} from "antd/es/table"
import React, {FunctionComponent, useEffect, useState} from "react"
import styled from "styled-components"
import {AddIconCustom} from "../../pages/import"
import {getErrorMessage} from "../../util/utils"
import CreateCategoryForm from "../form/CreateCategoryForm"
import CreateSubCategoryForm from "../form/CreateSubCategoryForm"
import UpdateCategoryForm from "../form/UpdateCategoryForm"
import ModalCustom from "../ModalCustom"
import SubCategoryTable from "./SubCategoryTable"


const TableCustom = styled(Table)`
   padding: 20px 0px 0px 0px;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`


const Container = styled.div`

`


const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`


const PlusIcon = styled(PlusCircleFilled)`
    font-size: 24px;
    color: #19aa8d;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`



const CreateButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 120px;
    &:hover {
        background-color: #00bfff;
        color: white;
        opacity: 0.8;
    }
    &:active {
        background-color: black;
        color: white;
        opacity: 0.8;
    }
`


const ReloadButton = styled(Button)`
    background-color: #19aa8d;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 120px;
    &:hover {
        background-color: #19aa8d;
        color: white;
        opacity: 0.8;
    }
    &:active {
        background-color: black;
        color: white;
        opacity: 0.8;
    }
    margin-left: 10px;
`




const EditIcon = styled(EditFilled)`
    font-size: 24px;
    color: #00bfff;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`


const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`



const { confirm } = Modal;

const client = ClientService.getInstance()
const CategoryTable:FunctionComponent = () => {


    const [categories,setCategories] = useState<Array<CategoryResponse>>()
    const [isLoading,setIsLoading] =useState(false)


    useEffect(()=>{
        onLoadData()
    },[])






    const [brands,setBrands] = useState<Array<BrandResponse>>()
   
    const [isCreateSubCategory,setIsCreateSubCategory] = useState(false)




    const [isCreateCategory,setIsCreateCategory] = useState(false)

    const [isCategoryUpdate,setIsCategoryUpdate] = useState(false)

    const [selectedCategory,setSelectedCategory] = useState<CategoryResponse>()


    const onIsCategoryUpdateHandle = () => {
        setIsCategoryUpdate(!isCategoryUpdate)
    }

    const onIsCreateCategoryHandle = () => {
        setIsCreateCategory(!isCreateCategory)
    }



    const onIsCreateSubCategoryHandle =() => {
        setIsCreateSubCategory(!isCreateSubCategory)
    }


    useEffect(()=>{
        client.getAllBrandService({
            paged: false
        }).then(r => {
            setBrands(r.data)
        })
    },[])


    const onRemoveCateogry = (category:CategoryResponse) => {
        client.deleteCategoryService(category.code).then( (r) => {
            const current = categories?.filter(it => it.id !== category.id)
            setCategories([...current || []])
            message.success("Remove Category Successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }



    const columns: Array<ColumnType<any>> = [
        {
            title: 'Category Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Category Name',
            dataIndex: 'name',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Icon',
            dataIndex: 'config',
            align: "center",
            key: 'code',
            width: 200,
            render:v => {
                return(
                    <ImageCustom src={v.icon}/>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: "center",
            key: 'code',
            width: 200,
            render:(v,row) =>{
                return(
                  <>
                    <Tooltip placement={"top"} title={"Create SubCategory"}>
                        <PlusIcon onClick={()=>{
                            setSelectedCategory(row)
                            onIsCreateSubCategoryHandle()
                        }}/>
                    </Tooltip>

                    <Tooltip placement={"top"} title={"Update brand"}>
                        <EditIcon onClick={()=>{
                            setSelectedCategory(row)
                            onIsCategoryUpdateHandle()
                        }}/>
                    </Tooltip>

                    <Popconfirm title="Are you sure to delete this category？" 
                                onConfirm={()=> onRemoveCateogry(row)}
                                icon={<QuestionCircleOutlined 
                                style={{ color: 'red', fontSize: 16 }} />}>
                        <DeleteIcon/>
                    </Popconfirm>
                       
                  </>
                )
            }
        }
    ]

    
    const keyChange = (key: any): string => {
        return key.id
    }

    


    const CreateSubCategory = () => {
        if (selectedCategory === undefined)
            return <></>
        return (
            <ModalCustom visible={isCreateSubCategory}
                footer={null}
                width={500}
                onCancel={onIsCreateSubCategoryHandle}
                children={<CreateSubCategoryForm category={selectedCategory}  />} />
        )
    }

    const UpdateCategory = () => {
        if (selectedCategory === undefined)
        return <></>
        return (
            <ModalCustom visible={isCategoryUpdate}
                footer={null}
                width={500}
                onCancel={onIsCategoryUpdateHandle}
                children={<UpdateCategoryForm category={selectedCategory} />} />
        )
    }

    const onLoadData = () => {
        setIsLoading(true)
        client.getAllCategoryService({
            paged: false
        }).then(r => {
            setCategories(r.data)
        }).finally(()=> setIsLoading(false))
    }


    


    return(
       <Container>
           <CreateSubCategory/>

           <ModalCustom visible={isCreateCategory}
                footer={null}
                width={500}
                onCancel={onIsCreateCategoryHandle}
                children={<CreateCategoryForm/>} />


            <UpdateCategory/>

            <Tooltip title={"Create brand"} placement={"top"}>
                <CreateButton children={"Create"} onClick={onIsCreateCategoryHandle} icon={<AddIconCustom/>}/>
            </Tooltip>

            <Tooltip title={"Reload data"} placement={"top"}>
                <ReloadButton children={"Reload"} onClick={onLoadData}/>
            </Tooltip>
           
           {
                <TableCustom columns={columns}
                    bordered
                    loading={isLoading}
                    rowKey={(value) => keyChange(value)}
                    expandable={{
                        expandedRowRender: (row: any, index: number) => {
                            return(
                                <SubCategoryTable subCategory={row.subCategory} key={index} />
                            )
                        },
                    }}
                    dataSource={categories} />
           }
       </Container>
    )
}

export default CategoryTable