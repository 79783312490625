import {DiscountType} from "@cubetiq/computer-web-sdk"
import {Select, SelectProps} from "antd"
import React, {FunctionComponent} from "react"
import styled from "styled-components"



const { Option } = Select




interface SelectDiscountTypeProps extends SelectProps<any>{

}



const SelectDiscountType: FunctionComponent<SelectDiscountTypeProps> = (props) => {


    return (
        <Select defaultValue={DiscountType.PERCENTAGE} style={{ minWidth: 120 }} {...props}>
            <Option value={DiscountType.PERCENTAGE}>{DiscountType.PERCENTAGE}</Option>
            <Option value={DiscountType.CASH}>{DiscountType.CASH}</Option>
        </Select>
    )
}

export default SelectDiscountType