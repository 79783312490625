import React,{ FunctionComponent } from "react";
import styled from "styled-components";
import StockTable from "../../components/table/StockTable";
import { withAuth } from "../../provider/AuthWrapper";
import MainLayout from "../MainLayout";




const Container = styled.div`

`




const StockPage:FunctionComponent = () => {
    return(
       <MainLayout>
           <Container>
               <StockTable/>
           </Container>
       </MainLayout>
    )
}   

export default withAuth()(StockPage)