
import { BrandResponse, ClientService, ModelResponse, SubCategoryResponse } from "@cubetiq/computer-web-sdk"
import { SubCategoryRequest } from "@cubetiq/computer-web-sdk/dist/interface/request/sub_category/SubCategoryRequest"
import { ModelRequest } from "@cubetiq/computer-web-sdk/src/interface/request/model/ModelRequest"
import { Button, Menu, message, Spin } from "antd"
import { Form, Input } from "antd"
import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"





const Container = styled.div`


`


interface UpdateSubCategoryFormProps {
    subCategory: SubCategoryResponse
}

const ButtonSubmit= styled(Button)`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 5px;
    border: none;
    background-color: #19aa8d;
    color: white;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
`





const client = ClientService.getInstance()
const UpdateSubCategoryForm: FunctionComponent<UpdateSubCategoryFormProps> = (props) => {
    const [isLoading,setIsLoading] = useState(false)
    const form = Form.useForm()[0]
    const { subCategory } = props

    const onFinish = (values:any) => {
        setIsLoading(true)

        const request:SubCategoryRequest = {
            name: values["name"],
        }


        client.updateSubCategoryService(subCategory.code!!,request).then(r => {
            message.success("Update model successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=> setIsLoading(false))

        form.resetFields()
    }


    


    return (
        <Container>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>
                <Form.Item name={"name"}
                    hasFeedback
                    initialValue={subCategory.name}
                    label={"SubCategory Name"}
                    required rules={[
                        {
                            required: true,
                            message: "Please input name"
                        }]}>
                        <Input/>
                </Form.Item>


                <Form.Item style={{marginBottom: 0}}>
                    <Spin spinning={isLoading}>
                        <ButtonSubmit children={"Save"} htmlType={"submit"}/>
                    </Spin>
                </Form.Item>
            </Form>
        </Container>
    )

}

export default UpdateSubCategoryForm