
import { BrandResponse, CategoryResponse, ClientService, SubCategoryResponse, SupplierRequest } from "@cubetiq/computer-web-sdk"
import { SubCategoryRequest } from "@cubetiq/computer-web-sdk/dist/interface/request/sub_category/SubCategoryRequest"
import { ModelRequest } from "@cubetiq/computer-web-sdk/src/interface/request/model/ModelRequest"
import { Button, message, Spin } from "antd"
import { Form, Input } from "antd"
import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"





const Container = styled.div`


`


interface CreateSupplierFormProps {
   
}

const ButtonSubmit= styled(Button)`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 5px;
    border: none;
    background-color: #19aa8d;
    color: white;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
`




const { TextArea } = Input;
const client = ClientService.getInstance()
const CreateSupplierForm: FunctionComponent<CreateSupplierFormProps> = (props) => {
    const [isLoading,setIsLoading] = useState(false)
    const form = Form.useForm()[0]

    const onFinish = (values:any) => {
        setIsLoading(true)
        const request: SupplierRequest = {
            name: values["name"],
            contact: values["contact"],
            memo: values["memo"]
           
        }

        client.createSupplierService(request).then(r => {
            message.success("Create Supplier Successfully successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=>setIsLoading(false))

        form.resetFields()
    }


    


    return (
        <Container>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>
                <Form.Item name={"name"}
                    hasFeedback
                    label={"Supplier Name"}
                    required rules={[
                        {
                            required: true,
                            message: "Please input name"
                        }]}>
                        <Input/>
                </Form.Item>



                <Form.Item name={"contact"}
                    hasFeedback
                    label={"Contact"}
                    required rules={[
                        {
                            required: true,
                            message: "Please input contact"
                        }]}>
                        <Input/>
                </Form.Item>

                
                
                <Form.Item name={"memo"}
                    hasFeedback
                    label={"Memo"}>
                        <TextArea rows={4}/>
                </Form.Item>



                <Form.Item style={{marginBottom: 0}}>
                    <Spin spinning={isLoading}>
                        <ButtonSubmit children={"Create"} htmlType={"submit"}/>
                    </Spin>
                </Form.Item>
            </Form>
        </Container>
    )

}

export default CreateSupplierForm