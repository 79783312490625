import React, { FunctionComponent, useEffect, useState } from "react";
import { Avatar, Button, Col, Dropdown, Layout, Menu, Row, Tooltip } from "antd";
import { Link, NavLink, useHistory } from "react-router-dom";
import Icons from "../icons";
import 'antd/dist/antd.css';
import Elements from "../elements";
import { AUTHORITY_GROUPS } from "../enum/AUTHORITY_TYPES";
import { ROUTE_URI_ENUM } from "../routes/paths";
import { withAuth } from "../provider/AuthWrapper";
import { useAuth } from "../provider/AuthProvider";
import {BarChartOutlined, StockOutlined} from "@ant-design/icons";
import ModalCustom from "../components/ModalCustom";
import styled from "styled-components";
import {
    BUILD_DATE,
    BUILD_NUMBER,
    COMMIT_ID,
    PACKAGE_VERSION,
} from "../app.config";
const { Header, Sider, Content } = Layout;
const { SubMenu } = Elements.MENU;

const menuItems = [
    {
        key: 'categories',
        icon: <Icons.FILE_ADD_OUTLINE />,
        title: "Categories",
        path: "/categories",
        authorities: AUTHORITY_GROUPS.CATEGORY,
        submenu: []
    },
    {
        key: 'brands',
        icon: <Icons.CLUSTER_OUTLINE />,
        title: "Brands",
        path: "/brands",
        authorities: AUTHORITY_GROUPS.BRAND,
        submenu: []
    },
    {
        key: 'specification',
        icon: <Icons.PARTITION_OUTLINE />,
        title: "Specification",
        path: "/specification",
        authorities: AUTHORITY_GROUPS.SPECIFICATION,
        submenu: []
    },
    {
        key: 'items',
        icon: <Icons.APPSTORE_ADD_OUTLINE />,
        title: "Items",
        path: "/items",
        authorities: AUTHORITY_GROUPS.ITEM,
        submenu: []
    },
    {
        key: "import",
        icon: <Icons.IMPORT_OUTLINE />,
        title: "Import",
        path: "/import",
        authorities: AUTHORITY_GROUPS.IMPORT,
        submenu: []
    },
    {
        key: "stock",
        icon: <StockOutlined />,
        title: "Stock",
        path: "/stock",
        authorities: AUTHORITY_GROUPS.STOCK,
        submenu: []
    },
    {
        key: 'order',
        icon: <Icons.SHOPPING_CART />,
        title: "Order",
        path: ROUTE_URI_ENUM.ORDER,
        authorities: AUTHORITY_GROUPS.ORDER,
        submenu: []
    },
    {
        key: 'adjustment',
        icon: <Icons.SHOPPING_CART />,
        title: "Adjustment",
        path: ROUTE_URI_ENUM.ADJUSTMENT,
        authorities: AUTHORITY_GROUPS.ADJUSTMENT,
        submenu: []
    },
    {
        key: 'report',
        icon: <BarChartOutlined />,
        title: "Reports",
        path: "",
        authorities: [],
        submenu: [
            {
                title: "Sale Report",
                path: ROUTE_URI_ENUM.REPORT,
                key: "sale-report"
            },
        ]
    },
    {
        key: 'user',
        icon: <Icons.USER_OUTLINE />,
        title: "User",
        path: ROUTE_URI_ENUM.USER,
        authorities: AUTHORITY_GROUPS.USER,
        submenu: []
    },
    {
        key: 'supplier',
        icon: <Icons.USER_GROUP_OUTLINE />,
        title: "Supplier",
        path: ROUTE_URI_ENUM.SUPPLIER,
        authorities: AUTHORITY_GROUPS.SUPPLIER,
        submenu: []
    },
    {
        key: 'configuration',
        icon: <Icons.SETTING_FILLED />,
        title: "Configuration",
        path: "",
        authorities: [],
        submenu: [
            {
                title: "Slider",
                path: ROUTE_URI_ENUM.SLIDER,
                key: "slider"
            },
            {
                title: "Visitor",
                path: ROUTE_URI_ENUM.VISITOR,
                key: "visitor"
            },
            {
                title: "Location",
                path: ROUTE_URI_ENUM.LOCATION,
                key: "location"
            },
        ]
    },
]

interface MainLayoutProps {
    data?: string
}

const PlatformContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const PFContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px;

    & > div:first-child {
        width: 20%;
    }

    & > div:last-child {
        width: 80%;
        flex-wrap: wrap;
    }
`;

const ResetButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 120px;
    &:hover {
        background-color: #00bfff;
        color: white;
        opacity: 0.8;
    }
    &:active {
        background-color: black;
        color: white;
        opacity: 0.8;
    }
`

const PlatformInfoContainer = () => {

    const history = useHistory()

    const handleLogOut = (e: any) => {
        history.push("/reset")
    }

    return (
        <PlatformContent>
            <PFContent style={{paddingTop: 0}}>
                <div>Version</div>
                <div>: {PACKAGE_VERSION}</div>
            </PFContent>
            <PFContent>
                <div>Build Number</div>
                <div>: {BUILD_NUMBER}</div>
            </PFContent>
            <PFContent>
                <div>Build Date</div>
                <div>: {BUILD_DATE}</div>
            </PFContent>
            <PFContent style={{paddingBottom: 20}}>
                <div>Commit ID</div>
                <div>: {COMMIT_ID}</div>
            </PFContent>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Tooltip title={"Reset"} placement={"top"}>
                    <ResetButton children={"Reset"} onClick={handleLogOut}/>
                </Tooltip>
            </div>
        </PlatformContent>
    )
}


const MainLayout: FunctionComponent<MainLayoutProps> = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isPlatformInfo, setIsPlatformInfo] = useState(false)

    const history = useHistory()

    const handleLogOut = (e: any) => {
        localStorage.clear()
        history.push(ROUTE_URI_ENUM.LOGIN)
    }

    const { user } = useAuth()

    const [key,setKey] = useState("categories")

    useEffect(()=>{
        const path = window.location.pathname
        setKey(path)
    },[])

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider trigger={null} collapsible collapsed={isCollapsed}>
                <div className="logo" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                    <Link to={"/"}>
                        {
                            isCollapsed ? <img style={{ width: '100px', paddingLeft: '20px', paddingRight: '20px' }}
                                src="/logo.png" alt="logo" />
                                : <img style={{ width: '200px' }} src="/logo.png" alt="logo" />
                        }
                    </Link>
                </div>



                <Menu theme="dark"
                    mode="inline"
                    selectedKeys={[key]}
                    defaultSelectedKeys={["categories"]}
                    defaultOpenKeys={["sub1"]}>
                    {

                        menuItems.map((it: any, index) => {

                            return (
                                <React.Fragment key={index}>
                                    {
                                        it.submenu.length > 0 ?
                                            <SubMenu title={it.title} key={"sub"+index} icon={it.icon}>
                                                {
                                                    it.submenu.map((sub: any, keyValue: number) => {
                                                        return (
                                                            <Menu.Item key={sub.path}>
                                                                <Link to={sub.path}>
                                                                    {
                                                                        sub.title
                                                                    }
                                                                </Link>
                                                            </Menu.Item>
                                                        )
                                                    })
                                                }
                                            </SubMenu>
                                            :
                                            <Menu.Item key={it.path} icon={it.icon}>
                                                <NavLink to={it.path}>
                                                    {
                                                        it.title
                                                    }
                                                </NavLink>
                                            </Menu.Item>
                                    }
                                </React.Fragment>

                            )
                        })

                    }
                </Menu>


            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: '0px 10px', color: 'white', fontSize: '20px' }}>
                    <Row>
                        <Col span={12}>
                            <a style={{ cursor: 'pointer', color: 'white' }} onClick={() => setIsCollapsed(!isCollapsed)}>
                                {
                                    isCollapsed
                                        ? <Icons.MENU_FOLD />
                                        :
                                        <Icons.MENU_UNFOLD />
                                }
                            </a>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Dropdown overlay={
                                    <Menu>
                                        <Menu.Item key="0">
                                            <Link onClick={handleLogOut} to={"/login"}>Logout &nbsp;&nbsp; <Icons.POWER_OFF_OUTLINE /></Link>
                                        </Menu.Item>
                                    </Menu>
                                } trigger={['click']}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <Avatar icon={<Icons.USER_OUTLINE />} />
                                    <span style={{ color: 'white', fontSize: '16px', padding: "0 10px 0 10px" }}>
                                        {
                                            user?.username
                                        }
                                    </span>
                                    <Icons.DOWN_OUTLINE style={{ color: 'white' }} />
                                </a>
                            </Dropdown>
                            <Icons.QUESTION_CIRCLE_OUTLINED 
                                style={{marginLeft: 10, cursor: 'pointer'}}
                                onClick={
                                    () => {
                                        setIsPlatformInfo(true)
                                    }
                                }
                            />
                            {/* Modal Platform Info */}
                            <ModalCustom 
                                title={"Platform information"}
                                visible={isPlatformInfo}
                                footer={null}
                                width={600}
                                onCancel={() => { setIsPlatformInfo(!isPlatformInfo) }}
                                children={<PlatformInfoContainer/>} 
                            />
                        </Col>
                    </Row>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        backgroundColor: 'white'
                    }}
                >
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default withAuth()(MainLayout)