import React, {FunctionComponent, useEffect, useState} from "react";
import {withAuth} from "../../provider/AuthWrapper";
import MainLayout from "../MainLayout";
import {ClientService, ItemResponse, Specification, SubCategoryResponse} from "@cubetiq/computer-web-sdk";
import ELements from "../../elements";
import {Link, useHistory, useLocation} from "react-router-dom";
import Icons from "../../icons";
import {ColumnsType} from "antd/es/table";
import ModalItem from "./components/ModalItem";
import FormItem from "./components/FormItem";
import {ROUTE_URI_ENUM} from "../../routes/paths";
import Elements from "../../elements";
import {logPrint} from "../../util/Logging";
import ItemTable from "../../components/table/ItemTable";
const { Search } = ELements.INPUT;

const ItemPage: FunctionComponent = () => {


    return(
        <MainLayout>
            <ItemTable/>
        </MainLayout>
    )
}

export default withAuth()(ItemPage)