import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons"
import { AuthUserResponse, BrandResponse, ClientService } from "@cubetiq/computer-web-sdk"
import { Avatar } from "antd"
import { Button, message, Switch } from "antd"
import { Spin, Table, Tooltip } from "antd"
import { ColumnType } from "antd/es/table"
import React,{ FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"
import CreateBrandForm from "../form/CreateBrandForm"
import CreateModelForm from "../form/CreateModelForm"
import UpdateBrandForm from "../form/UpdateBrandForm"
import UserResetPasswordForm from "../form/UserResetPasswordForm"
import ModalCustom from "../ModalCustom"
import { ReloadButton } from "./BrandTable"
import { ReloadIcon } from "./ImportTable"
import ModelTable from "./ModelTable"



const TableCustom = styled(Table)`
   padding: 20px 0px 0px 0px;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`


const Container = styled.div`

`


const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`


const PlusIcon = styled(PlusCircleFilled)`
    font-size: 24px;
    color: #19aa8d;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`



const CreateButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 120px;
    &:hover {
        background-color: #00bfff;
        color: white;
        opacity: 0.8;
    }
    &:active {
        background-color: black;
        color: white;
        opacity: 0.8;
    }
`


const EditIcon = styled(EditFilled)`
    font-size: 24px;
    color: #00bfff;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`


const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`



const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: cneter
`


const client = ClientService.getInstance()
const UserTable:FunctionComponent = () => {

    const [users,setUsers] = useState<Array<AuthUserResponse>>()
    const [isLoading,setIsLoaindg] = useState(false)


    useEffect(()=>{
        LoadData()
    },[])

    const LoadData = () => {
        setIsLoaindg(true)
        client.getAllUserService().then(r=>{
            setUsers(r.data)
        }).finally(()=> setIsLoaindg(false))
    }   

    const columns: Array<ColumnType<any>> = [
        {
            title: 'User Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Avatar',
            dataIndex: 'profile',
            align: "center",
            key: 'profile',
            width: 40,
            render: (v: any, row: AuthUserResponse) => {
                return(
                    <Avatar size="large" src={v.avatar === null ? "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png" : v.avatar} key={row.code} />
                    // <ImageCustom src={v.avatar === null ? "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png" : v.avatar} key={row.code}/>
                )
            }
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            align: "center",
            key: 'username',
            width: 200,
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            align: "center",
            key: 'phoneNumber',
            width: 200,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            align: "center",
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'roles',
            align: "center",
            key: 'roles',
            render: (v:string[],row:AuthUserResponse) => {
                return(
                    <div key={row.code}>
                          {
                              v
                          }
                    </div>
                )
            }
        },
        {
            title: 'Enable',
            dataIndex: 'isEnableUser',
            align: "center",
            key: 'isEnableUser',
            render: (v:boolean,row:AuthUserResponse) => {
                return(
                    <Switch  onChange={(value)=> updateEnableUser(row,value) } defaultChecked={v}/>
                )
            }
        },
        {
            title: 'Reset Password',
            dataIndex: 'reset',
            align: "center",
            key: 'reset',
            render: (v:boolean,row:AuthUserResponse) => {
                return(
                   <EditIcon onClick={()=>{
                        OnIsChangePasswordHandle()
                        setSelectedUser(row)
                   }}/>
                )
            }
        }
    ]


    const [isChangePassword,setIsChangePassword] = useState(false)
    const [selectedUser,setSelectedUser] = useState<AuthUserResponse>()
    const OnIsChangePasswordHandle = () => {
        setIsChangePassword(!isChangePassword)
    }

    

    const updateEnableUser = (user:AuthUserResponse,value:boolean) => {
        client.updateIsEnableUserService(user.code,{
            value: value
        }).then( r=> {
            message.success(`Username ${user.username} hass been ${value? "enabled" : "disabled"}`)
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }





    const keyChange = (key: any): string => {
        return key.id
    }



    const ModalUserChangePasswordForm = () => {
        if(selectedUser === undefined)
            return<></>
        return (
            <ModalCustom visible={isChangePassword} 
                        footer={null}
                        children={<UserResetPasswordForm user={selectedUser}/>}
                        onCancel={OnIsChangePasswordHandle}/>
        )
    }

    return(
       <Container>

           <ModalUserChangePasswordForm/>
           <ReloadButton icon={<ReloadIcon/>} children={"Reload"} onClick={LoadData} style={{marginLeft: 0}}/>
           {
                <TableCustom columns={columns}
                            rowKey={(value) => keyChange(value)}
                            bordered
                            loading={isLoading}
                            dataSource={users} />
           }
       </Container>
    )
}

export default UserTable