import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    DownOutlined,
    PoweroffOutlined,
    AppstoreAddOutlined,
    ClockCircleOutlined,
    FileAddOutlined,
    FolderAddOutlined,
    SearchOutlined,
    LeftOutlined,
    PlusOutlined,
    SafetyCertificateOutlined,
    LoadingOutlined,
    SyncOutlined,
    BranchesOutlined,
    ClusterOutlined,
    ShoppingCartOutlined,
    ImportOutlined,
    PartitionOutlined,
    SettingFilled,
    UsergroupAddOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import React from "react";

const Icons = {
    MENU_UNFOLD: MenuUnfoldOutlined,
    MENU_FOLD: MenuFoldOutlined,
    FOLDER_ADD_OUTLINE: FolderAddOutlined,
    FILE_ADD_OUTLINE: FileAddOutlined,
    APPSTORE_ADD_OUTLINE: AppstoreAddOutlined,
    POWER_OFF_OUTLINE: PoweroffOutlined,
    USER_OUTLINE: UserOutlined,
    USER_GROUP_OUTLINE: UsergroupAddOutlined,
    DOWN_OUTLINE: DownOutlined,
    SEARCH_OUTLINE: SearchOutlined,
    LEFT_OUTLINE: LeftOutlined,
    PLUS_OUTLINE: PlusOutlined,
    SAFETY_CERTIFICATION_OUTLINE: SafetyCertificateOutlined,
    LOADING_OUTLINE: LoadingOutlined,
    SYNCOUTLINED: SyncOutlined,
    BRANCHES_OUTLINE: BranchesOutlined,
    CLUSTER_OUTLINE: ClusterOutlined,
    SHOPPING_CART: ShoppingCartOutlined,
    IMPORT_OUTLINE: ImportOutlined,
    PARTITION_OUTLINE: PartitionOutlined,
    SETTING_FILLED: SettingFilled,
    QUESTION_CIRCLE_OUTLINED: QuestionCircleOutlined,
}

export default Icons
