import React, { FunctionComponent, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { ROUTE_URI_ENUM } from "../../routes/paths";

const clearAllCaches = () => {
  caches.keys().then((cacheNames) => {
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName).then((r) => console.log("Cached", r));
    });
  });
};

const ResetPage: FunctionComponent = () => {
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    clearAllCaches();

    console.log("Reseted successfull!");
  }, []);

  return <Redirect to={ROUTE_URI_ENUM.LOGIN} />;
};

export default ResetPage;