// @ts-nocheck

import React, {useState} from 'react'
import styled from "styled-components";
import {Button, Col, Form, Input, message, Row, Spin} from "antd";
import {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    MinusCircleOutlined,
    PlusOutlined,
    SaveOutlined
} from "@ant-design/icons";
import {FormProps} from "antd/es/form";
import {AdminService, SerialNumberRequest, SerialNumberResponse} from "@cubetiq/computer-web-sdk";


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 24px;
  font-weight: 600;


`

const MinusStyle = styled.span`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const HeaderTitle = styled.div`
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
`


const AddButton = styled(Button)`
  width: 100%;
  border: none;
  background-color: #19aa8d;

  color: white;
  letter-spacing: 1px;


`

const SaveButton = styled(Button)`
  width: 100%;
  border: none;
  background-color: #00bfff;

  color: white;
  letter-spacing: 1px;


  &:hover {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }

  &:focus {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }

  transition: 0.2s;

  &:active {
    transform: scale(0.9);
  }

`


const UpdateIcon = styled(CheckCircleOutlined)`
  font-size: 24px;
  color: green;
  transition: 0.2s;

  cursor: pointer;


  &:hover {
    opacity: 0.5;
  }

  &:focus {
    opacity: 0.5;
  }

  &:active {
    transform: scale(0.8);
  }

`


interface SerialNumberFormProps extends FormProps {
    serials: SerialNumberResponse[]
    limit: number
}


const admin = AdminService.getInstance()

const SerialNumberUpdateForm: React.FC<SerialNumberFormProps> = (props) => {

    const {serials, limit, ...rest} = props

    const [loading, setLoading] = useState<boolean[]>(Array(serials.length).fill(false))
    const [isChangeValues, setIsChangeValues] = useState<boolean[]>(Array(serials.length).fill(false))

    const form = Form.useForm()[0]

    const updateSerial = async (index: number) => {

        const curr = form.getFieldValue("serials")[index]
        const value = serials[index]

        if (curr["serial"] === value.serial) {
            message.warn("Cloud not update because of new serial is the same old serial")
            return
        }


        LoadingSetting(index)


        const request: SerialNumberRequest = {
            serial: curr["serial"],
            isInStock: curr["isInStock"]
        }

        const code = curr["code"]

        admin.updateSerialNumberService(code, request).then(r => {
            message.success("Serial number has been updated")
        }).then(() => {
            LoadingSetting(index)
            isChangeValues[index] = false
            setIsChangeValues([...isChangeValues])
        })
    }


    const LoadingSetting = (index: number) => {
        loading[index] = !loading[index]
        setLoading([...loading])
    }


    const onChangeValue = (v: any, index: number) => {
        const curr = form.getFieldValue("serials")[index]
        const value = serials[index]
        if (curr["serial"] === value.serial) {
            isChangeValues[index] = false
            setIsChangeValues([...isChangeValues])
        } else {
            isChangeValues[index] = true
            setIsChangeValues([...isChangeValues])
        }

        return v
    }

    return (
        <Container>
            <HeaderTitle children={"Serial Number"}/>

            <Form name="dynamic_form_item"
                  form={form}
                  style={{width: 400}}
                  {...rest}>
                <Form.List
                    name="serials"
                    initialValue={serials}
                >
                    {(fields) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={[15, 15]} style={{
                                    width: 400,
                                    margin: 0,
                                    height: 60
                                }} key={index}>
                                    <Col style={{
                                        padding: 0,
                                        margin: 0
                                    }}
                                         span={20}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'serial']}
                                            fieldKey={[field.fieldKey, 'serial']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input serial number.",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Serial number"
                                                   onChange={v => onChangeValue(v, index)}
                                                   style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <MinusStyle>
                                            <Form.Item style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                {
                                                    loading[index] ?
                                                        <Spin spinning/>
                                                        :
                                                        isChangeValues[index] &&
                                                            <UpdateIcon
                                                                onClick={() => updateSerial(index)}
                                                            />
                                                }
                                            </Form.Item>
                                        </MinusStyle>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form>
        </Container>
    )
}

export default SerialNumberUpdateForm