
import { BrandResponse, CategoryRequest, CategoryResponse, ClientService } from "@cubetiq/computer-web-sdk"
import { BrandRequest } from "@cubetiq/computer-web-sdk/src/interface/request/brand/BrandRequest"
import { ModelRequest } from "@cubetiq/computer-web-sdk/src/interface/request/model/ModelRequest"
import { Button, message, Spin } from "antd"
import { Form, Input } from "antd"
import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"
import FileSelectWithPreview from "../upload/FileSelectWithPreview"





const Container = styled.div`


`


interface CreateBrandFormProps {
    category: CategoryResponse
}

const ButtonSubmit= styled(Button)`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 5px;
    border: none;
    background-color: #19aa8d;
    color: white;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
`

const Style = styled.div`
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`



const client = ClientService.getInstance()
const UpdateCategoryForm: FunctionComponent<CreateBrandFormProps> = (props) => {
    const [isLoading,setIsLoading] = useState(false)
    const [file,setFile] = useState<File>()
    const form = Form.useForm()[0]
    const {  category } = props
    

    const onFinish = async (values:any) => {


        setIsLoading(true)


        let url = null

        if(file !== undefined){
            await client.otherUploadFileService(file).then( r => {
                url = r.data.url
            })
        }

        const request: CategoryRequest = {
            name: values["name"],
            config: {
                icon: url || category.config.icon
            }
        }
        client.updateCategoryService(category.code,request).then(res => {
            message.success("Update Category Successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=> setIsLoading(false))
    }


    


    return (
        <Container>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>

                <Style>
                    <Form.Item name={"file"}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                        <FileSelectWithPreview onChange={setFile} defaultValue={category.config.icon}/>
                    </Form.Item>
                </Style>


                <Form.Item name={"name"}
                    hasFeedback
                    label={"Brand Name"}
                    initialValue={category.name}
                    required rules={[
                        {
                            required: true,
                            message: "Please input name"
                        }]}>
                        <Input/>
                </Form.Item>

                <Form.Item style={{marginBottom: 0}}>
                    <Spin spinning={isLoading}>
                        <ButtonSubmit children={"Save"} htmlType={"submit"}/>
                    </Spin>
                </Form.Item>
            </Form>
        </Container>
    )

}

export default UpdateCategoryForm