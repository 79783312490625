import React,{ FunctionComponent } from "react";
import styled from "styled-components";
import SupplierTable from "../../components/table/SupplierTable";
import { withAuth } from "../../provider/AuthWrapper";
import MainLayout from "../MainLayout";




const Container = styled.div`

`



const SupplierPage:FunctionComponent = () => {
    return(
       <MainLayout>
            <Container>
                <SupplierTable/>
            </Container>
       </MainLayout>
    )
}

export default withAuth()(SupplierPage)