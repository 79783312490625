import { DeleteFilled, EditFilled, QuestionCircleOutlined } from "@ant-design/icons"
import { ClientService, SubCategoryResponse } from "@cubetiq/computer-web-sdk"
import { message } from "antd"
import { Popconfirm, Spin, Table } from "antd"
import { ColumnType } from "antd/es/table"
import React,{ FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"
import UpdateSubCategoryForm from "../form/UpdateSubCategoryForm"
import ModalCustom from "../ModalCustom"



const TableCustom = styled(Table)`
   padding: 20px 0px 0px 0px;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`


const Container = styled.div`

`


const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`


interface ModelTableProps  {
    subCategory: Array<SubCategoryResponse>
}



const EditIcon = styled(EditFilled)`
    font-size: 24px;
    color: #00bfff;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`


const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`

const IconContainer = styled.div`

`


const client = ClientService.getInstance()
const SubCategoryTable:FunctionComponent<ModelTableProps> = (props) => {

    const {  subCategory } = props
    const [selectedSubCategory,setSelectedSubCategory] = useState<SubCategoryResponse>()
    const [isLoading,setIsLoading] = useState(true)
    const [isUpdate,setIsUpdate] = useState(false)
    const [subCategories,setSubCategories] = useState<Array<SubCategoryResponse>>()
    const onIsUpdateHandle = () =>{
        setIsUpdate(!isUpdate)
    }


    useEffect(()=>{
        if(subCategory === undefined)
            return
        setSubCategories([...subCategory])
        setIsLoading(false)
    },[subCategory])


    const columns: Array<ColumnType<any>> = [
        {
            title: 'SubCategory Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'SubCategory Name',
            dataIndex: 'name',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: "center",
            key: 'code',
            width: 200,
            render:(v,row) => {

                return(
                    <IconContainer>
                        <EditIcon onClick={()=>{
                            setSelectedSubCategory(row)
                            onIsUpdateHandle()
                        }}/>
                        <Popconfirm title="Are you sure to delete this sub category" 
                                onConfirm={()=> onRemoveSubCategory(row)}
                                icon={<QuestionCircleOutlined 
                                style={{ color: 'red', fontSize: 16 }} />}>
                            <DeleteIcon/>
                        </Popconfirm>
                    </IconContainer>
                )
            }
        }
    ]



    

    const onRemoveSubCategory = (sub:SubCategoryResponse) => {
        client.deleteSubCategoryService(sub.code).then( (r) => {
            const current = subCategory?.filter(it => it.id !== sub.id)
            setSubCategories([...current || []])
            message.success("Remove Category Successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }

    const UpdateSubCategory = () => {
        if (selectedSubCategory === undefined)
            return <></>
        return (
            <ModalCustom visible={isUpdate}
                footer={null}
                width={500}
                onCancel={onIsUpdateHandle}
                children={<UpdateSubCategoryForm subCategory={selectedSubCategory} />} />
        )
    }
    

    return(
       <Container>
          <UpdateSubCategory/>
          {
                <TableCustom columns={columns}
                    bordered
                    loading={isLoading}
                    pagination={false}
                    dataSource={subCategories} />
           }
       </Container>
    )
}

export default SubCategoryTable