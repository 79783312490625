// @ts-nocheck

import React, {FunctionComponent, useEffect, useState} from "react";
import {Col, Row, Select} from 'antd';
import styled from "styled-components";
import { ItemResponse } from "@cubetiq/computer-web-sdk";


interface SelectCustomProps{
    onChange?: (value:any) => void,
    data: ItemResponse[],
    placeholder?: string,
    defaultValue?: any,
    style?: any,
}

const { Option } = Select;





const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 4px;
`

const Content = styled.div`
    padding-left: 10px;
`



const SelectItemCustom:FunctionComponent<SelectCustomProps> = props =>{
    const [value,setValue] =useState<any| undefined>()
    const { onChange, data } = props
    
    useEffect(()=>{
        if(value === undefined)
            return
        typeof onChange === "function" && onChange(value)
    },[value])


    function onHandleChange(value:any) {
        setValue(value)
    }

   
    function onBlur(value:any) {
        setValue(null)
    }





    return(
        <Select
            showSearch
            style={props.style}
            optionFilterProp="children"
            onChange={onHandleChange}
            autoClearSearchValue
            value={value}
            defaultValue={props.defaultValue}
            onBlur={onBlur}
            filterOption={(input, option) =>{
                const json = JSON.parse(option?.value)
                
                return json.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                    || json.code.toLowerCase().indexOf(input.toLowerCase()) >= 0 
            }}>
            {
                data.map((it,index) =>{
                    return(
                        <Option value={JSON.stringify(it)} key={index}>
                            <Row>
                                <Col span={2}>
                                    <ImageCustom src={it.attachments?.[0]?.originalLink}/>
                                </Col>
                                <Col span={22} style={{display: "flex",alignItems: "center"}}>
                                    <Content>
                                        {
                                            it.name
                                        }
                                    </Content>
                                </Col>
                            </Row>
                        </Option>
                    )
                })
            }
        </Select>
    )
}

export default SelectItemCustom