import { ClientService } from "@cubetiq/computer-web-sdk";
import { message } from "antd";
import { getErrorMessage } from "./utils";

export const logPrint = (...args: Array<any>) => {
    const [firstArg,...otherArg] = args
    console.log(`%c${firstArg}`,"color:green;font-weight:bold;font-size:12pt",...otherArg)

}


export const SelectFileUtils = (setFile: (value: File) => void ) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;
    inputElement.style.display = ' none';
    document.body.append(inputElement);
    inputElement.addEventListener('change', () => {
        selectFile(inputElement,setFile);
        inputElement.remove();
    });
    inputElement.click();
}


const selectFile = (input: HTMLInputElement, setFile: (value: File) => void ) => {
    const files = input.files;
    const file = files?.item(0);
    file && setFile(file)
}


export const SelectMultipleFileUtils = (setFile: (value: FileList) => void ) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;
    inputElement.style.display = ' none';
    document.body.append(inputElement);
    inputElement.addEventListener('change', () => {
        multipleSelectFile(inputElement,setFile);
        inputElement.remove();
    });
    inputElement.click();
}



const multipleSelectFile = (input: HTMLInputElement,setFile:(value: FileList) => void) => {
    const files = input.files;
    if(files!==null)
        setFile(files)
}


export const SelectFileWithUpload= (setValue: (value: any) => void ) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;
    inputElement.style.display = ' none';
    document.body.append(inputElement);
    inputElement.addEventListener('change', () => {
        selecWithUpload(inputElement,setValue);
        inputElement.remove();
    });
    inputElement.click();
}

const selecWithUpload = (input: HTMLInputElement, setValue: (value: any) => void ) => {
    const files = input.files;
    const file = files?.item(0);
    if(file){
        const client = ClientService.getInstance()
        client.fileUploadService(file).then(r => {
            setValue(r.data)
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }
}