// @ts-nocheck

import {MinusCircleOutlined} from "@ant-design/icons";
import {
    BrandResponse,
    CategoryResponse,
    ClientService,
    DiscountType,
    ModelResponse,
    Specification,
    SpecificationResponse,
    SubCategoryResponse,
} from "@cubetiq/computer-web-sdk";
import {ItemRequest} from "@cubetiq/computer-web-sdk/dist/interface/request/item/ItemRequest";
import {ItemSpecificationRequest} from "@cubetiq/computer-web-sdk/dist/interface/request/specification/ItemSpecificationRequest";
import {AutoComplete, Button, Col, Form, Input, InputNumber, message, Row, Spin, Switch} from "antd";

import React, {FunctionComponent, useEffect, useState} from "react";
import styled from "styled-components";
import SpecificationUpdateButtonCustom from "../../components/CustomButton/SpecificationUpdateButtonCustom";
import MultipleUploadCustom from "../../components/MultipleUploadCustom";
import TextEditor from "../../components/text-editor/TextEditor";
import {withAuth} from "../../provider/AuthWrapper";
import {getErrorMessage} from "../../util/utils";
import MainLayout from "../MainLayout";
import SelectCustom from "../../components/SelectCustom";
import { useHistory } from "react-router";


const Container = styled.div`

`

const FormCustom = styled(Form)`
  margin-top: 10px;
`


const ButtonSubmit = styled(Button)`
  width: 100%;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 5px;
  border: none;
  background-color: #19aa8d;
  color: white;
  height: 45px;
  font-size: 24px;
  font-weight: bold;
`


export const ProductUsedData = [
    {value: 'New'},
    {value: 'Open Box'},
    {value: 'Second Hand'},
    {value: 'Factory Outlet'},
]

export const DiscountTypeData = [
    {
        name: DiscountType.PERCENTAGE
    },
    {
        name: DiscountType.CASH
    }
]


const client = ClientService.getInstance()
const CreateItemPage: FunctionComponent = () => {

    const [specifications, setSpecifications] = useState<Array<SpecificationResponse>>([])
    const [categories, setCategories] = useState<Array<CategoryResponse>>([])
    const [subCategories, setSubCategories] = useState<Array<SubCategoryResponse>>([])
    const [brands, setBrand] = useState<Array<BrandResponse>>([])
    const [models, setModels] = useState<Array<ModelResponse>>([])
    const [files, setFiles] = useState<FileList>()
    const [isCreate, setIsCreate] = useState(false)
    const history = useHistory();


    useEffect(() => {
        client.getAllCategoryService({paged: false}).then(r => {
            setCategories(r.data)
        })
        client.getAllBrandService({paged: false}).then(r => {
            setBrand(r.data)
        })
        client.getAllSpecificationService({
            paged: false
        }).then(r => {
            setSpecifications(r.data)
        })
    }, [])


    const onSpecificatioSelectedHandle = (value: SpecificationResponse) => {
        setSpecifications([...specifications, value])
    }


    const onFinish = (values: any) => {

        const currentSpec: Array<ItemSpecificationRequest> = []
        setIsCreate(true)

        for (const temp in values) {
            const spec = temp.split("@_@")
            if (spec?.[0] === "specification") {
                const req: ItemSpecificationRequest = {
                    id: spec[1],
                    value: values[temp]
                }
                currentSpec.push(req)
            }
        }


        const request: ItemRequest = {
            name: values["name"],
            price: values["price"],
            categoryId: values["category"] && JSON.parse(values["category"])["id"],
            subCategoryId: values["subCategory"] && JSON.parse(values["subCategory"])["id"],
            brandId: values["brand"] && JSON.parse(values["brand"])["id"],
            modelId: values["model"] && JSON.parse(values["model"])["id"],
            memo: values["memo"],
            specifications: values["specifications"],
            discount: values["discount"] || 0,
            isNewItem: values["isNewItem"],
            productUsed: values["productUsed"],
            isShowUnavailable: values["isShowUnavailable"],
            discountType: discountType
        }


        if (files?.length === undefined) {
            message.error("Please choose image to upload!")
            setIsCreate(false)
            return
        }


        client.createItem(request, files).then(r => {
            message.success("Create Item Successfully");
            history.push("/items");
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(() => setIsCreate(false))

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    const onCategoryChange = (v: any) => {
        const json: CategoryResponse = JSON.parse(v)
        setSubCategories(json.subCategory)
    }


    const onBrandChange = (v: any) => {
        const json: BrandResponse = JSON.parse(v)
        setModels(json.model)
    }

    const [selectedSpecification, setSelectedSpecification] = useState<Array<Specification>>([])


    const onSpecificationSelectedHandle = (value: Specification) => {
        setSelectedSpecification([...selectedSpecification || [], value])
        const newValue = specifications?.filter(it => it.id !== Number(value.id))
        setSpecifications([...newValue || []])
    }

    const onRemoveSpecificationSelected = (index: number) => {
        const remove = selectedSpecification

        const add = selectedSpecification[index]
        const spec: SpecificationResponse = {
            id: Number(add.id),
            code: add.id!!,
            name: add.name!!,
            config: {}
        }
        setSpecifications([...specifications || [], spec])
        remove.splice(index, 1)
        setSelectedSpecification([...remove])
    }


    const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.PERCENTAGE)


    const form = Form.useForm()[0]

    useEffect(() => {
        const value = form.getFieldValue("discount")
        if(discountType === DiscountType.PERCENTAGE){
            const result = value > 100 ? 100 : value
            form.setFieldsValue({
                discount: result
            })
        }
    }, [discountType])

    return (
        <MainLayout>
            <Container>
                <MultipleUploadCustom onChange={setFiles}/>
                <FormCustom onFinish={onFinish}
                            layout={"vertical"}
                            form={form}
                            onFinishFailed={onFinishFailed}>

                    <Row gutter={[16, 0]}>
                        <Col span={6}>
                            <Form.Item name={"name"}
                                       label={"Name"}
                                       required>
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name={"price"}
                                       label={"Price"}
                                       required>
                                <InputNumber style={{width: "100%"}} min={0}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name={"category"}
                                       label={"Category"}>
                                <SelectCustom data={categories} onChange={(v) => onCategoryChange(v)}/>
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item name={"subCategory"}
                                       label={"Sub Category"}>
                                <SelectCustom data={subCategories}/>
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item name={"brand"}
                                       label={"Brand"}>
                                <SelectCustom data={brands} onChange={(v) => onBrandChange(v)}/>
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item name={"model"}
                                       label={"Model"}>
                                <SelectCustom data={models}/>
                            </Form.Item>
                        </Col>


                        <Col span={3}>
                            <Form.Item name={"discount"}
                                       label={"Discount"}>
                                <InputNumber style={{width: "100%"}}
                                             defaultValue={0}
                                             max={
                                                 discountType === DiscountType.PERCENTAGE ? 100 : 10000000
                                             }
                                             min={0}/>
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item name={"discountType"}
                                       label={"Discount Type"}>
                                <SelectCustom data={DiscountTypeData}
                                              onChange={(v) => {
                                                  setDiscountType(JSON.parse(v)["name"])
                                                  return v
                                              }}
                                              defaultValue={DiscountType.PERCENTAGE}/>
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item name={"productUsed"}
                                       label={"Product Used"}>
                                <AutoComplete
                                    options={ProductUsedData}
                                    filterOption={(inputValue: any, option: any) =>
                                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item name={"isShowUnavailable"}
                                       valuePropName="checked"
                                       initialValue={true}
                                       label={"Show Unavailable"}>
                                <Switch checkedChildren="On" unCheckedChildren="Off" defaultChecked/>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.List name="specifications" initialValue={selectedSpecification}>
                        {
                            (fields, {add, remove}) => (

                                <>
                                    <SpecificationUpdateButtonCustom onChange={(v) => {
                                        add()
                                        onSpecificationSelectedHandle(v)
                                    }} defaultValue={specifications || []}/>

                                    <Row gutter={[16, 0]}>
                                        {
                                            fields.map((field, index) => (
                                                <React.Fragment key={index}>


                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'id']}
                                                        hidden
                                                        label={selectedSpecification?.[index]?.id}
                                                        fieldKey={[field.fieldKey, 'id']}
                                                        initialValue={selectedSpecification?.[index]?.id}>
                                                        <Input/>
                                                    </Form.Item>


                                                    <Col span={5}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'value']}
                                                            label={selectedSpecification?.[index]?.name}
                                                            fieldKey={[field.fieldKey, 'value']}
                                                            rules={[{
                                                                required: true,
                                                                message: 'Please choose items to import!'
                                                            }]}>
                                                            <Input/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center"
                                                    }}>
                                                        <MinusCircleOutlined onClick={() => {
                                                            remove(field.name)
                                                            onRemoveSpecificationSelected(index)
                                                        }}/>
                                                    </Col>
                                                </React.Fragment>
                                            ))
                                        }
                                    </Row>
                                </>

                            )
                        }
                    </Form.List>


                    <Row>
                        <Col md={24} lg={24}>
                            <Form.Item name={"memo"}>
                                <TextEditor/>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={24} lg={24}>
                            <Form.Item>
                                <Spin spinning={isCreate} size={"large"}>
                                    <ButtonSubmit children={"CREATE"} htmlType={"submit"}/>
                                </Spin>
                            </Form.Item>
                        </Col>
                    </Row>
                </FormCustom>


            </Container>
        </MainLayout>
    )
}

export default withAuth()(CreateItemPage)