import { DeleteFilled, EditFilled, QuestionCircleOutlined } from "@ant-design/icons"
import { BrandResponse, ClientService, LocationRequest, LocationResponse, ModelResponse, SpecificationResponse, SubCategoryResponse } from "@cubetiq/computer-web-sdk"
import { Button } from "antd"
import { TablePaginationConfig } from "antd"
import { Popconfirm } from "antd"
import { message } from "antd"
import { Spin, Table } from "antd"
import { ColumnType } from "antd/es/table"
import React,{ FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { AddIconCustom } from "../../pages/import"
import { getErrorMessage, toUSD } from "../../util/utils"
import CreateLocationForm from "../form/CreateLocationForm"
import CreateSpecificationForm from "../form/CreateSpecificationForm"
import UpdateLocationForm from "../form/UpdateLocationForm"
import UpdateModelForm from "../form/UpdateModelForm"
import UpdateSpecificationForm from "../form/UpdateSpecificationForm"
import UpdateSubCategoryForm from "../form/UpdateSubCategoryForm"
import ModalCustom from "../ModalCustom"
import { ReloadButton } from "./BrandTable"
import { ReloadIcon } from "./ImportTable"



const TableCustom = styled(Table)`
   padding: 20px 0px 0px 0px;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`



const CreateButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 120px;
    &:hover {
        background-color: #00bfff;
        color: white;
        opacity: 0.8;
    }
    &:active {
        background-color: black;
        color: white;
        opacity: 0.8;
    }
`



const Container = styled.div`

`


const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`


interface LocationTableProps  {
    
}



const EditIcon = styled(EditFilled)`
    font-size: 24px;
    color: #00bfff;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`


const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`

const IconContainer = styled.div`

`


const client = ClientService.getInstance()
const LocationTable:FunctionComponent<LocationTableProps> = (props) => {

    const [selectedLocation,setSelectedLocation] = useState<LocationResponse>()
    const [isUpdate,setIsUpdate] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const onIsUpdateHandle = () =>{
        setIsUpdate(!isUpdate)
    }


    const [locations,setLocations] = useState<Array<LocationResponse>>()
    const [totalCount,setTotalCount] = useState(0)

    useEffect(()=>{
       onLoadData()
    },[])



    const [pageSize,setPageSize] = useState(10)
    const onLoadData = () => {
        setIsLoading(true)
        client.getAllLocationService({
            size: pageSize
        }).then(r => {
            setLocations(r.data)
            setTotalCount(r.pages.totalCount)
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=> setIsLoading(false))
    }


    const onRemoveLocation = (location:LocationResponse) => {
        client.deleteLocationService(location.code).then(res => {
            const current = locations?.filter(it => it.id !== location.id)
            setLocations([...current || []])
            message.success("Delete successfully")
        }).catch(res => {
            message.error(getErrorMessage(res))
        })
    }


    const columns: Array<ColumnType<any>> = [
        {
            title: 'Location Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            align: "center",
            key: 'location',
            width: 200,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            align: "center",
            key: 'price',
            width: 200,
            render: v => {
                return(
                    <React.Fragment>
                        {
                            toUSD.format(Number(v))
                        }
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: "center",
            key: 'code',
            width: 200,
            render:(v,row) => {

                return(
                    <IconContainer>
                        <EditIcon onClick={()=>{
                            setSelectedLocation(row)
                            onIsUpdateHandle()
                        }}/>

                        <Popconfirm title={`Are you sure to delete ( ${row.location} ) ? `} 
                                    onConfirm={()=> onRemoveLocation(row)}
                                    icon={<QuestionCircleOutlined 
                                    style={{ color: 'red', fontSize: 16 }} />}>
                            <DeleteIcon/>
                        </Popconfirm>
                    </IconContainer>
                )
            }
        }
    ]


    const UpdateLocation = () => {
        if (selectedLocation === undefined)
            return <></>
        return (
            <ModalCustom visible={isUpdate}
                footer={null}
                width={500}
                onCancel={onIsUpdateHandle}
                children={<UpdateLocationForm location={selectedLocation} />} />
        )
    }

    const [isCreate,setIsCreate] = useState(false)
    const onIsCreateHandle = () => {
        setIsCreate(!isCreate)
    }


    const PaginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            client.getAllLocationService({
                page: (v - 1),
                size: pageSize
            }).then(r => {
                setLocations(r.data)
                setTotalCount(r.pages.totalCount)
            })
        },
        total: totalCount,
    }





    return(
       <Container>

            <ModalCustom visible={isCreate}
                footer={null}
                width={500}
                onCancel={onIsCreateHandle}
                children={<CreateLocationForm/>} />

            <UpdateLocation/>
            <CreateButton children={"Create"} onClick={onIsCreateHandle} icon={<AddIconCustom/>}/>
            <ReloadButton icon={<ReloadIcon/>} children={"Reload"} onClick={onLoadData}/>

          {
                <TableCustom columns={columns}
                    bordered
                    loading={isLoading}
                    pagination={PaginationConfig}
                    dataSource={locations} />
           }
       </Container>
    )
}

export default LocationTable