// @ts-nocheck

import React, {FunctionComponent, useEffect, useState} from "react"
import {Editor} from '@tinymce/tinymce-react';


interface TextEditorProps {
    onChange?: (value:any) => void
    defaultValue?: any
}



const TextEditor:FunctionComponent<TextEditorProps> = (props) => {
    const [value,setValue] = useState("")

    const handleEditorChange = (content:string, editor:any) => {
       setValue(content)
    }

    useEffect(()=>{
        typeof props.onChange === "function" && props.onChange(value)
    },[value])

    return(
        <div style={{marginTop: 20}}>
            <Editor
                onEditorChange={handleEditorChange}
                apiKey='5ra099y3w8gxn3uruo1b0uyatsaodafcndt3jk3eu8k29qsx'
                init={{
                    height: 500,
                    fontFamily: "Roboto, Siem Reap",
                    menubar: false,
                    images_upload_handler: function (blobInfo, success, failure) {
                      
                        success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
                    },
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor textcolor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount emoticons'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | image | table |removeformat  | emoticons | fullscreen | help'

                }}
                initialValue={props.defaultValue}
            />
        </div>
    )
}

export default TextEditor