import { DeleteFilled, EditFilled } from "@ant-design/icons";
import styled from "styled-components";

export const EditIcon = styled(EditFilled)`
    font-size: 24px;
    color: #00bfff;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }

  transition: 0.2s;
  &:active {
    transform: scale(0.9);
  }
`

export const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
  transition: 0.2s;
  &:active {
    transform: scale(0.9);
  }
`