import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {Button, Form, FormProps, Input, message, Spin} from "antd";
import {
    ClientService,
    LoginRequest,
    setClientToken,
} from "@cubetiq/computer-web-sdk";
import {useHistory} from "react-router-dom";
import {ROUTE_URI_ENUM} from "../../../routes/paths";
import {getErrorMessage} from "../../../util/utils";

interface FormLoginProps extends FormProps {
}

const client = ClientService.getInstance();

const FormLogin: FunctionComponent<FormLoginProps> = (props) => {
    const history = useHistory();
    const [isLogin, setIsLogin] = useState(false);
    const [isCheck, setIsCheck] = useState(false);

    const onFinish = async (values: any) => {
        setIsLogin(true);
        const request: LoginRequest = {
            username: values.username,
            password: values.password,
        };
        client
            .backendLoginService(request)
            .then((r) => {
                // setClientToken(r.data.token).then(r => {
                //     history.push(ROUTE_URI_ENUM.DEFAULT)
                // })
                setClientToken(r.data.token)
                window.location.reload()
            })
            .catch((r) => {
                message.error(getErrorMessage(r));
            })
            .finally(() => setIsLogin(false));
    };

    useEffect(() => {
        client
            .fetchUserService()
            .then((r) => {
                history.push(ROUTE_URI_ENUM.DEFAULT);
            }).catch(()=>setClientToken(null))
            .finally(() => setIsCheck(true));
    });

    return (
        <React.Fragment>
        {
            isCheck &&
            <Form
                name="basic"
                initialValues={{remember: true}}
                onFinish={onFinish}
                layout={"vertical"}
                style={{
                    width: 500,
                    borderRadius: 4,
                    padding: 50,
                    boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{required: true, message: "Please input your username!"}]}
                >
                    <Input placeholder={"Enter your username..."}/>
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{required: true, message: "Please input your password!"}]}
                >
                    <Input.Password placeholder={"Enter your password..."}/>
                </Form.Item>

                <Form.Item>
                    <Spin spinning={isLogin}>
                        <Button
                            size={"large"}
                            type="primary"
                            block={true}
                            children={"Login"}
                            style={{backgroundColor: "green"}}
                            htmlType="submit"
                        />
                    </Spin>
                </Form.Item>

                <Form.Item>
                    <a href={ROUTE_URI_ENUM.RESET}>Reset session!</a>
                </Form.Item>
            </Form>
        }

        </React.Fragment>
    );
};

export default FormLogin;