import {CloseCircleOutlined} from "@ant-design/icons";
import { ClientService } from "@cubetiq/computer-web-sdk";
import { message } from "antd";
import React, {FunctionComponent} from "react";
import styled from "styled-components";
import { getErrorMessage } from "../util/utils";


interface ImageCardProps {
    attachment: string,
    index: number,
    setAttachments: (value:any) => void,
    attachments: any[]



}


const Container = styled.div`
    width: 500px ; 
    height: 200px;
    object-fit: contain;
    border: 1px solid #ccc;
    margin-top: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: white;
    border-radius: 4px;
`


const IconCustom = styled(CloseCircleOutlined)`
    font-size: 24px;
    margin: 5px 5px 0px 0px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`
const client = ClientService.getInstance()
const ImageCardLink:FunctionComponent<ImageCardProps> = (props) => {

    const { attachment,setAttachments,index,attachments } = props 


    const onRemoveHandle = () => {
        const arrayFile = attachments
        const removeSliderCode = attachments[index].code
        arrayFile.splice(index,1);
        setAttachments([...arrayFile])
        client.deleteSliderService(removeSliderCode).then(r => {
            message.success("Delete Slide Successfully!")
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }

    return(
        <Container style={{backgroundImage: `url(${attachment})`}}>
            <IconCustom onClick={onRemoveHandle}/>
        </Container>
    )
}

export default ImageCardLink;