// @ts-nocheck

import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {Button, DatePicker, Table, TablePaginationConfig} from "antd";
import {AdminService} from "@cubetiq/computer-web-sdk";
import {ColumnType} from "antd/es/table";
import {SaleReportResponse} from "@cubetiq/computer-web-sdk/dist/interface/response/SaleReportResponse";
import {dateFnsFormat} from "../../../util/DateUtils";
import {toUSD} from "../../../util/utils";
import SaleReportDetailTable from "./SaleReportDetailTable";
import moment from "moment";
import { ExportOutlined } from '@ant-design/icons';

const TableCustom = styled(Table)`
  padding: 20px 0 0 0;

  .ant-table-expanded-row {
    .ant-table-cell {
      background: none;
    }

    .ant-table-content {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
`

const DownloadButton = styled(Button)`
    background-color: #389E0D;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 200px;
     &:hover {
        background-color: #808080;
        color: white;
     }
`

const Container = styled.div`

`
const {RangePicker} = DatePicker;

const admin = AdminService.getInstance()

const SaleReportTable = () => {

    const [data, setData] = useState<SaleReportResponse[]>([])
    const [dataDownload, setDataDownload] = useState<SaleReportResponse[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize] = useState(10)
    const [startDate, setStartDate] = useState(0)
    const [endDate, setEndDate] = useState(0)

    const query = admin.getSaleReportService

    useEffect(() => {
        onLoadData()

        // data no pages
        query({
            startDate: Date.now(),
            endDate: Date.now()
        }).then(r => {
            setDataDownload(r.data)
        })
    }, [])

    const onLoadData = () => {
        setIsLoading(true)
        query({
            startDate: Date.now(),
            endDate: Date.now(),
            // size: pageSize
        }).then(r => {
            setData(r.data)
            setTotalCount(r.pages.totalCount)
            setStartDate(Date.now())
            setEndDate(Date.now())
        }).finally(() => setIsLoading(false))
    }


    const onChangeHandle = (v: any) => {
        if (v !== null) {
            const start = v[0]["_d"].getTime()
            const end = v[1]["_d"].getTime()

            setIsLoading(true)
            query({
                startDate: start,
                endDate: end,
                // size: pageSize
            }).then(r => {
                setData(r.data)
                setTotalCount(r.pages.totalCount)
                setStartDate(start)
                setEndDate(end)
            }).finally(() => setIsLoading(false))

            // data no pages
            query({
                startDate: start,
                endDate: end
            }).then(r => {
                setDataDownload(r.data)
            }) 
        } else {
            onLoadData()
        }
    }

    const xlsx = require('json-as-xlsx');

    const dataExport = [
        {
          sheet: 'Sale Report',
          columns: [
            { label: 'Code', value: 'code' },
            { label: 'Date', value: (row:any) => (dateFnsFormat(row.date)) },
            { label: 'Income', value: (row:any) => (toUSD.format(row.income)) },
            { label: 'Cost', value: (row:any) => (toUSD.format(row.cost)) },
            { label: 'Item Discount', value: (row:any) => (toUSD.format(row.itemDiscount)) },
            { label: 'Invoice Discount', value: (row:any) => (toUSD.format(row.invoiceDiscount)) },
            { label: 'Total Discount', value: (row:any) => (toUSD.format(row.discount)) },
            { label: 'Revenue', value: (row:any) => (toUSD.format(row.revenue)) },
            { label: 'User', value: 'username' },
          ],
          content: dataDownload
        }
    ];

    const settings = {
        fileName: 'Sale Report', // Name of the spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    const onHandleDownload = () => {
        return xlsx(dataExport, settings); 
    }

    const columns: Array<ColumnType<any>> = [
        {
            title: 'Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: "center",
            key: 'date',
            width: 200,
            render: value => dateFnsFormat(value)
        },
        {
            title: 'Income',
            dataIndex: 'income',
            align: "center",
            key: 'income',
            width: 200,
            render: value => toUSD.format(value)
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            align: "center",
            key: 'cost',
            width: 200,
            render: value => toUSD.format(value)
        },
        {
            title: 'Item Discount',
            dataIndex: 'itemDiscount',
            align: "center",
            key: 'itemDiscount',
            width: 200,
            render: value => toUSD.format(value)
        },
        {
            title: 'Invoice Discount',
            dataIndex: 'invoiceDiscount',
            align: "center",
            key: 'invoiceDiscount',
            width: 200,
            render: value => toUSD.format(value)
        },
        {
            title: 'Total Discount',
            dataIndex: 'discount',
            align: "center",
            key: 'discount',
            width: 200,
            render: value => toUSD.format(value)
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            align: "center",
            key: 'revenue',
            width: 200,
            render: value => toUSD.format(value)
        },
        {
            title: 'User',
            dataIndex: 'username',
            align: "center",
            key: 'username',
            width: 200,
        },


    ]


    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        // setCurrentPage(pagination.current)
    }


    const paginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            query({
                // page: (v - 1),
                // size: 10,
                startDate: startDate,
                endDate: endDate,
            }).then(r => {
                setData(r.data)
                setTotalCount(r.pages.totalCount)
            })
        },
        total: totalCount,
    }

    const keyChange = (key: any): string => {
        return key.id
    }

    return (
        <Container>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <RangePicker 
                    onChange={onChangeHandle}
                    format={"DD-MM-YYYY"}
                    defaultValue={[moment(), moment()]}
                />
                <div style={{width: 20}} />
                <DownloadButton
                    icon={<ExportOutlined/>}
                    onClick={() => {
                        onHandleDownload();
                    }}
                    disabled={!data.length}
                    children={"Export to Excel"}
                />
            </div>
            <TableCustom columns={columns}
                         bordered
                         onChange={onChange}
                         pagination={false}
                         loading={isLoading}
                         rowKey={(value) => keyChange(value)}
                         expandable={{
                             expandedRowRender: (row: any, index: number) => (
                                 <SaleReportDetailTable data={row} key={index}/>
                             ),
                         }}
                         dataSource={data}/>
        </Container>
    )
}

export default SaleReportTable