import React, { FunctionComponent, useEffect } from "react";
import FormLogin from "./components/FormLogin";
import styled from "styled-components";
import { withAuth } from "../../provider/AuthWrapper";
import { useHistory } from "react-router-dom";
import { ROUTE_URI_ENUM } from "../../routes/paths";

const LoginContainer = styled.div``;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
`;

const Login = styled.div`
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Avatar = styled.img`
  width: 250px;
  height: 250px;
  object-fit: contain;
`;

const LoginPage: FunctionComponent = () => {
  const history = useHistory();

  useEffect(() => {
    // if (localStorage.getItem("client_token")) {
    //   history.push(ROUTE_URI_ENUM.HOME);
    // }

    return () => {
      console.log("Login validated for token umount!");
    };
  }, []);

  return (
    <LoginContainer>
      <LogoContainer>
        <Avatar src={"/logo.png"} />
      </LogoContainer>
      <Login>
        <FormLogin />
      </Login>
    </LoginContainer>
  );
};

export default withAuth({ allowAnonymous: true })(LoginPage);
