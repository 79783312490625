"use strict";
exports.__esModule = true;
exports.AdminService = void 0;
var AdjustmentService_1 = require("../service/adjustment/AdjustmentService");
var ReportService_1 = require("../service/report/ReportService");
var ImportService_1 = require("../service/import/ImportService");
var SerialNumberService_1 = require("../service/serial/SerialNumberService");
var ItemService_1 = require("../service/item/ItemService");
var OrderService_1 = require("../service/order/OrderService");
var AdminService = /** @class */ (function () {
    function AdminService() {
    }
    AdminService.getInstance = function () {
        if (!AdminService.instance) {
            AdminService.instance = new AdminService();
        }
        return AdminService.instance;
    };
    AdminService.prototype.getAllAdjustmentService = function (params) {
        return (0, AdjustmentService_1.getAllAdjustmentService)(params);
    };
    AdminService.prototype.createAdjustmentService = function (data, params) {
        return (0, AdjustmentService_1.createAdjustmentService)(data, params);
    };
    AdminService.prototype.deleteAdjustmentService = function (code, params) {
        return (0, AdjustmentService_1.deleteAdjustmentService)(code, params);
    };
    AdminService.prototype.updateAdjustmentService = function (code, data, params) {
        return (0, AdjustmentService_1.updateAdjustmentService)(code, data, params);
    };
    // Report
    AdminService.prototype.getSaleReportService = function (params) {
        return (0, ReportService_1.getSaleReportService)(params);
    };
    AdminService.prototype.getSaleReportDetailService = function (code, params) {
        return (0, ReportService_1.getSaleReportDetailService)(code, params);
    };
    //Import
    AdminService.prototype.getImportByCodeService = function (code, params) {
        return (0, ImportService_1.getImportByCodeService)(code, params);
    };
    //Serial Number
    AdminService.prototype.updateSerialNumberService = function (code, data, params) {
        return (0, SerialNumberService_1.updateSerialNumberService)(code, data, params);
    };
    // Item
    AdminService.prototype.adminGetAllItemService = function (params) {
        return (0, ItemService_1.adminGetAllItemService)(params);
    };
    AdminService.prototype.adminGetItemByCodeService = function (code, params) {
        return (0, ItemService_1.adminGetItemByCodeService)(code, params);
    };
    // Order
    AdminService.prototype.createOrderService = function (request, file, params) {
        return (0, OrderService_1.createOrderService)(request, file, params);
    };
    AdminService.prototype.orderModifyService = function (code, data, params) {
        return (0, OrderService_1.orderModifyService)(code, data, params);
    };
    AdminService.prototype.adminCreateOrderService = function (data, file, params) {
        return (0, OrderService_1.adminCreateOrderService)(data, file, params);
    };
    AdminService.prototype.adminGetOrderByCodeService = function (code, params) {
        return (0, OrderService_1.adminGetOrderByCodeService)(code, params);
    };
    return AdminService;
}());
exports.AdminService = AdminService;
