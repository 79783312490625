import React, {FunctionComponent} from 'react'
import MainLayout from "./MainLayout";
import {withAuth} from './../provider/AuthWrapper'

const HomePage:FunctionComponent = () => {
    return(
        <MainLayout data={"Hello data"}>

        </MainLayout>
    )
}

export default withAuth()(HomePage)