import {CloseCircleOutlined} from "@ant-design/icons";
import React, {FunctionComponent} from "react";
import styled from "styled-components";


interface ImageCardUrlReadProps {
    index: number
    url: string,
    setUrl: (value:any) => void,
    urls: string[]
}


const Container = styled.div`
    width: 150px ; 
    height: 150px;
    object-fit: contain;
    border: 1px solid #ccc;
    margin-top: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: white;
    border-radius: 4px;
`


const IconCustom = styled(CloseCircleOutlined)`
    font-size: 24px;
    margin: 5px 5px 0px 0px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`

const ImageCardUrlRead:FunctionComponent<ImageCardUrlReadProps> = (props) => {

    const { url,urls,setUrl,index } = props 


    const onRemoveHandle = () => {
        const removeUrl = urls
        removeUrl.splice(index,1)
        setUrl([...removeUrl])
            
    }

    return(
        <Container style={{backgroundImage: `url(${url}`}}>
            <IconCustom onClick={onRemoveHandle}/>
        </Container>
    )
}

export default ImageCardUrlRead;