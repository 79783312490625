// @ts-nocheck

import {DeleteFilled} from "@ant-design/icons";
import {
    ClientService,
    DiscountType,
    ItemResponse,
    OrderDetailRequest,
    OrderDetailResponse,
    OrderResponse,
} from "@cubetiq/computer-web-sdk";
import {Button, Col, Divider, Form, Input, InputNumber, message, Row, Spin} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";
import styled from "styled-components";
import SelectTagCustom from "../../pages/order/component/SelectTagCustom";
import SelectItemCustom from "../select/SelectItemCustom";
import SelectDiscountType from "../../pages/order/component/SelectDiscountType";
import {dateFnsFormat} from "../../util/DateUtils";
import {getErrorMessage} from "../../util/utils";

interface OrderDetailEditFormProps {
    order: OrderResponse;
}

const Container = styled.div`
  margin-top: 20px;
  min-width: 1000px;

  .ant-input[disabled] {
    background-color: white;
    color: black;
  }

  .ant-input-number-input {
    text-align: center;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }
`;


const DeleteIcon = styled(DeleteFilled)`
  font-size: 24px;
  color: red;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    opacity: 0.5;
  }
`;

const Selector = styled.div`
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
  }
`


const ButtonSubmit = styled(Button)`
  width: 100%;
  font-family: "Poppins", sans-serif;
  letter-spacing: 5px;
  border: none;
  background-color: #19aa8d;
  color: white;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;

  &:hover {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }
`;

const AvatarCustom = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-top: 15px;
`;

const OrderCode = styled.div`
  font-size: 16px;
  font-weight: 600;
`

const OrderDate = styled.div`
  font-size: 16px;

`


const OrderHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const admin = ClientService.getInstance()
const OrderDetailEditForm: FunctionComponent<OrderDetailEditFormProps> = (
    props
) => {
    const {order} = props;
    const [details, setDetails] = useState<Array<OrderDetailResponse>>([]);
    const [items, setItems] = useState<Array<ItemResponse>>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        admin.adminGetAllItemService({
            available: true,
            paged: false

        }).then(r => {
            setItems(r.data)
        })


    }, []);

    useEffect(() => {
        if (order === undefined) return;
        setDetails(order.details);
    }, [order]);

    const onFinish = (values: any) => {

        const newOrder: OrderDetailResponse[] & { serials: string[] } = values["details"];
        setIsLoading(true);
        const request = newOrder.map((it, index) => {

            let s = it.serials
            let r: string[] = []
            const check = s?.some(it => "serial" in it)

            if (check) {
                r = s?.map(it => it.code) ?? []
            }

            return {
                itemId: it.item.id,
                price: it.price,
                qty: it.qty,
                discount: it.discount.discountValue ?? 0,
                discountType: it.discount.discountType ?? DiscountType.PERCENTAGE,
                serialCode: check ? r : s,
            } as OrderDetailRequest
        });


        console.log(request)

        admin
            .orderDetailMultipleUpdateService(order.code, request)
            .then((r: any) => {
                message.success("Update Order Successfully");
            })
            .catch((r: any) => {
                message.error(getErrorMessage(r));
            })
            .finally(() => setIsLoading(false));
    };

    const onFinishFailed = (errorInfo: any) => {
    };

    const form = Form.useForm()[0];

    const onAddHandle = (v: any) => {
        const item: ItemResponse = JSON.parse(v);
        if (item === undefined) return;
        const request: OrderDetailResponse = {
            id: "",
            item: {
                id: item.id!!,
                code: item.code,
                attachments: item?.attachments?.[0].originalLink!!,
                memo: item.memo,
                name: item.name!!,
                price: item.price!!,
                stockQty: item.stockQty.toString(),
                isDecimal: false,
                serials: item.serials.inStock
            },
            price: item.price!!,
            qty: 1,
            serials: [],
            mergeSerials: item.serials.inStock,
            discount: {
                discountValue: item.discount?.discountValue ?? 0,
                discountType: item.discount?.discountType ?? DiscountType.PERCENTAGE,
                afterDiscount: item.discount?.afterDiscount ?? 0,
                discountPrice: item.discount?.discountPrice ?? 0
            },
        };


        setDetails([...details, request]);

        return request;
    };


    const getSerial = (index: number) => {
        return details[index].mergeSerials ?? []
    }


    const [selectLimit, setSelectLimit] = useState<number[]>([])


    const QuantityChange = (v: any, index: number) => {
        selectLimit[index] = Number(v)
        setSelectLimit([...selectLimit])
    }


    const LabelRender = (prefix: string, index: number) => {
        return (
            <div>
                {
                    prefix
                }
                <span style={{color: "green", marginLeft: 10}}>
                     In Stock Have
                     (
                    {
                        details[index].item.stockQty
                    }
                    )
                </span>
            </div>
        )
    }


    return (
        <Container>

            <OrderHeaderContainer>
                <OrderCode children={`Code: ${order.code}`}/>
                <OrderDate children={`Date: ${dateFnsFormat(order.date ?? 0)}`}/>
            </OrderHeaderContainer>

            {details.length > 0 && items.length > 0 && (
                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    form={form}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.List name={"details"} initialValue={details}>
                        {(fields, {add, remove}) => (
                            <>
                                <Row>
                                    <Col md={24} lg={24}>
                                        <Form.Item label={"Add New Item"} name={"addItem"}>
                                            {/*<SelectCustom*/}
                                            {/*    data={items}*/}
                                            {/*    onChange={(v) => {*/}
                                            {/*        const addDetail = onAddHandle(v);*/}
                                            {/*        add(addDetail, 0);*/}
                                            {/*    }}*/}
                                            {/*    clear={true}*/}
                                            {/*/>*/}


                                            <Selector>
                                                <SelectItemCustom data={items} onChange={(v) => {
                                                    if (v === null)
                                                        return null
                                                    const it = onAddHandle(v)
                                                    add(it)
                                                }}/>
                                            </Selector>
                                        </Form.Item>
                                        <Divider/>
                                    </Col>
                                </Row>

                                {fields.map((field, index) => {
                                    return (
                                        <div key={field.key}>
                                            <Row gutter={[10, 10]}>
                                                <Col span={2}>
                                                    <Form.Item
                                                        name={[field.name, "item", "attachments"]}
                                                        fieldKey={[field.fieldKey, "item", "attachments"]}
                                                    >
                                                        <AvatarCustom
                                                            src={details[field.key].item.attachments}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={22}>
                                                    <Row gutter={[15, 0]}>
                                                        <Col span={9}>
                                                            <Form.Item
                                                                name={[field.name, "item", "name"]}
                                                                fieldKey={[field.fieldKey, "item", "name"]}
                                                                label={LabelRender("Product Name", index)}
                                                                required
                                                            >
                                                                <Input disabled/>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={4}>
                                                            <Form.Item
                                                                name={[field.name, "price"]}
                                                                label={"Price"}
                                                                required
                                                                fieldKey={[field.fieldKey, "price"]}
                                                            >
                                                                <InputNumber disabled
                                                                             style={{
                                                                                 width: "100%",
                                                                                 backgroundColor: "white",
                                                                                 color: "black"
                                                                             }}/>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={3}>
                                                            <Form.Item
                                                                name={[field.name, "qty"]}
                                                                label={"Quantity"}
                                                                required
                                                                fieldKey={[field.fieldKey, "qty"]}
                                                            >
                                                                <InputNumber min={0}
                                                                             onChange={
                                                                                 (v) => QuantityChange(v, index)
                                                                             }
                                                                             max={
                                                                                 Number(details[index].item.stockQty)
                                                                             }
                                                                             style={{width: "100%"}}/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={3}>
                                                            <Form.Item
                                                                name={[field.name, "discount", "discountValue"]}
                                                                label={"Discount"}
                                                                fieldKey={[field.fieldKey, "discount", "discountValue"]}
                                                            >
                                                                <InputNumber style={{width: "100%"}}/>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={4}>
                                                            <Form.Item
                                                                name={[field.name, "discount", "discountType"]}
                                                                label={"Discount Type"}
                                                                fieldKey={[field.fieldKey, "discount", "discountType"]}
                                                            >
                                                                <SelectDiscountType style={{textAlign: "center"}}/>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col
                                                            span={1}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Form.Item>
                                                                <DeleteIcon onClick={() => {
                                                                    remove(field.name)

                                                                    details.splice(index, 1)
                                                                    setDetails([...details])
                                                                }}/>
                                                            </Form.Item>
                                                        </Col>

                                                        {
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[field.name, "serials"]}
                                                                    fieldKey={[field.fieldKey, "serials"]}
                                                                    initialValue={details[index].serials?.map(it => it.code)}
                                                                >
                                                                    <SelectTagCustom data={getSerial(index)}
                                                                                     field={"serial"}
                                                                                     placeholder={"Serial Number"}
                                                                                     defaultValue={
                                                                                         details[index].serials
                                                                                     }
                                                                                     limit={selectLimit[index] ?? 1}/>
                                                                </Form.Item>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </Form.List>

                    <Row>
                        <Col span={24}>
                            <Spin spinning={isLoading} size={"default"}>
                                <ButtonSubmit htmlType={"submit"} children={"Save"}/>
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            )}
        </Container>
    );
};

export default OrderDetailEditForm;
