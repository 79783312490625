export enum AUTHORITY_TYPES {
    VIEW_HOME='VIEW_HOME',
    VIEW_ITEM='VIEW_ITEM',
    VIEW_CATEGORY='VIEW_CATEGORY',
    VIEW_BRAND='VIEW_BRAND',
    VIEW_LOGIN='VIEW_LOGIN',
    VIEW_TEST='VIEW_TEST',
    ORDER = 'CREATE_ORDER',
    CREATE_ITEM = 'CREATE_ITEM',
    UPDATE_ITEM = 'UPDATE_ITEM',
    VIEW_IMPORT=  "VIEW_IMPORT",
    VIEW_ORDER = "VIEW_ORDER",
    VIEW_STOCK = "VIEW_STOCK",
    VIEW_SPECIFICATION = "VIEW_SPECIFICATION",
    VIEW_USER = "VIEW_USER",
    VIEW_SLIDER = "VIEW_SLIDER",
    VIEW_VISITOR = "VIEW_VISITOR",
    CREATE_SLIDER = "CREATE_VISITOR",
    CREATE_SUPPLIER = "CREATE_SUPPLIER",
    VIEW_SUPPLIER = "VIEW_SUPPLIER",
    VIEW_LOCATION = "VIEW_LOCATION",
    VIEW_ADJUSTMENT = "VIEW_ADJUSTMENT"
}

export const AUTHORITY_GROUPS = {
    HOME:[
      AUTHORITY_TYPES.VIEW_HOME
    ],
    ITEM: [
        AUTHORITY_TYPES.VIEW_ITEM,
        AUTHORITY_TYPES.CREATE_ITEM,
        AUTHORITY_TYPES.UPDATE_ITEM
    ],
    CATEGORY:[
        AUTHORITY_TYPES.VIEW_CATEGORY,
    ],
    SPECIFICATION:[
        AUTHORITY_TYPES.VIEW_SPECIFICATION,
    ],
    ORDER:[
        AUTHORITY_TYPES.VIEW_ORDER,
    ],
    USER:[
        AUTHORITY_TYPES.VIEW_USER,
    ],
    BRAND:[
        AUTHORITY_TYPES.VIEW_BRAND,
    ],
    LOGIN:[
        AUTHORITY_TYPES.VIEW_LOGIN,
    ],
    IMPORT:[
        AUTHORITY_TYPES.VIEW_IMPORT,
    ],
    STOCK:[
        AUTHORITY_TYPES.VIEW_STOCK,
    ],
    TEST:[
        AUTHORITY_TYPES.VIEW_TEST
    ],
    VISITOR: [
        AUTHORITY_TYPES.VIEW_VISITOR
    ],
    SLIDER: [
        AUTHORITY_TYPES.VIEW_VISITOR,
        AUTHORITY_TYPES.CREATE_SLIDER
    ],
    SUPPLIER: [
        AUTHORITY_TYPES.CREATE_SUPPLIER,
        AUTHORITY_TYPES.VIEW_SUPPLIER
    ],
    LOCATION: [
        AUTHORITY_TYPES.VIEW_LOCATION
    ],
    ADJUSTMENT: [
        AUTHORITY_TYPES.VIEW_ADJUSTMENT
    ]
}