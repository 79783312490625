import {ClientService, ItemResponse, ServerResponse, TotalCostAndStockResponse} from "@cubetiq/computer-web-sdk";
import React, {FunctionComponent, useEffect, useState} from "react";
import {ColumnType} from "antd/es/table";
import styled from "styled-components";
import {Input, Table, TablePaginationConfig} from "antd";
import {toUSD} from "../../util/utils";
import {EditFilled} from "@ant-design/icons";
import ModalCustom from "../ModalCustom";
import {ReloadButton} from "./BrandTable";
import {ReloadIcon} from "./ImportTable";
import UpdateCostForm from "../form/UpdateCostForm";


const TableCustom = styled(Table)`
  padding: 20px 0px 0px 0px;

  .ant-table-expanded-row {
    .ant-table-cell {
      background: none;
    }

    .ant-table-content {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
`

const EditIcon = styled(EditFilled)`
  font-size: 24px;
  color: #00bfff;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`


const AvatarCustom = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`


const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const {Search} = Input;


const SearchCustom = styled(Search)`
  width: 400px;

`


const client = ClientService.getInstance();

const StockTable: FunctionComponent = () => {

    const [items, setItems] = useState<Array<ItemResponse>>()
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [totalCostAndStock, setTotalCostAndStock] = useState<TotalCostAndStockResponse>()
    const [selectedItem, setSelectedItem] = useState<ItemResponse>()
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
    const [isEditCost, setIsEditCost] = useState(false)
    const [isFirstRender,setIsFirstRender] = useState(true)
    const [currentPage,setCurrentPage] = useState(1)

    const query = client.adminGetAllItemService

    const onEditCostHandle = () => {
        setIsEditCost(!isEditCost)
    }

    useEffect(() => {
        onLoadData()
        setIsFirstRender(false)
    }, [])


    const onLoadData = () => {
        setIsLoading(true)
        query({
            size: 10
        }).then((r: ServerResponse<ItemResponse[]>) => {
            setItems(r.data)
            setTotalCount(r.pages.totalCount)
        }).finally(() => setIsLoading(false))

        client.getTotalCostAndStockService().then((r: ServerResponse<TotalCostAndStockResponse>) => {
            setTotalCostAndStock(r.data)
        })
    }


    const columns: Array<ColumnType<any>> = [
        {
            title: 'Item Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Product',
            dataIndex: 'name',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Avatar',
            dataIndex: 'attachments',
            align: "center",
            key: 'code',
            width: 200,
            render: v => {
                return (
                    <AvatarCustom src={v?.[0]?.originalLink}/>
                )
            }
        },
        {
            title: 'Stock Quantity',
            dataIndex: 'stockQty',
            align: "center",
            key: 'code',
            width: 200
        },
        {
            title: 'Stock Hold',
            dataIndex: 'stockHold',
            align: "center",
            key: 'code',
            width: 200,
            sorter: (a, b) => a.stockHold - b.age,
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            align: "center",
            key: 'cost',
            width: 200,
            render: (v, row) => {
                return (
                    <React.Fragment>
                        {
                            toUSD.format(v)
                        }
                        <EditIcon style={{marginLeft: 30}} onClick={() => {
                            setSelectedItem(row)
                            onEditCostHandle()
                        }}/>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Sale Price',
            dataIndex: 'price',
            align: "center",
            key: 'price',
            width: 200,
            render: v => {
                return (
                    <React.Fragment>
                        {
                            toUSD.format(v)
                        }
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Total Cost',
            dataIndex: 'totalCost',
            align: "center",
            key: 'totalCost',
            width: 200,
            render: v => {
                return (
                    <React.Fragment>
                        {
                            toUSD.format(v)
                        }
                    </React.Fragment>
                )
            }
        },

    ]

    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        setCurrentPage(pagination.current)
    }


    const PaginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            query({
                page: (v - 1),
                size: 10,
                q: searchValue
            }).then(r => {
                setItems(r.data)
                setTotalCount(r.pages.totalCount)
            })
        },
        total: totalCount,
        current: currentPage
    }

    const onSearch = (value: string) => {
        setSearchValue(value)
        if (value.trim().length > 0) {
            query({
                q: value,
                size: 10
            }).then(r => {
                setItems(r.data)
                setTotalCount(r.pages.totalCount)
                setCurrentPage(1)
            })
        } else {
            setSearchValue(undefined)
        }
    }


    useEffect(() => {
        if(isFirstRender) return
        if (searchValue === undefined) {
            query({
                size: 10
            }).then(r => {
                setItems(r.data)
                setTotalCount(r.pages.totalCount)
                setCurrentPage(1)
            })
        }
    }, [searchValue])

    const ModalEditCost = () => {
        if (selectedItem === undefined)
            return <></>

        return (
            <ModalCustom visible={isEditCost}
                         onCancel={onEditCostHandle}
                         width={400}
                         centered
                         footer={null}
                         children={<UpdateCostForm item={selectedItem}/>}/>
        )
    }


    return (
        <React.Fragment>
            <Container>
                <ReloadButton icon={<ReloadIcon/>}
                              children={"Reload"}
                              onClick={() => {
                                  setSearchValue(undefined)
                                  onLoadData()
                              }}
                              style={{marginLeft: 0}}/>
                <SearchCustom placeholder="Please input item code or item name"
                              onSearch={onSearch}
                              allowClear
                              enterButton/>
            </Container>


            <ModalEditCost/>

            <TableCustom columns={columns}
                         bordered
                         onChange={onChange}
                         loading={isLoading}
                         pagination={PaginationConfig}
                         dataSource={items}
                         summary={(pageData) => {
                             return (
                                 <React.Fragment>
                                     <Table.Summary.Row style={{textAlign: "center"}}>

                                         <Table.Summary.Cell index={0} colSpan={3}>

                                         </Table.Summary.Cell>

                                         <Table.Summary.Cell index={1}>
                                             {
                                                 totalCostAndStock?.totalStockQty ?? 0
                                             }
                                         </Table.Summary.Cell>

                                         <Table.Summary.Cell index={2}>
                                             {
                                                 totalCostAndStock?.totalStockHold ?? 0
                                             }
                                         </Table.Summary.Cell>

                                         <Table.Summary.Cell index={3}>

                                         </Table.Summary.Cell>

                                         <Table.Summary.Cell index={4}>

                                         </Table.Summary.Cell>

                                         <Table.Summary.Cell index={5}>
                                             {
                                                 toUSD.format(totalCostAndStock?.grandTotalCost ?? 0)
                                             }
                                         </Table.Summary.Cell>

                                     </Table.Summary.Row>
                                 </React.Fragment>
                             )
                         }}


            />
        </React.Fragment>
    )
}

export default StockTable;