import { DeleteFilled, EditFilled, QuestionCircleOutlined } from "@ant-design/icons"
import { BrandResponse, ClientService, ModelResponse, SpecificationResponse, SubCategoryResponse } from "@cubetiq/computer-web-sdk"
import { Button } from "antd"
import { Popconfirm } from "antd"
import { message } from "antd"
import { Spin, Table } from "antd"
import { ColumnType } from "antd/es/table"
import React,{ FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { AddIconCustom } from "../../pages/import"
import { getErrorMessage } from "../../util/utils"
import CreateSpecificationForm from "../form/CreateSpecificationForm"
import UpdateModelForm from "../form/UpdateModelForm"
import UpdateSpecificationForm from "../form/UpdateSpecificationForm"
import UpdateSubCategoryForm from "../form/UpdateSubCategoryForm"
import ModalCustom from "../ModalCustom"
import { ReloadButton } from "./BrandTable"
import { ReloadIcon } from "./ImportTable"



const TableCustom = styled(Table)`
   padding: 20px 0px 0px 0px;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`



const CreateButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 120px;
    &:hover {
        background-color: #00bfff;
        color: white;
        opacity: 0.8;
    }
    &:active {
        background-color: black;
        color: white;
        opacity: 0.8;
    }
`



const Container = styled.div`

`


const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`


interface SpecificationTableProps  {
    
}



const EditIcon = styled(EditFilled)`
    font-size: 24px;
    color: #00bfff;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`


const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`

const IconContainer = styled.div`

`


const client = ClientService.getInstance()
const SpecificationTable:FunctionComponent<SpecificationTableProps> = (props) => {

    const [selectedSpecification,setSelectedSpecification] = useState<SpecificationResponse>()
    const [isUpdate,setIsUpdate] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const onIsUpdateHandle = () =>{
        setIsUpdate(!isUpdate)
    }


    const [specifications,setSpecification] = useState<Array<SpecificationResponse>>()


    useEffect(()=>{
       onLoadData()
    },[])




    const onLoadData = () => {
        setIsLoading(true)
        client.getAllSpecificationService({
            paged: false
        }).then(r => {
            setSpecification(r.data)
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=> setIsLoading(false))
    }


    const onRemoveSpecification = (specification:SpecificationResponse) => {
        client.deleteSpecificationService(specification.code).then(res => {
            client.getAllSpecificationService().then(r => {
                setSpecification(r.data)
                message.success("Delete successfully")
            }).catch(r => {
                message.error(getErrorMessage(r))
            })
        }).catch(res => {
            message.error(getErrorMessage(res))
        })
    }


    const columns: Array<ColumnType<any>> = [
        {
            title: 'Specification Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Specification Name',
            dataIndex: 'name',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: "center",
            key: 'code',
            width: 200,
            render:(v,row) => {

                return(
                    <IconContainer>
                        <EditIcon onClick={()=>{
                            setSelectedSpecification(row)
                            onIsUpdateHandle()
                        }}/>

                        <Popconfirm title={`Are you sure to delete ( ${row.name} ) ? `} 
                                    onConfirm={()=> onRemoveSpecification(row)}
                                    icon={<QuestionCircleOutlined 
                                    style={{ color: 'red', fontSize: 16 }} />}>
                            <DeleteIcon/>
                        </Popconfirm>
                    </IconContainer>
                )
            }
        }
    ]


    const UpdateSpecification = () => {
        if (selectedSpecification === undefined)
            return <></>
        return (
            <ModalCustom visible={isUpdate}
                footer={null}
                width={500}
                onCancel={onIsUpdateHandle}
                children={<UpdateSpecificationForm specification={selectedSpecification} />} />
        )
    }

    const [isCreate,setIsCreate] = useState(false)
    const onIsCreateHandle = () => {
        setIsCreate(!isCreate)
    }




    return(
       <Container>
           <UpdateSpecification/>
            <CreateButton children={"Create"} onClick={onIsCreateHandle} icon={<AddIconCustom/>}/>
            <ReloadButton icon={<ReloadIcon/>} children={"Reload"} onClick={onLoadData}/>
            <ModalCustom visible={isCreate}
                footer={null}
                width={500}
                onCancel={onIsCreateHandle}
                children={<CreateSpecificationForm />} />

          {
                <TableCustom columns={columns}
                    bordered
                    loading={isLoading}
                    pagination={false}
                    dataSource={specifications} />
           }
       </Container>
    )
}

export default SpecificationTable