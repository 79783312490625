import React, {FunctionComponent, useState} from "react";
import MainLayout from "../MainLayout";
import styled from "styled-components";
import {Button} from "antd";
import {AppstoreAddOutlined} from "@ant-design/icons/lib";
import ModalCustom from "../../components/ModalCustom";
import ImportForm from "../../components/form/ImportForm";
import ImportTable from "../../components/table/ImportTable";
import { withAuth } from "../../provider/AuthWrapper";


const Container = styled.div`

`


export const CreateButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: white;
     font-family: 'Poppins', sans-serif;
     width: 120px;
     &:hover {
        background-color: #808080;
        color: white;
     }
`

export const AddIconCustom = styled(AppstoreAddOutlined)`
    font-size: 18px;
`


const ImportPage: FunctionComponent = () => {
    const [isCreate, setIsCreate] = useState(false)

    const onCreateHandle = () => {
        setIsCreate(!isCreate)
    }


    return (
        <MainLayout>
            <Container>
                {/* <ModalCustom visible={isCreate}
                             footer={null}
                             width={1000}
                             centered
                             onCancel={onCreateHandle}
                             children={<ImportForm/>}/>

                <CreateButton icon={<AddIconCustom/>}
                              onClick={onCreateHandle}
                              children={"Create"}/> */}

                <ImportTable/>
            </Container>
        </MainLayout>
    )
}

export default withAuth()(ImportPage)