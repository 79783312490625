import {ArrowLeftOutlined, CloudUploadOutlined} from '@ant-design/icons'
import {Button, Col, Row} from 'antd'
import React, {FunctionComponent, useEffect, useState} from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import ImageCard from '../card/ImageCard'
import {SelectMultipleFileUtils} from '../util/Logging'


const Container = styled.div`
    width: 100%;
`

const Contain = styled.div`
    min-height: 400px;
    margin-top: 20px;
    border-radius: 4px;
    background-color: #808080;
`

const ButtonUploadCustom = styled(Button)`
    text-align: center;
    background-color: #00bfff;
    font-family: 'Poppins', sans-serif;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;
`;

const ButtonBackCustom = styled(ButtonUploadCustom)`
    margin-right: 10px;
`;


interface MultipleUploadCustomProps {
    onChange?: (value: any) => void
}


const MultipleUploadCustom: FunctionComponent<MultipleUploadCustomProps> = (props) => {

    const [files, setFiles] = useState<FileList>();

    const history = useHistory();


    const onUploadFileHandle = () => {
        SelectMultipleFileUtils(setFiles)
    }


    useEffect(() => {
        if (files === undefined)
            return
        typeof props.onChange === "function" && props.onChange(files)
    }, [files])


    return (
        <Container>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <ButtonBackCustom
                    icon={
                        <ArrowLeftOutlined style={{fontSize: 24}}/>
                    }
                    children={"Back"}
                    onClick={() => history.push("/items")}
                />
                <ButtonUploadCustom icon={<CloudUploadOutlined
                    style={{fontSize: 24}}/>}
                                    onClick={onUploadFileHandle}
                                    children={"Upload"}
                />
            </div>
            <Contain>
                <Row gutter={[8, 16]}>
                    {
                        files !== undefined &&
                        Array.from(files).map((it, index) => {
                            return (
                                <Col span={3} style={{display: "flex", justifyContent: "center"}} key={index}>
                                    <ImageCard file={it} index={index} setFile={setFiles} files={files}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Contain>
        </Container>
    )
}

export default MultipleUploadCustom