import {
    CheckCircleOutlined,
} from '@ant-design/icons';
import React from "react";
import {ClockCircleOutlined, CloseCircleOutlined, SyncOutlined} from "@ant-design/icons/lib";
import { OrderStatus } from '@cubetiq/computer-web-sdk';
import { Tag } from 'antd';

export const renderColorTag = (key: string) => {
    switch (key) {
        case "APPROVE":
            return {
                color: "processing",
                icon:  <SyncOutlined spin/>
            }
        case "CANCEL":
            return {
                color: "error",
                icon: <CloseCircleOutlined/>
            }
        case "DONE":
            return {
                color: "success",
                icon: <CheckCircleOutlined/>
            }
        case "PENDING":
            return {
                color: "warning",
                icon: <ClockCircleOutlined />
            }
        default:
            break
    }
}


export const renderOrderTag = (value:OrderStatus) => {
    const p = renderColorTag(value)
    switch(value){
        case OrderStatus.PENDING:
            return(
                <Tag {...p}>
                    {value}
                </Tag>
            )
        case OrderStatus.CANCEL:
            return(
                <Tag {...p}>
                    {value}
                </Tag>
            )
        case OrderStatus.DONE:
            return(
                <Tag {...p}>
                    {value}
                </Tag>
            )
        case OrderStatus.APPROVE:
            return(
                <Tag {...p}>
                    {value}
                </Tag>
            )
        default:
            break
    }
}

export const renderHTML = (value:any) =>{
    if(!value)
        return
    return(
        <span dangerouslySetInnerHTML={{ __html: value}}/>
    )
}