import {Col, Row} from 'antd'
import React, {FunctionComponent} from 'react'
import styled from 'styled-components'
import {toUSD} from '../../util/utils'
import {DiscountType} from "@cubetiq/computer-web-sdk";


interface TotalFooterProps {
    data: Array<ItemTotalAmount>
    locationPrice: number | 0
    discount: number
    discountType: DiscountType
}



const Container = styled.div`

`


const LeftColumn = styled(Col)`
    text-align: right;
    padding-right: 10px;
`

const RightColumn = styled(Col)`
    text-align: center;
`


const Title = styled.div`
    font-size: 18px;
`

const Value = styled.div`
    font-size: 18px;
`




export interface ItemTotalAmount {
    price: number,
    qty: number,
    discount: number,
    amount: number,
    cost?: number
}



const TotalFooter:FunctionComponent<TotalFooterProps>  = (props) => {
    const { data, locationPrice, discount, discountType } = props


    const getTotalPrice = () => {
        let sum = 0 
        data.forEach(it => {
            sum += it.amount
        })

        if(discountType === DiscountType.PERCENTAGE){
            if(discount > 0 ){
                const discountPrice = sum * discount / 100
                return sum - discountPrice
            }
            else
                return sum
        }
        else {
            return sum - discount
        }

    }







    return(
       <Container>
           <Row>

               <LeftColumn span={18} >

                    <Title>
                        Item Count : 
                    </Title>

                    <Title>
                        Discount : 
                    </Title>

                    <Title>
                        Delivery Price : 
                    </Title>

                    <Title>
                        Total Price : 
                    </Title>

               </LeftColumn>

               <RightColumn span={6}>

                    <Value>
                        {
                            data.length
                        }
                    </Value>

                   <Value>
                       {
                            discount
                       } %
                   </Value>


                   <Value>
                       {
                          toUSD.format(locationPrice)
                       } 
                   </Value>

                   <Value>
                       {
                           toUSD.format(getTotalPrice())
                       } 
                   </Value>

                </RightColumn>

           </Row>
       </Container>
    )
}   

export default TotalFooter