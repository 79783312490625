import moment from "moment";
import { format } from 'date-fns'

export const FORMAT = "DD-MM-YYYY"
export const FORMAT_WITH_TIME = "DD-MM-YYYY h:mm a"
export const DATE_FORMAT = "dd-MM-yyyy p"

export const formatDate = (value:number) => {
    return moment.unix(value).format(FORMAT)
}

export const formatDateWithTime= (value:number) => {
    return moment.unix(value).format(FORMAT_WITH_TIME)
}


export const dateFnsFormat = (value:number) => {
    return format(new Date(value), DATE_FORMAT)
}