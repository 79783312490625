// @ts-nocheck

import { ClientService, DiscountType, OrderResponse, OrderSortStatus, OrderStatus } from "@cubetiq/computer-web-sdk";
import React, { FunctionComponent, useEffect, useState } from "react";
import { ColumnType } from "antd/es/table";
import styled from "styled-components";
import { Button, Col, Dropdown, Input, Menu, message, Popconfirm, Row, Table, TablePaginationConfig, Tag } from "antd";
import { getErrorMessage, toUSD } from "../../util/utils";
import { renderColorTag } from "../../util/ComponentUtils";
import { CheckCircleOutlined, CloseCircleOutlined, EditFilled, EyeOutlined, SyncOutlined,QuestionCircleOutlined } from "@ant-design/icons";
import ModalCustom from "../ModalCustom";
import OrderDetailTable from "./OrderDetailTable";
import OrderDetailEditForm from "../form/OrderDetailEditForm";
import OrderSort from "../select/OrderSort";
import CreateOrderForm from "../form/CreateOrderForm";
import { ReloadButton } from "./BrandTable";
import { ReloadIcon } from "./ImportTable";
import { AddIconCustom } from "../../pages/import";
import { dateFnsFormat, formatDate, formatDateWithTime } from "../../util/DateUtils";
import { DeleteIcon } from "../icon/IconCustom";


const TableCustom = styled(Table)`
  padding: 20px 0 0 0;

  .ant-table-expanded-row {
    .ant-table-cell {
      background: none;
    }

    .ant-table-content {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
`

const Center = styled.div`
  text-align: center;
`

const TagCustom = styled(Tag)`
  cursor: pointer;
`

const ViewIconCustom = styled(EyeOutlined)`
  font-size: 16px;
  cursor: pointer;
`


const ImageView = styled.img`
  width: 500px;
  height: 500px;
  object-fit: contain;
  margin-top: 20px;
`

const MenuItemCustom = styled(Menu.Item)`
  padding: 15px 10px 15px 10px;
`


const EditIcon = styled(EditFilled)`
  font-size: 24px;
  color: #00bfff;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`


const Contain = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

`


const CreateButton = styled(Button)`
  background-color: #00bfff;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-family: 'Poppins', sans-serif;
  width: 120px;

  &:hover {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }

  &:active {
    background-color: black;
    color: white;
    opacity: 0.8;
  }
`

const { Search } = Input;
const client = ClientService.getInstance();
const OrderTable: FunctionComponent = () => {

    const [orders, setOrders] = useState<Array<OrderResponse>>([])
    const [selectOrder, setSelectOrder] = useState<OrderResponse>()
    // const [orderDelete, setOrderDelete] = useState<OrderResponse[]>([])
    const [isView, setIsView] = useState(false)
    const [attachment, setAttachment] = useState("")
    const [sort, setSort] = useState<OrderSortStatus>()
    const [isCreate, setIsCreate] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [pageSize, setPageSize] = useState(10)
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)

    const query = client.getAllOrderService

    const [isEdit, setIsEdit] = useState(false)
    const onIsEditHandle = () => {
        setIsEdit(!isEdit)
    }


    const onIsViewHandle = () => {
        setIsView(!isView)
    }


    useEffect(() => {
        onLoadData()
        setIsFirstRender(false)
    }, [])


    useEffect(() => {
        if (isFirstRender) return
        if (searchValue === undefined) {
            query({
                size: pageSize
            }).then(r => {
                setOrders(r.data)
                setTotalCount(r.pages.totalCount)
                setCurrentPage(1)
            })
        }
    }, [searchValue])


    const onLoadData = () => {
        setIsLoading(true)
        query({
            size: pageSize
        }).then(r => {
            setOrders(r.data)
            setTotalCount(r.pages.totalCount)

        }).finally(() => setIsLoading(false))
    }


    useEffect(() => {
        if (sort === undefined)
            return

        if (sort === OrderSortStatus.ALL) {
            client.getAllOrderService({
                q: searchValue
            }).then(r => {
                setOrders(r.data)
            })
        } else {
            client.getAllOrderService({
                status: sort.toString(),
                q: searchValue
            }).then(r => {
                setOrders(r.data)
            })
        }
    }, [sort])


    const columns: Array<ColumnType<any>> = [
        {
            title: 'Order Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Order Date',
            dataIndex: 'date',
            align: "center",
            key: 'date',
            width: 200,
            render: value => dateFnsFormat(value)
        },
        {
            title: 'Delivery Fee',
            dataIndex: 'delivery',
            align: "center",
            key: 'delivery',
            width: 200,
            render: value => {
                return (
                    <div>
                        {
                            value.address
                        }
                        <span style={{ marginLeft: 5 }}>
                            ({toUSD.format(value.price)})
                        </span>
                    </div>
                )
            }
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            colSpan: 2,
            align: "center",
            render: (value) => {

                return (
                    <Center>
                        {value.username}
                    </Center>
                )

            }
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            colSpan: 0,
            align: "center",
            render: (value) => {

                return (
                    <Center>
                        {value.phoneNumber}
                    </Center>
                )

            }
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            align: "center",
            colSpan: 2,
            key: 'paymentMethod',
            width: 150,
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            align: "center",
            colSpan: 0,
            key: 'paymentMethod',
            render: (value, row: OrderResponse) => {
                return (
                    <Center>
                        {
                            value === "Cash" ? <>N/A</>
                                :
                                <ViewIconCustom onClick={() => {
                                    onIsViewHandle()
                                    setAttachment(row.attachment)
                                }} />
                        }
                    </Center>
                )
            }
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            align: "center",
            width: 150,
            render: v => {
                return (
                    <React.Fragment>
                        {
                            v.discountValue
                        }
                        {
                            v.discountType === DiscountType.PERCENTAGE ? "%" : "$"
                        }
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalPriceAfterDiscount',
            key: 'totalPriceAfterDiscount',
            align: "center",
            render: value => {
                return (
                    <div>
                        {
                            toUSD.format(value)
                        }
                    </div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: "center",
            key: 'status',
            render: (value, row: any) => {
                const prop = renderColorTag(value)
                return (

                    <div style={{ textAlign: 'center' }}>
                        {
                            value === "PENDING" || value === "APPROVE" ?
                                <Dropdown overlay={() => menu(row)} trigger={['click']}>
                                    <TagCustom {...prop}>
                                        {value}
                                    </TagCustom>
                                </Dropdown>
                                :
                                <TagCustom {...prop}>
                                    {value}
                                </TagCustom>
                        }
                    </div>

                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            align: "center",
            key: 'action',
            render: (value, row: any) => {
                return (
                    <React.Fragment>
                        <EditIcon onClick={() => {
                            setSelectOrder(row)
                            onIsEditHandle()
                        }} />
                        <span style={{margin: '0px 10px'}}/>
                        <Popconfirm title="Are you sure to delete this order？"
                            onConfirm={() => onRemoveOrder(row)}
                            icon={<QuestionCircleOutlined
                                style={{ color: 'red', fontSize: 16 }} />}>
                            <DeleteIcon />
                        </Popconfirm>
                    </React.Fragment>
                )
            }
        },

    ]

    const onRemoveOrder = (value: OrderResponse) => {
        client.deleteOrderService(value.code).then(r =>{
            const curr = orders.filter(it => it.id !== value.id)
            setOrders([...curr])
            message.success("Remove Order Successfully")
        }).catch((r)=> message.error(getErrorMessage(r)))
    }

    const menu = (order: OrderResponse) => (
        <Menu>
            <MenuItemCustom key={"1"} onClick={() => onConfirm(order, OrderStatus.APPROVE)}>
                <TagCustom color={"processing"} icon={<SyncOutlined />} style={{ width: "100%" }}>
                    Approved
                </TagCustom>
            </MenuItemCustom>
            <MenuItemCustom key={"2"} onClick={() => onConfirm(order, OrderStatus.DONE)}>
                <TagCustom color={"green"} icon={<CheckCircleOutlined />} style={{ width: "100%" }}>
                    Done
                </TagCustom>
            </MenuItemCustom>
            <MenuItemCustom key={"3"} onClick={() => onConfirm(order, OrderStatus.CANCEL)}>
                <TagCustom color={"error"} icon={<CloseCircleOutlined />} style={{ width: "100%" }}>
                    Cancel
                </TagCustom>
            </MenuItemCustom>
        </Menu>
    )

    const onConfirm = (order: OrderResponse, status: OrderStatus) => {

        if (orders === undefined)
            return

        client.adminOrderUpdateService(order.code, {
            status: status.valueOf()
        }).then(r => {
            orders.forEach((it) => {
                if (it.id === order.id)
                    it.status = status
            })
            setOrders([...orders])
            message.success(`Order Code: ${order.code} has been updated successully!`)
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }

    const keyChange = (key: any): string => {
        return key.id
    }


    const EditApp = () => {
        if (selectOrder === undefined)
            return <></>
        return (
            <ModalCustom visible={isEdit}
                footer={null}
                width={1400}
                onCancel={onIsEditHandle}
                children={<OrderDetailEditForm order={selectOrder} />} />
        )
    }

    const onIsCreateHandle = () => {
        setIsCreate(!isCreate)
    }


    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        setPageSize(pagination.pageSize)
        setCurrentPage(pagination.current)
    }


    const PaginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            setIsLoading(true)
            query({
                page: (v - 1),
                size: pageSize
            }).then(r => {
                setOrders(r.data)
                setTotalCount(r.pages.totalCount)
            }).catch(r => {
                message.error(getErrorMessage(r))
            }).finally(() => setIsLoading(false))
        },
        total: totalCount,
        pageSize: pageSize,
        current: currentPage
    }


    const onSearchHandle = (value: string) => {

        if (value.trim().length < 0) {
            setSearchValue(undefined)
            return
        }

        setIsLoading(true)
        setSearchValue(value)
        client.getAllOrderService({
            size: pageSize,
            status: sort ?? "ALL",
            q: value,
        }).then(r => {
            setOrders(r.data)
            setTotalCount(r.pages.totalCount)
            setCurrentPage(1)
        }).finally(() => setIsLoading(false))
    }


    return (
        <>
            <ModalCustom visible={isView}
                onCancel={onIsViewHandle}
                width={550}
                footer={null}
                children={<ImageView src={attachment} />} />


            <ModalCustom visible={isCreate}
                onCancel={onIsCreateHandle}
                width={1400}
                footer={null}
                children={<CreateOrderForm />} />

            <EditApp />

            <Contain>
                <Row style={{ width: "100%" }}>
                    <Col span={12}>
                        <CreateButton children={"Create"} onClick={onIsCreateHandle} icon={<AddIconCustom />} />
                        <ReloadButton icon={<ReloadIcon />} children={"Reload"} onClick={onLoadData} />
                    </Col>
                    <Col span={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <Search placeholder="Please input Order code"
                            onSearch={onSearchHandle}
                            allowClear
                            style={{ width: "400px", marginRight: 20 }} enterButton />
                        <OrderSort onChange={(v) => setSort(v)} />
                    </Col>
                </Row>

            </Contain>

            <TableCustom columns={columns}
                bordered
                onChange={onChange}
                pagination={PaginationConfig}
                loading={isLoading}
                rowKey={(value) => keyChange(value)}
                expandable={{
                    expandedRowRender: (row: any, index: number) => (
                        <OrderDetailTable data={row} key={index} />
                    ),
                }}
                dataSource={orders} />

        </>
    )
}

export default OrderTable;