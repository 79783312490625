import React from "react";
import MainLayout from "../MainLayout";
import SaleReportTable from "./table/SaleReportTable";


const ReportPage = () => {
    return (
        <MainLayout>
            <SaleReportTable />
        </MainLayout>
    )
}

export default ReportPage