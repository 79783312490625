import {ArrowLeftOutlined, CloudUploadOutlined} from '@ant-design/icons'
import { AttachmentResponse } from '@cubetiq/computer-web-sdk'
import {Button, Col, Row} from 'antd'
import React, {FunctionComponent, useEffect, useState} from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import ImageCard from '../../card/ImageCard'
import ImageCardUrlRead from '../../card/ImageCardUrlRead'
import { SelectFileWithUpload } from '../../util/Logging'


const Container = styled.div`
    width: 100%;
`

const Contain = styled.div`
    min-height: 400px;
    margin-top: 20px;
    border-radius: 4px;
    background-color: #808080;
`

const ButtonUploadCustom = styled(Button)`
    text-align: center;
    background-color: #00bfff;
    font-family: 'Poppins', sans-serif;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;

`

const ButtonBackCustom = styled(ButtonUploadCustom)`
    margin-right: 10px;
`;


interface MultipleUploadCustomProps {
    onChange?: (value: any) => void,
    defaultUrls: string[]
}


const UpdateItemUploadCustom: FunctionComponent<MultipleUploadCustomProps> = (props) => {
    const { defaultUrls } = props


    const [urls,setUrls] = useState<Array<string>>(defaultUrls)

    const [selected,setSelected] = useState<any>()

    const history = useHistory();

    const onUploadFileHandle = () => {
        SelectFileWithUpload(setSelected)
    }



    useEffect(()=>{
        if(selected === undefined)
            return
        setUrls([...urls,selected.url])
    },[selected])


    useEffect(() => {
        if (urls === undefined)
            return
        typeof props.onChange === "function" && props.onChange(urls)
    }, [urls])


    return (
        <Container>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <ButtonBackCustom
                    icon={
                        <ArrowLeftOutlined style={{fontSize: 24}}/>
                    }
                    children={"Back"}
                    onClick={() => history.push("/items")}
                />
                <ButtonUploadCustom icon={<CloudUploadOutlined
                    style={{fontSize: 24}}/>}
                                    onClick={onUploadFileHandle}
                                    children={"Upload"}/>
            </div>
            <Contain>
                <Row gutter={[8, 16]}>
                    {
                        urls !== undefined &&
                            urls.map((it, index) => {
                                return (
                                    <Col span={3} style={{display: "flex", justifyContent: "center"}} key={index}>
                                        <ImageCardUrlRead index={index} url={it} urls={urls} setUrl={setUrls} />
                                    </Col>
                                )
                            })
                    }
                </Row>
            </Contain>
        </Container>
    )
}

export default UpdateItemUploadCustom