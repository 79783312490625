export enum ROUTE_URI_ENUM {
    DEFAULT = '/',
    HOME = '/home',
    RESET = '/reset',
    ITEM = '/items',
    CREATE_ITEM = '/items/create',
    UPDATE_ITEM = '/items/update/:code',
    CATEGORY = '/categories',
    LOCATION = '/location',
    ORDER = "/order",
    IMPORT = "/import",
    USER = "/user",
    STOCK = "/stock",
    SPECIFICATION = "/specification",
    REPORT = "/report",
    VISITOR = "/visitor",
    SLIDER = "/slider",
    ADJUSTMENT =  "/adjustment",
    CREATE_CATEGORY = '/categories/create-category',
    EDIT_CATEGORY = '/categories/edit-category/:code',
    DETAIL_CATEGORY = '/categories/detail-category/:code',
    SUB_CATEGORY = '/sub-categories',
    CREATE_SUB_CATEGORY = '/sub-categories/create-sub-category',
    EDIT_SUB_CATEGORY = '/sub-categories/edit-sub-category/:code',
    DETAIL_SUB_CATEGORY = '/sub-categories/detail-sub-category/:code',
    BRAND = '/brands',
    SUPPLIER = '/supplier',
    LOGIN = '/login',
    TEST = '/test',
}