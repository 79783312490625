import {SettingTwoTone} from "@ant-design/icons";
import {ClientService, Specification, SpecificationResponse} from "@cubetiq/computer-web-sdk";
import {Button, Dropdown, Menu} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";
import styled from "styled-components";
import {arrayNotInValue} from "../../util/ArrayUtils";
import {logPrint} from "../../util/Logging";


const SpecificationButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 15px;
`



interface SpecificationButtonCustomProps {
    onChange?: (value:any) => void,
    defaultValue: any[],
}


const client = ClientService.getInstance()
const SpecificationUpdateButtonCustom:FunctionComponent<SpecificationButtonCustomProps> = (props) => {

    const [specifications,setSpecifications] = useState<Array<SpecificationResponse>>([])
    const [selectedValue,setSelectedValue] = useState<SpecificationResponse>()
    const { defaultValue } = props
   


    useEffect(()=>{
        if(defaultValue === undefined)
            return
        setSpecifications(defaultValue)
    },[defaultValue])


    const onRemoveSpecification = (index:number) => {
        setSelectedValue(specifications[index])
        specifications.splice(index,1)
        setSpecifications([...specifications])
    }




    useEffect(()=>{
        if(selectedValue === undefined)
            return

        if( typeof props.onChange !== "undefined" ){
            const spec:Specification ={
                id: selectedValue.id.toString(),
                name: selectedValue.name,
                code: selectedValue.code,
                value: ""
            }
            props.onChange(spec)
        }
    },[selectedValue])
    
    

    const menu = (
        <Menu>
            {
                specifications.map((it,index)=> {
                    return(
                        <Menu.Item key={index} onClick={()=> onRemoveSpecification(index)}>
                            {
                                it.name
                            }
                        </Menu.Item>
                    )
                })
            }
        </Menu>
    )

    return(
        <Dropdown overlay={menu} placement={"bottomCenter"} arrow trigger={['click']}>
            <SpecificationButton icon={<SettingTwoTone />} children={"Specification"} />
        </Dropdown>
    )
}

export default SpecificationUpdateButtonCustom