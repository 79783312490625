import React, {FunctionComponent, HTMLAttributes, useEffect, useState} from "react"
import ItemDescription from "../components/description/ItemDescription"
import {dateFnsFormat, formatDate} from "../util/DateUtils";
import axios from 'axios'
import {getAxiosAnonymousInstance} from "@cubetiq/computer-web-sdk/dist/config/axios/AxiosConfig";
import Axios from "axios";
import styled from "styled-components";
import SerialNumberForm from "../pages/import/form/SerialNumberForm";
import ImportForm from "../components/form/ImportForm";
import {Button} from "antd";
import ModalCustom from "../components/ModalCustom";
import {
    AdminService,
    ClientService,
    ImportResponse,
    OrderResponse,
    SerialNumberObject
} from "@cubetiq/computer-web-sdk";
import SerialNumberUpdateForm from "../pages/import/form/SerialNumberUpdateForm";
import ImportDetailTable from "../components/table/ImportDetailTable";
import OrderDetailEditForm from "../components/form/OrderDetailEditForm";
import CreateOrderForm from "../components/form/CreateOrderForm";
import SelectSerialNumberItem from "../pages/order/form/SelectSerialNumberItem";
import SelectTagCustom from "../pages/order/component/SelectTagCustom";


const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;
`


const admin = AdminService.getInstance()

const TestTwoPage: FunctionComponent = () => {


    // const [image,setImage ] = useState<HTMLAttributes<HTMLImageElement> | undefined >(undefined)
    //
    // const [data,setData] = useState<Blob | undefined>(undefined)
    //
    // function loadImage(url: string) {
    //     return new  Promise(resolve => {
    //         const image = new Image();
    //         image.addEventListener('load', () => {
    //             resolve(image);
    //         });
    //         image.src = url;
    //     });
    // }
    // //
    //
    // useEffect(() => {
    //     // loadImage("https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg").then(r => {
    //     //     const result = r as HTMLAttributes<HTMLImageElement>
    //     //     console.log(typeof result)
    //     //     setImage(result)
    //     // })
    //
    //   Axios({
    //       method: "get",
    //       url: "http://192.168.0.145:8090/files/2a822fda-2199-4d3c-92c0-9f46cf4f50cf/8062e1ba-ff92-4cba-8fc8-662242c32063.jpg",
    //       responseType: "blob",
    //       headers: {
    //
    //       }
    //   }).then((r) => {
    //       // setData(r.data)
    //       // console.log(Buffer.from(r.data, 'binary').toString("base64"))
    //       console.log(r.data)
    //       setData(r.data)
    //   })
    // },[])


    // const onFinish = (values: any) => {
    //     console.log(values)
    // }
    //
    //
    const [data, setData] = useState<any[]>([])
    const [isVisible, setIsVisible] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined)


    const onFinishSerialNumber = (values: any) => {
        if(currentIndex !== undefined) {
            data[currentIndex] = values["serials"]
            const result = data.map(it => {
                if(it === undefined){
                    return []
                }
                return it
            })

            console.log(result)

            setData([...result])
            onIsVisibleHandle()
        }
    }


    const OnModal = () => {
        if (currentIndex === undefined) return <></>
        return (
            <ModalCustom visible={isVisible}
                         onCancel={onIsVisibleHandle}
                         footer={null}
                         children={<SerialNumberForm
                             onFinish={onFinishSerialNumber}
                             values={data[currentIndex] ?? ['']}/>}/>
        )
    }


    const onIsVisibleHandle = () => {
        setIsVisible(!isVisible)
    }
    //
    //
    // //
    //
    // const [state,setState] = useState<string[]>(Array(2).fill(""))
    //
    const [dataTest,setDataTest] = useState<ImportResponse>()

    useEffect(()=> {
        const code = "IMP0000000010"
        const itemCode = "ITM0000000055"

        // admin.getImportByCodeService(code).then(r => {
        //     setDataTest(r.data)
        // })

        // admin.adminGetItemByCodeService(itemCode).then(r => {
        //     console.log(r.data)
        // })


        // admin.adminGetAllItemService({
        //     available: true
        // }).then(r => console.log(r.data))



        const orderCode = "ORD0000000001"

        admin.adminGetOrderByCodeService(orderCode).then(r => {
            setOrder(r.data)

            console.log(r.data)
        })

    },[])

    const [order,setOrder] = useState<OrderResponse | undefined>(undefined)

    const onFinish = (values:any) => {
        console.log(values)
    }

    return (
        <Container>
            {/*<ImportForm/>*/}
            {/*<OnModal/>*/}

            {/*<CreateOrderForm/>*/}

            {
                order && <OrderDetailEditForm order={order}/>
            }

            {/*{ dataTest && <ImportDetailTable data={dataTest.details} /> }*/}

            {/*<SelectSerialNumberItem onFinish={onFinish}/>*/}

        </Container>
    )
}
export default TestTwoPage
