
import { BrandResponse, ClientService, ModelResponse, SpecificationResponse, SupplierRequest, SupplierResponse } from "@cubetiq/computer-web-sdk"
import { ModelRequest } from "@cubetiq/computer-web-sdk/src/interface/request/model/ModelRequest"
import { Button, Menu, message, Spin } from "antd"
import { Form, Input } from "antd"
import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"





const Container = styled.div`


`


interface UpdateSupplierFormProps {
    supplier: SupplierResponse
}

const ButtonSubmit= styled(Button)`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 5px;
    border: none;
    background-color: #19aa8d;
    color: white;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
`




const { TextArea } = Input;
const client = ClientService.getInstance()
const UpdateSUpplierForm: FunctionComponent<UpdateSupplierFormProps> = (props) => {
    const [isLoading,setIsLoading] = useState(false)
    const form = Form.useForm()[0]
    const { supplier } = props

    const onFinish = (values:any) => {
        setIsLoading(true)
        const request:SupplierRequest = {
            name: values["name"],
            contact: values["contact"],
            memo: values["memo"]
        }

        client.updateSupplierService(supplier.code!!,request).then(r => {
            message.success("Update Supplier Successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=> setIsLoading(false))

    }


    


    return (
        <Container>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>

            <Form.Item name={"name"}
                    hasFeedback
                    label={"Supplier Name"}
                    initialValue={supplier.name}
                    required rules={[
                        {
                            required: true,
                            message: "Please input name"
                        }]}>
                        <Input/>
                </Form.Item>



                <Form.Item name={"contact"}
                    hasFeedback
                    initialValue={supplier.contact}
                    label={"Contact"}
                    required rules={[
                        {
                            required: true,
                            message: "Please input contact"
                        }]}>
                        <Input/>
                </Form.Item>

                
                
                <Form.Item name={"memo"}
                    hasFeedback
                    initialValue={supplier.memo}
                    label={"Memo"}>
                        <TextArea rows={4}/>
                </Form.Item>


                <Form.Item style={{marginBottom: 0}}>
                    <Spin spinning={isLoading}>
                        <ButtonSubmit children={"Save"} htmlType={"submit"}/>
                    </Spin>
                </Form.Item>
            </Form>
        </Container>
    )

}

export default UpdateSUpplierForm