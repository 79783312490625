import React,{ FunctionComponent } from "react"
import VisitorTable from "../../components/table/VisitorTable"
import { withAuth } from "../../provider/AuthWrapper"
import MainLayout from "../MainLayout"



const VisitorPage:FunctionComponent = () => {
    return(
        <MainLayout>
           <VisitorTable/>
        </MainLayout>
    )
}

export default withAuth()(VisitorPage)