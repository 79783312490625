import {DiscountType, OrderDetailResponse, OrderResponse, SerialNumberObject} from "@cubetiq/computer-web-sdk";
import React, {FunctionComponent, useEffect, useState} from "react";
import {Col, Divider, Row, Table} from "antd";
import {ColumnType} from "antd/es/table";
import styled from "styled-components";
import {toUSD} from "../../util/utils";


interface OrderDetailTableProps {
    data: OrderResponse
}


const TableCustom = styled(Table)`
  padding: 20px 20px 20px 20px;
`

const Center = styled.div`
  text-align: center;
`

const ProductContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProductImage = styled.img`
  width: 75px;
  height: 75px;
  object-fit: contain;
  margin-right: 10px;
`


export const convertToDiscountSymbol = (disType: DiscountType) => {
    return disType === DiscountType.PERCENTAGE ? "%" : "$"
}


const OrderDetailTable: FunctionComponent<OrderDetailTableProps> = (props) => {

    const [details, setDetails] = useState<Array<OrderDetailResponse>>([])
    const [isEdit, setIsEdit] = useState(false)


    const {data} = props


    useEffect(() => {
        if (data === null)
            return
        setDetails(data.details)
    }, [data])

    // const [editItem, setEditItem] = useState<OrderDetailResponse>()
    // const onIsEditHanlde = () => {
    //     setIsEdit(!isEdit)
    // }


    const getSubTotal = (row: OrderDetailResponse): number => {
        const totalPrice = row.price * row.qty
        const discountType = row.discount.discountType
        const discountValue = row.discount.discountValue ?? 0

        if(discountType === DiscountType.PERCENTAGE){
            if (discountValue > 0) {
                const dis = totalPrice * discountValue / 100
                return totalPrice - dis
            }
            else
                return totalPrice
        }
        else {
            return totalPrice - discountValue
        }
    }




    const columns: Array<ColumnType<any>> = [
        {
            title: 'Product Avatar',
            dataIndex: 'item',
            align: "center",
            key: 'item',
            render: value => {
                return (
                    <ProductContainer>
                        <ProductImage src={value.attachments}/>
                    </ProductContainer>
                )
            }
        },
        {
            title: 'Product Name',
            dataIndex: 'item',
            align: "center",
            key: 'item',
            render: (value, row) => {
                const serials = row["serials"] as SerialNumberObject[]
                return (
                    <React.Fragment>
                        <Center>
                            {value.name}
                        </Center>
                        {
                            serials.length > 0 && (
                                <>
                                    <Divider style={{margin: "10px 0px"}}/>
                                    <Row>
                                        <Col style={{ fontWeight: 600 , color: "red" }} span={4}>
                                            Serial Number:
                                        </Col>
                                        <Col span={20}>
                                            {
                                                serials.map((it, index) => {
                                                    return (
                                                        <div style={{ textAlign: "left", marginLeft: 10 }} key={index}>
                                                            * {
                                                                it.serial
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Price',
            dataIndex: 'price',
            align: "center",
            key: 'price',
            render: (value) => {
                return (
                    <Center>
                        {toUSD.format(Number(value))}
                    </Center>
                )
            }
        },
        {
            title: 'Quantity',
            align: "center",
            dataIndex: 'qty',
            key: 'qty',
            render: (value) => {
                return (
                    <Center>
                        {value}
                    </Center>
                )
            }

        },
        {
            title: 'Discount',
            align: "center",
            dataIndex: 'discount',
            key: 'discount',
            render: (value) => {
                return (
                    <Center>
                        {value.discountValue}
                        {convertToDiscountSymbol(value.discountType)}
                    </Center>
                )
            }
        },
        {
            title: 'Subtotal',
            align: "center",
            dataIndex: ['qty', 'price'],
            key: 'subTotal',
            render: (value, row) => {
                return (
                    <Center>
                        {toUSD.format(getSubTotal(row))}
                    </Center>
                )
            }
        }
    ];


    return (
        <>
            {/* <ModalCustom visible={isEdit}
                footer={null}
                width={1000}
                onCancel={onIsEditHanlde}
                children={<OrderDetailEditForm/>} /> */}
            <TableCustom
                bordered
                pagination={false}
                rowKey={(value: any) => value.id}
                dataSource={details}
                columns={columns}/>
        </>
    )
}

export default OrderDetailTable