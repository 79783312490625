import { AuthUserResponse, ClientService, NotificationEnumStatus, NotificationResponse } from "@cubetiq/computer-web-sdk"
import React, { FunctionComponent, useEffect } from "react"
import * as mqtt from 'mqtt'
import { notification } from "antd"
import { CheckCircleOutlined, ExclamationCircleOutlined, InfoOutlined, SmileOutlined, SyncOutlined, WarningOutlined } from "@ant-design/icons"


interface ContextState {

}

const Context = React.createContext<ContextState>({

})

const clientService = ClientService.getInstance()
const NotificationProvider: FunctionComponent<{}> = (props: any) => {

  

    const IconRender = (value: NotificationEnumStatus) => {
        switch (value) {
            case NotificationEnumStatus.Alert:
                return <InfoOutlined style={{ color: '#108ee9' }} />
            case NotificationEnumStatus.Error:
                return <ExclamationCircleOutlined style={{ color: 'red' }} />
            case NotificationEnumStatus.Processing:
                return <SyncOutlined spin style={{ color: '#108ee9' }} />
            case NotificationEnumStatus.Success:
                return <CheckCircleOutlined style={{ color: "green" }} />
            case NotificationEnumStatus.Warning:
                return <WarningOutlined style={{ color: 'yellow' }} />
            default:
                return <WarningOutlined style={{ color: 'yellow' }} />
        }
    }


    useEffect(() => {
        clientService.fetchUserService().then(r => {
            var client = mqtt.connect("wss://ws.cubetiqs.com")
            client.subscribe("admin-channel")
            client.on('message', function (topic, message) {
                const msg:NotificationResponse = JSON.parse(message.toString())
                notification.open({
                    message: msg.title,
                    description:`${msg.memo}`,
                    icon: IconRender(msg.status),
                });
            })
        })
    }, [])



    const contextValue: ContextState = {
      
    }


    return (
        <Context.Provider value={contextValue}>
            {props.children}
        </Context.Provider>
    )
}

export function useNotificationProvider() {
    return React.useContext(Context)
}

export default NotificationProvider


