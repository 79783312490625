// @ts-nocheck

import {DeleteFilled} from "@ant-design/icons"
import {
    AdminService,
    AuthUserResponse,
    ClientService,
    DiscountType,
    ItemResponse,
    LocationResponse,
    OrderDetailRequest,
    OrderRequest,
    PaymentMethodEnum
} from "@cubetiq/computer-web-sdk"
import {Button, Col, Divider, Form, Input, InputNumber, message, Row, Spin} from "antd"
import React, {useEffect, useState} from "react"
import styled from "styled-components"
import TotalFooter, {ItemTotalAmount} from "../footer/TotalFooter"
import SelectItemCustom from "../select/SelectItemCustom"
import SelectCustom from "../SelectCustom"
import SelectTagCustom from "../../pages/order/component/SelectTagCustom";
import {getErrorMessage} from "../../util/utils";
import {DiscountTypeData} from "../../pages/items/CreateItemPage";
import {logPrint} from "../../util/Logging";
import SelectDiscountType from "../../pages/order/component/SelectDiscountType";


const Container = styled.div`
  margin-top: 20px;
  min-width: 1200px;

  .ant-input[disabled] {
    background-color: white;
    color: black;
  }

  .ant-input-number-disabled .ant-input-number-input {
    background-color: white;
    color: black;
  }

  .ant-input-number-input {
    text-align: center;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

`


const AvatarCustom = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-top: 15px;
`

const DeleteIcon = styled(DeleteFilled)`
  font-size: 24px;
  color: red;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    opacity: 0.5;
  }
`


const ButtonSave = styled(Button)`
  width: 100%;
  color: white;
  background-color: #19aa8d;
  border: none;
  height: 51px;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  margin-top: 10px;

  &:hover {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }

  &:focus {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }

  transition: 0.2s;

  &:active {
    transform: scale(0.9);
  }

`


const Selector = styled.div`
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
  }
`


const {TextArea} = Input

const client = ClientService.getInstance()
const admin = AdminService.getInstance()
const CreateOrderForm = () => {

    const [items, setItems] = useState<Array<ItemResponse>>([])
    const [orders, setOrders] = useState<Array<ItemResponse>>([])
    const [isLoading, setIsLoading] = useState(false)
    const [locations, setLocations] = useState<Array<LocationResponse>>([])
    const [total, setTotal] = useState<Array<ItemTotalAmount>>([])
    const [currentLocation, setCurrentLocation] = useState<LocationResponse>()
    const [users, setUsers] = useState<AuthUserResponse[]>()
    const [discount, setDiscount] = useState(0)
    const [memo, setMemo] = useState("")

    useEffect(() => {

        admin.adminGetAllItemService({
            available: true,
            paged: false
        }).then(r => setItems(r.data))

        client.getAllLocationService({
            paged: false
        }).then(r => {
            setLocations(r.data)
        })
        client.getAllUserService({
            paged: false
        }).then(r => {
            setUsers(r.data)
        })
    }, [])


    const onFinish = (values: any) => {


        const user = JSON.parse(values["user"])

        const itemOrder: Array<ItemResponse & { qty: number, serialCode: string[] }> = values["items"]


        setIsLoading(true)
        const detail = itemOrder.map(it => {
            const req: OrderDetailRequest = {
                itemId: it.id!!,
                price: it.price!!,
                qty: it.qty,
                discount: it.discount?.discountValue,
                discountType: it.discount?.discountType,
                serialCode: it.serialCode ?? []
            }
            return req
        })

        const request: OrderRequest = {
            detail: detail,
            paymentMethod: PaymentMethodEnum.Cash,
            delivery: {
                address: currentLocation?.location,
                label: currentLocation?.location,
                latitudeAndLongitude: "N/A",
                price: currentLocation?.price ?? 0
            },
            discount: discount,
            memo: memo,
            userId: user["id"],
            isAdmin: true
        }


        console.log(request)

        client.adminCreateOrderService(request, undefined).then(r => {
            message.success("Create Order Successfully").then()
        }).catch(r => {
            message.error(getErrorMessage(r)).then()
        }).finally(() => setIsLoading(false))

    }

    const onFinishFailed = (errorInfo: any) => {

    }


    const onHandleChange = (v: any) => {
        const item: ItemResponse = JSON.parse(v)
        setOrders([...orders, item])
        return item
    }


    const form = Form.useForm()[0]


    const onItemChange = () => {
        const values = form.getFieldsValue()

        const dis = values["discount"]
        setDiscount(dis)


        if (values["location"].length !== 0) {
            const location: LocationResponse = JSON.parse(values["location"])
            setCurrentLocation(location)
        }
        const currentItem: Array<ItemResponse & { qty: number | 1 }> = values["items"]


        const newValue = currentItem.map((it) => {
            const amount = getTotalAmount(it)


            return {
                ...it,
                amount: amount
            }
        })

        form.setFieldsValue({
            items: newValue
        })


        const total = newValue.map(it => {
            return {
                name: it.name || 0,
                price: it.price || 0,
                qty: it.qty,
                discount: it.discount?.discountValue || 0,
                discountType: it.discount?.discountType ?? DiscountType.PERCENTAGE,
                amount: it.amount
            }
        })

        setTotal([...total])
    }


    const onRemoveItemChange = () => {
        const values = form.getFieldsValue()

        const currentItem: Array<ItemResponse & { qty: number | 1 }> = values["items"]

        const newValue = currentItem.map((it) => {
            const amount = getTotalAmount(it)
            return {
                name: it.name || 0,
                price: it.price || 0,
                qty: it.qty,
                discount: it.discount?.discountValue || 0,
                discountType: it.discount?.discountType ?? DiscountType.PERCENTAGE,
                amount: amount
            }
        })

        const totalItem = newValue.filter(it => it.qty !== 0 && it.price !== 0)

        setTotal([...totalItem])
    }

    //
    // const getAmount = (item: ItemResponse): number => {
    //     if (item === undefined)
    //         return 0
    //     const discountPrice = (item.price!! * item.discount!!) / 100
    //
    //     return (item.price!! - discountPrice)
    // }


    const getTotalAmount = (it: any): number => {

        const discountType = it.discount.discountType
        const discountValue = it.discount.discountValue

        let qty = it.qty ?? 1
        const totalPrice = qty * it.price

        if (discountType === DiscountType.PERCENTAGE) {
            if (discountValue > 0) {
                const discountPrice = (totalPrice * discountValue) / 100
                return totalPrice - discountPrice
            } else
                return totalPrice
        } else {
            return totalPrice - discountValue
        }
    }


    const [serialLimit, setSerialLimit] = useState<Array<number>>(Array(items.length).fill(1))


    const onQuantityChange = (v: number, index: number) => {
        serialLimit[index] = v
        setSerialLimit([...serialLimit])
        onItemChange()
        return v
    }


    const LabelRender = (prefix: string, index: number) => {
        return (
            <div>
                {
                    prefix
                }
                <span style={{color: "green", marginLeft: 10}}>
                     In Stock Have
                     (
                    {
                        orders[index].stockQty
                    }
                    )
                </span>
            </div>
        )
    }


    const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.PERCENTAGE)

    useEffect(() => {
        const value = form.getFieldValue("discount")
        if (discountType === DiscountType.PERCENTAGE) {
            const result = value > 100 ? 100 : value
            form.setFieldsValue({
                discount: result
            })
        }
    }, [discountType])


    const [itemsDiscountType, setItemsDiscountType] = useState<DiscountType[]>([])


    return (
        <Container>
            <Form layout={"vertical"}
                  onFinishFailed={onFinishFailed}
                  form={form}
                  onFinish={onFinish}>

                <Row gutter={[10, 10]}>

                    <Col span={8}>
                        <Form.Item name={"location"}
                                   initialValue={locations}
                                   label={"Location"}>
                            <SelectCustom data={locations}
                                          onChange={(v) => {

                                              itemsDiscountType[orders.length] = DiscountType.PERCENTAGE
                                              setItemsDiscountType([...itemsDiscountType])
                                              onItemChange()
                                              return v
                                          }}
                                          keyValue={"location"}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item name={"discount"}
                                   initialValue={0}
                                   label={"Discount"}>
                            <InputNumber style={{width: "100%"}}
                                         min={0}
                                         max={
                                             discountType === DiscountType.PERCENTAGE ? 100 : 100000000
                                         }
                                         onChange={(v) => {
                                             onItemChange()
                                             return v
                                         }}/>
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item name={"discountType"}
                                   initialValue={DiscountType.PERCENTAGE}
                                   label={"Discount Type"}>
                            <SelectCustom defaultValue={DiscountType.PERCENTAGE}
                                          onChange={(v: any) => {
                                              setDiscountType(JSON.parse(v)["name"])
                                              return v
                                          }}
                                          data={DiscountTypeData}/>
                        </Form.Item>
                    </Col>


                    {
                        users && (
                            <Col span={8}>
                                <Form.Item name={"user"}
                                           label={"User"}
                                           hasFeedback
                                           required rules={[
                                    {
                                        required: true, message: "Please selected user"
                                    }
                                ]}>
                                    <SelectCustom data={users ?? []}
                                                  keyValue={"username"}/>
                                </Form.Item>
                            </Col>
                        )
                    }

                    <Col span={24}>
                        <Form.Item name={"memo"}>
                            <TextArea placeholder={"Memo"} rows={2}/>
                        </Form.Item>
                    </Col>

                </Row>

                <Form.List name="items" initialValue={items}>
                    {(fields, {add, remove}) => {
                        return (
                            <>

                                <Row>
                                    <Col span={24}>

                                        <Form.Item label={"Add Items"}>
                                            <Selector>
                                                <SelectItemCustom data={items} onChange={(v) => {
                                                    if (v === null)
                                                        return null
                                                    const it = onHandleChange(v)
                                                    add(it)
                                                    onItemChange()
                                                }}/>
                                            </Selector>
                                        </Form.Item>

                                        <Divider/>
                                    </Col>
                                </Row>


                                {
                                    fields.map((field, index) => {
                                        return (
                                            <div key={field.key}>
                                                <Row gutter={[10, 10]}>

                                                    <Col span={2}>
                                                        <Form.Item name={[field.name, 'attachments']}
                                                                   fieldKey={[field.fieldKey, 'attachments']}>
                                                            <AvatarCustom
                                                                src={orders[index].attachments?.[0]?.originalLink}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={22}>
                                                        <Row gutter={[10, 0]}>
                                                            <Col span={9}>
                                                                <Form.Item name={[field.name, 'name']}
                                                                           fieldKey={[field.fieldKey, 'name']}
                                                                           label={LabelRender("Product Name", index)}
                                                                           required>
                                                                    <Input disabled/>
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span={3}>
                                                                <Form.Item name={[field.name, 'price']}
                                                                           label={"Price"}
                                                                           required
                                                                           fieldKey={[field.fieldKey, 'price']}>
                                                                    <InputNumber
                                                                        min={0}
                                                                        onChange={(v) => {
                                                                            onItemChange()
                                                                            return v
                                                                        }}
                                                                        style={{width: "100%"}}/>
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span={2}>
                                                                <Form.Item name={[field.name, 'qty']}
                                                                           label={"Quantity"}
                                                                           required
                                                                           initialValue={1}
                                                                           fieldKey={[field.fieldKey, 'qty']}>
                                                                    <InputNumber style={{width: "100%"}}
                                                                                 max={orders[index].stockQty}
                                                                                 onChange={
                                                                                     (v) =>
                                                                                         onQuantityChange(Number(v), index)
                                                                                 }
                                                                                 min={0}/>
                                                                </Form.Item>
                                                            </Col>


                                                            <Col span={3}>
                                                                <Form.Item
                                                                    name={[field.name, 'discount', 'discountValue']}
                                                                    label={"Discount"}
                                                                    fieldKey={[field.fieldKey, 'discount', 'discountValue']}>
                                                                    <InputNumber style={{width: "100%"}}
                                                                                 onChange={(v) => {
                                                                                     onItemChange()
                                                                                     return v
                                                                                 }}
                                                                                 min={0}/>
                                                                </Form.Item>
                                                            </Col>


                                                            <Col span={3}>
                                                                <Form.Item
                                                                    name={[field.name, 'discount', 'discountType']}
                                                                    label={"Discount Type"}
                                                                    fieldKey={[field.fieldKey, 'discount', 'discountType']}>
                                                                    <SelectDiscountType onChange={(v) => {
                                                                        itemsDiscountType[index] = v
                                                                        setItemsDiscountType([...itemsDiscountType])
                                                                        onItemChange()
                                                                        return v
                                                                    }}/>
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span={3}>
                                                                <Form.Item name={[field.name, 'amount']}
                                                                           label={"Amount"}
                                                                           fieldKey={[field.fieldKey, 'amount']}>

                                                                    <InputNumber style={{width: "100%"}}
                                                                                 disabled
                                                                                 min={0}/>

                                                                </Form.Item>
                                                            </Col>

                                                            <Col style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }} span={1}>
                                                                <Form.Item>
                                                                    <DeleteIcon onClick={() => {
                                                                        remove(field.name)

                                                                        const dataDelete = [...orders]
                                                                        dataDelete.splice(index, 1)
                                                                        setOrders([...dataDelete])
                                                                        onRemoveItemChange()

                                                                        serialLimit.splice(index, 1)
                                                                        setSerialLimit([...serialLimit])

                                                                        itemsDiscountType.splice(index, 1)
                                                                        setItemsDiscountType([...itemsDiscountType])

                                                                    }}/>
                                                                </Form.Item>
                                                            </Col>

                                                            {
                                                                orders[index].serials.inStock.length > 0 &&
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        fieldKey={[field.fieldKey, 'serialCode']}
                                                                        name={[field.name, 'serialCode']}>
                                                                        <SelectTagCustom
                                                                            data={orders[index].serials.inStock}
                                                                            field={"serial"}
                                                                            placeholder={"Serial Number"}
                                                                            limit={serialLimit[index] ?? 1}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        )
                    }}
                </Form.List>


                {
                    orders.length > 0 &&
                    <Form.Item>
                        <Row>
                            <Col span={24}>
                                <Divider/>
                                <TotalFooter data={total}
                                             discount={discount}
                                             discountType={discountType}
                                             locationPrice={currentLocation?.price || 0}/>
                            </Col>
                        </Row>
                    </Form.Item>
                }


                {
                    orders.length > 0 &&
                    <Form.Item>
                        <Row>
                            <Col span={24}>
                                <Spin spinning={isLoading}>
                                    <ButtonSave children={"Create Order"} htmlType={"submit"}/>
                                </Spin>
                            </Col>
                        </Row>
                    </Form.Item>
                }
            </Form>


        </Container>
    )
}

export default CreateOrderForm