import React from 'react';
import './App.css';
import RouterView from './routes';

function App() {
  return (
    <>
      <RouterView />
    </>
  );
}

export default App;
