
import { BrandResponse, CategoryRequest, ClientService, SliderRequest } from "@cubetiq/computer-web-sdk"
import { BrandRequest } from "@cubetiq/computer-web-sdk/src/interface/request/brand/BrandRequest"
import { ModelRequest } from "@cubetiq/computer-web-sdk/src/interface/request/model/ModelRequest"
import { Button, message, Spin } from "antd"
import { Form, Input } from "antd"
import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"
import FileSelectWithPreview from "../upload/FileSelectWithPreview"





const Container = styled.div`


`


interface CreateSliderFormProps {
    
}

const ButtonSubmit = styled(Button)`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 5px;
    border: none;
    background-color: #19aa8d;
    color: white;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
`

const Style = styled.div`
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`



const client = ClientService.getInstance()
const CreateSilderForm: FunctionComponent<CreateSliderFormProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [file, setFile] = useState<File>()
    const form = Form.useForm()[0]


    const onFinish = (values: any) => {
        setIsLoading(true)
        if (file === undefined) {
            message.error("File image can not empty !")
            return
        }


        client.otherUploadFileService(file).then(r => {
            const request: SliderRequest = {
                url: r.data.url,
                action: values["action"]
            }
            client.createSliderService(request).then(r => {
                message.success("Slider Category Successfully")
            }).catch(r => {
                message.error(getErrorMessage(r))
            })
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(() => setIsLoading(false))

        form.resetFields()
    }





    return (
        <Container>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>

                <Style>
                    <Form.Item name={"file"}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <FileSelectWithPreview onChange={setFile}
                            overrideStyle={{ width: "100%",marginTop: 30 }}
                            style={{ width: "100%" }} />
                    </Form.Item>
                </Style>


                <Form.Item name={"action"}
                    hasFeedback
                    label={"Slide Action Link"}>
                    <Input />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Spin spinning={isLoading}>
                        <ButtonSubmit children={"Create"} htmlType={"submit"} />
                    </Spin>
                </Form.Item>
            </Form>
        </Container>
    )

}

export default CreateSilderForm