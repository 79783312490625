import {DeleteFilled, EditFilled, PlusCircleFilled, QuestionCircleOutlined} from "@ant-design/icons"
import {BrandResponse, ClientService} from "@cubetiq/computer-web-sdk"
import {message} from "antd"
import {Popconfirm} from "antd"
import {Button} from "antd"
import {Spin, Table, Tooltip} from "antd"
import {ColumnType} from "antd/es/table"
import React, {FunctionComponent, useEffect, useState} from "react"
import styled from "styled-components"
import {AddIconCustom} from "../../pages/import"
import {getErrorMessage} from "../../util/utils"
import CreateBrandForm from "../form/CreateBrandForm"
import CreateModelForm from "../form/CreateModelForm"
import UpdateBrandForm from "../form/UpdateBrandForm"
import ModalCustom from "../ModalCustom"
import ModelTable from "./ModelTable"


const TableCustom = styled(Table)`
  padding: 20px 0px 0px 0px;

  .ant-table-expanded-row {
    .ant-table-cell {
      background: none;
    }

    .ant-table-content {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
`


const Container = styled.div`

`


const ImageCustom = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`


const PlusIcon = styled(PlusCircleFilled)`
  font-size: 24px;
  color: #19aa8d;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`


const CreateButton = styled(Button)`
  background-color: #00bfff;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-family: 'Poppins', sans-serif;
  width: 120px;

  &:hover {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }

  &:active {
    background-color: black;
    color: white;
    opacity: 0.8;
  }
`


const EditIcon = styled(EditFilled)`
  font-size: 24px;
  color: #00bfff;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  margin-left: 30px;
`


const DeleteIcon = styled(DeleteFilled)`
  font-size: 24px;
  color: red;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  margin-left: 30px;
`

export const ReloadButton = styled(Button)`
  background-color: #19aa8d;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-family: 'Poppins', sans-serif;
  width: 120px;

  &:hover {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }

  &:active {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }

  transition: 0.2s;

  &:active {
    transform: scale(0.9);
  }

  margin-left: 10px;


`


const client = ClientService.getInstance()
const BrandTable: FunctionComponent = () => {

    const [brands, setBrands] = useState<Array<BrandResponse>>()
    const [selectedBrand, setSelectedBrands] = useState<BrandResponse>()
    const [isCreateModel, setIsCreateModel] = useState(false)
    const [isCreateBrand, setIsCreateBrand] = useState(false)
    const [isUpdateBrand, setIsUpdateBrand] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    const onIsUpdateBrandHandle = () => {
        setIsUpdateBrand(!isUpdateBrand)
    }

    const onIsCreateBrandHandle = () => {
        setIsCreateBrand(!isCreateBrand)
    }


    const onIsCreateModelHandle = () => {
        setIsCreateModel(!isCreateModel)
    }

    useEffect(() => {
        onLoadData()
    }, [])


    const columns: Array<ColumnType<any>> = [
        {
            title: 'Brand Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Brand Name',
            dataIndex: 'name',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Icon',
            dataIndex: 'config',
            align: "center",
            key: 'code',
            width: 200,
            render: v => {
                return (
                    <ImageCustom src={v.icon}/>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: "center",
            key: 'code',
            width: 200,
            render: (v, row) => {
                return (
                    <>
                        <Tooltip placement={"top"} title={"Create model"}>
                            <PlusIcon onClick={() => {
                                setSelectedBrands(row)
                                onIsCreateModelHandle()
                            }}/>
                        </Tooltip>

                        <Tooltip placement={"top"} title={"Update brand"}>
                            <EditIcon onClick={() => {
                                setSelectedBrands(row)
                                onIsUpdateBrandHandle()
                            }}/>
                        </Tooltip>


                        <Popconfirm title={`Are you sure to delete ( ${row.name} ) ? `}
                                    onConfirm={() => onRemoveBrand(row)}
                                    icon={<QuestionCircleOutlined
                                        style={{color: 'red', fontSize: 16}}/>}>
                            <DeleteIcon/>
                        </Popconfirm>

                    </>
                )
            }
        }
    ]


    const onRemoveBrand = (value: BrandResponse) => {
        setIsLoading(true)
        client.deleteBrandService(value.code).then((r) => {
            const remove = r.data
            const newBrand = brands?.filter(it => it.id !== remove.id)
            setBrands([...newBrand || []])
            message.success("Delete brand successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(() => setIsLoading(false))
    }


    const keyChange = (key: any): string => {
        return key.id
    }


    const CreateModel = () => {
        if (selectedBrand === undefined)
            return <></>
        return (
            <ModalCustom visible={isCreateModel}
                         footer={null}
                         width={500}
                         onCancel={onIsCreateModelHandle}
                         children={<CreateModelForm brand={selectedBrand}/>}/>
        )
    }

    const UpdateBrand = () => {
        if (selectedBrand === undefined)
            return <></>
        return (
            <ModalCustom visible={isUpdateBrand}
                         footer={null}
                         width={500}
                         onCancel={onIsUpdateBrandHandle}
                         children={<UpdateBrandForm brand={selectedBrand}/>}/>
        )
    }


    const onLoadData = () => {
        setIsLoading(true)
        client.getAllBrandService({
            paged: false
        }).then(r => {
            setBrands(r.data)
        }).finally(() => setIsLoading(false))
    }


    return (
        <Container>
            <CreateModel/>

            <ModalCustom visible={isCreateBrand}
                         footer={null}
                         width={500}
                         onCancel={onIsCreateBrandHandle}
                         children={<CreateBrandForm/>}/>


            <UpdateBrand/>

            <Tooltip title={"Create brand"} placement={"top"}>
                <CreateButton children={"Create"} onClick={onIsCreateBrandHandle} icon={<AddIconCustom/>}/>
            </Tooltip>

            <Tooltip title={"Reload data"} placement={"top"}>
                <ReloadButton children={"Reload"} onClick={onLoadData}/>
            </Tooltip>

            {
                <TableCustom columns={columns}
                             bordered
                             loading={isLoading}
                             rowKey={(value) => keyChange(value)}
                             expandable={{
                                 expandedRowRender: (row: any, index: number) => {
                                     console.log(row)
                                     return (
                                         <ModelTable models={row.model} key={index}/>
                                     )
                                 },
                             }}
                             dataSource={brands}/>
            }
        </Container>
    )
}

export default BrandTable