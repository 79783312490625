import React,{FunctionComponent} from 'react'
import Lottie from 'react-lottie';

import animationData from '../loading/8370-loading.json';

const LoadingCustom:FunctionComponent = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        speed: 1000,
        delay: 1000,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };


    return(
        <div
            style={{
                width: '100%',
                display: 'flex',
                height: '98vh',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Lottie options={defaultOptions} width={400} height={400} />
        </div>
    )
}

export default LoadingCustom