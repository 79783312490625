import styled from "styled-components";
import {Table} from "antd";
import {ColumnType, TableProps} from "antd/es/table";
import React, {useEffect, useState} from "react";
import {SaleReportResponse} from "@cubetiq/computer-web-sdk/dist/interface/response/SaleReportResponse";
import {AdminService} from "@cubetiq/computer-web-sdk";
import {SaleReportDetailResponse} from "@cubetiq/computer-web-sdk/dist/interface/response/SaleReportDetailResponse";
import {toUSD} from "../../../util/utils";


interface SaleReportDetailTableProps extends TableProps<any>{
    data: SaleReportResponse
}

const TableCustom = styled(Table)`
   padding: 20px 0 0 0;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`

const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`

const Container = styled.div``
const admin = AdminService.getInstance()
const SaleReportDetailTable:React.FC<SaleReportDetailTableProps> = (props) => {
   const { data ,...rest } = props
    const [reports,setReports] = useState<SaleReportDetailResponse[]>([])
    const [isLoading,setIsLoading] = useState(false)

    const query = admin.getSaleReportDetailService


    const onLoadData = () => {
       setIsLoading(true)
       query(data.code).then(r => {
           setReports(r.data)
       }).finally(()=> setIsLoading(false))
    }

    const columns: Array<ColumnType<any>> = [
        {
            title: 'Image',
            dataIndex: 'attachment',
            align: "center",
            key: 'attachment',
            width: 150,
            render: value => <ImageCustom src={value.originalLink}/>
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            align: "center",
            key: 'productName',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            align: "center",
            key: 'price',
            width: 150,
            render: value => toUSD.format(value)
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            align: "center",
            key: 'cost',
            width: 150,
            render: value => toUSD.format(value)
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            align: "center",
            key: 'qty',
            width: 150,
        },
        {
            title: 'Income',
            dataIndex: 'income',
            align: "center",
            key: 'income',
            width: 150,
            render: value => toUSD.format(value)
        },
        {
            title: 'Total Cost',
            dataIndex: 'totalCost',
            align: "center",
            key: 'totalCost',
            width: 150,
            render: value => toUSD.format(value)
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            align: "center",
            key: 'discount',
            width: 150,
            render: value => toUSD.format(value)
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            align: "center",
            key: 'revenue',
            width: 150,
            render: value => toUSD.format(value)
        },
    ]


    useEffect(()=>{
        onLoadData()
    },[])

    return (
        <Container>
            <TableCustom {...rest}
                         loading={isLoading}
                         columns={columns}
                         pagination={false}
                         bordered
                         dataSource={reports}/>
        </Container>
    )
}

export default SaleReportDetailTable;