import React,{ FunctionComponent } from "react";
import styled from "styled-components";
import UserTable from "../../components/table/UserTable";
import { withAuth } from "../../provider/AuthWrapper";
import MainLayout from "../MainLayout";


const Container = styled.div`

`


const UserPage:FunctionComponent = () => {
    return (
        <MainLayout>
            <Container>
                <UserTable/>
            </Container>
        </MainLayout>
    )
}

export default withAuth()(UserPage)