import {
    Select,
    Form,
    Input,
    InputNumber,
    Modal,
    Button,
    Col,
    Dropdown,
    Layout,
    Menu,
    Row,
    Space,
    Table,
    Popconfirm,
    message,
    Upload,
    Card,
    Checkbox,
    Pagination,
    Badge,
    Spin,
} from "antd";

const Elements = {
    SELECT: Select,
    FORM: Form,
    INPUT: Input,
    INPUT_NUMBER: InputNumber,
    MODAL: Modal,
    BUTTON: Button,
    COL: Col,
    DROPDOWN: Dropdown,
    LAYOUT: Layout,
    MENU: Menu,
    ROW: Row,
    SPACE: Space,
    TABLE: Table,
    POP_CONFIRM: Popconfirm,
    MESSAGE: message,
    UPLOAD: Upload,
    CARD: Card,
    CHECKBOX: Checkbox,
    PAGINATION: Pagination,
    BADGE: Badge,
    SPIN: Spin,
}

export default Elements