import { CloudUploadOutlined, CodeSandboxCircleFilled, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import { SelectFileUtils } from "../../util/Logging";




interface FileSelectWithPreviewProps {
    onChange?: (value: File) => void
    style?: CSSProperties
    defaultValue?: any,
    overrideStyle?: CSSProperties,
    isPreview?: boolean
}


const Container = styled.div`
  padding: 5px;
  border: 1px dashed #ccc ;
`

const Contain = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size:  contain;
    background-position: center;
    &:hover {
        opacity: 0.8;
    }
`



const FileSelectWithPreview: FunctionComponent<FileSelectWithPreviewProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState<File>()
    const [valueProps,setValueProps] = useState<{}>()

    const {  defaultValue, isPreview = true  } = props


    const onSelectFile = () => {
        setIsLoading(false)
        SelectFileUtils(setSelectedFile)
    }



    useEffect(()=>{
        if(defaultValue === undefined)
            return
        const newProps = {
            style: {
                ...props?.style,
                backgroundImage:  `url(${defaultValue})`
            }
        }
        setValueProps(newProps)
    },[defaultValue])




    useEffect(() => {
        typeof selectedFile !== "undefined" &&
            typeof props.onChange !== "undefined" &&
            props.onChange(selectedFile)
        
        if(typeof selectedFile === "undefined")
            return
    

        if(isPreview){
            const newProps = {
                style: {
                    ...props?.style,
                   backgroundImage:  `url(${ typeof selectedFile !== "undefined" && URL.createObjectURL(selectedFile) })`
                }
            }
            setValueProps(newProps)
        }
    }, [selectedFile])




    return (
        <Container style={props.overrideStyle}>
            <Contain onClick={onSelectFile} style={props?.style} {...valueProps}>
                {
                    isPreview && (selectedFile || defaultValue) ? <></> : isLoading ? <LoadingOutlined /> : <CloudUploadOutlined  style={{ fontSize: 40, color: "#00bfff" }} />
                }
            </Contain>
        </Container>
    )
}


export default FileSelectWithPreview