import React, {FunctionComponent, useEffect, useState} from "react";
import MainLayout from "../MainLayout";
import {BrandResponse, ClientService, ModelResponse} from "@cubetiq/computer-web-sdk";
import ELements from "../../elements";
import {Link, useHistory, useLocation} from "react-router-dom";
import {ROUTE_URI_ENUM} from "../../routes/paths";
import {logPrint} from "../../util/Logging";
import Icons from "../../icons";
import {ColumnsType} from "antd/es/table";
import Elements from "../../elements";
import ModalBrand from "./components/ModalBrand";
import FormBrand from "./components/FormBrand";
import CardBrand from "./components/CardBrand";
import FormModel from "./models/components/FormModel";
import {withAuth} from "../../provider/AuthWrapper";
import styled from "styled-components";
import BrandTable from "../../components/table/BrandTable";
const { Search } = ELements.INPUT;

const client = ClientService.getInstance()










const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`






const BrandPage: FunctionComponent = () => {

    const [brand, setBrand] = useState<Array<BrandResponse>>([])
    const [model, setModel] = useState<Array<ModelResponse>>([])
    const [isLoad, setIsLoad] = useState(false)
    const [isModalCreateBrand, setIsModalCreateBrand] = useState(false)
    const [isModalUpdateBrand, setIsModalUpdateBrand] = useState(false)
    const [isModalDetailBrand, setIsModalDetailBrand] = useState(false)
    const [isModalCreateModel, setIsModalCreateModel] = useState(false)
    const [isModalUpdateModel, setIsModalUpdateModel] = useState(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const history = useHistory()
    const location = useLocation()
    const codeBrand = history.location.pathname.substr(21)
    const codeModel = history.location.pathname.substr(21)

    const confirmDeleteBrand = (e:any) => {
        ELements.MESSAGE.success('Delete Successfully')
        e.stopPropagation()
        history.push(ROUTE_URI_ENUM.BRAND)

        client.deleteBrandService(codeBrand)
            .then(r => {
                logPrint("Deleted Data", r.data)
            })
            .catch(err => {
                Elements.MESSAGE.error("Delete Failed");
            })

        client.deleteModelService(codeModel)
    }

    const confirmDeleteModel = (e:any) => {
        ELements.MESSAGE.success('Delete Successfully')
        e.stopPropagation()
        history.push(ROUTE_URI_ENUM.BRAND)

        client.deleteModelService(codeModel)
            .then(r => {
                logPrint("Deleted Data", r.data)
            })
            .catch(err => {
                Elements.MESSAGE.error("Delete Failed");
            })
    }

    const cancel = (e:any) => {
        e.stopPropagation()
        history.push(ROUTE_URI_ENUM.BRAND)
    }

    const handleLoadPage = () => {
        window.location.reload()
        setIsLoad(!isLoad)
    }

    const onSearch = (value:any) => console.log(value);

    const onCancel = (e:any) => {
        history.push(ROUTE_URI_ENUM.BRAND)
        setIsModalCreateBrand(false)
        setIsModalUpdateBrand(false)
        setIsModalDetailBrand(false)
        setIsModalCreateModel(false)
        setIsModalUpdateModel(false)
    }

    const handleModalCreateBrand = () => {
        setIsModalCreateBrand(!isModalCreateBrand)
    }

    const handleModalUpdateBrand = () => {
        setIsModalUpdateBrand(!isModalUpdateBrand)
    }

    const handleModalDetailBrand = () => {
        setIsModalDetailBrand(!isModalDetailBrand)
    }

    const handleModalCreateModel = () => {
        setIsModalCreateModel(!isModalCreateModel)
    }

    const handleModalUpdateModel = () => {
        setIsModalUpdateModel(!isModalUpdateModel)
    }

    const expandedRowRender = () => {
        const columns: ColumnsType<ModelResponse> = [
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                render: (value?: string, row?: ModelResponse) => {
                    return (<>
                        <ELements.BUTTON onClick={handleModalUpdateModel} size={"small"} type="primary">
                            <Link to={{
                                pathname: `/models/edit-model/${row?.code}`,
                                state: {background: location}
                            }}>Edit</Link>
                        </ELements.BUTTON>
                        <ELements.POP_CONFIRM
                            title="Are you sure to delete this model?"
                            onConfirm={confirmDeleteModel}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <ELements.BUTTON style={{marginLeft: '10px'}} size={"small"} type="primary" danger>
                                <Link to={{
                                    pathname: `models/delete-model/${row?.code}`,
                                    state: {background: location}
                                }}>Delete</Link>
                            </ELements.BUTTON>
                        </ELements.POP_CONFIRM>
                    </>)
                }
            }
        ]
        // @ts-ignore
        return <Elements.TABLE size={"small"} columns={columns} bordered rowKey={record => record.code} dataSource={model} pagination={false} />
    }

    const columns = [
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "200",
        },
        {
            title: "Icon",
            dataIndex: "config",
            key: "config",
            width: "300",
            render: (value: string, row: any) => {
                return(
                    <ImageCustom src={`http://computer-dev-ui.osa.cubetiqs.com${row.config.icon}`}/>
                    // <img width={50} src={`http://computer-dev-ui.osa.cubetiqs.com${row.config.icon}`} alt="icon"/>
                    // <img width={80} src={`${row.config.icon}`} alt="icon"/>
                )
            }
        },
        {
            title: "Action",
            dataIndex: "",
            key: "action",
            render: (value?: string, row?: BrandResponse) => {
                return (<>
                    <ELements.BUTTON onClick={handleModalUpdateBrand} type="primary">
                        <Link to={{
                            pathname: `/brands/edit-brand/${row?.code}`,
                            state: {background: location},
                        }}>Edit</Link>
                    </ELements.BUTTON>
                    &nbsp;&nbsp;
                    <ELements.BUTTON onClick={handleModalDetailBrand} type="primary" style={{backgroundColor: 'yellowgreen', borderColor: 'yellowgreen'}}>
                        <Link to={{
                            pathname: `/brands/detail-brand/${row?.code}`,
                            state: {background: location}
                        }}>Detail</Link>
                    </ELements.BUTTON>
                    &nbsp;&nbsp;
                    <ELements.POP_CONFIRM
                        title="Are you sure to delete this brand?"
                        onConfirm={confirmDeleteBrand}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <ELements.BUTTON type="primary" danger>
                            <Link to={{
                                pathname: `/brands/delete-brand/${row?.code}`,
                                state: {background: location}
                            }}>Delete</Link>
                        </ELements.BUTTON>
                    </ELements.POP_CONFIRM>
                </>)
            }
        }
    ]

    const onTableRowExpand = (expanded:any, record:any) => {
        let keys:any = [];
        if(expanded){
            keys.push(record.code); // I have set my record.id as row key. Check the documentation for more details.
        }
        setExpandedRowKeys(keys)
    }

    useEffect(()=>{
        client.getAllBrandService()
            .then((r:any) => setBrand(r.data))
    },[])

    useEffect(()=> {
        // @ts-ignore
        client.getModelByBrandCodeService(expandedRowKeys).then((r:any) => setModel(r.data.model))
    }, [expandedRowKeys])

    return(
        <MainLayout>


            <BrandTable/>




            {/* <ELements.SPACE style={{marginBottom: 16, display: 'flex',justifyContent: 'space-between'}}>
                <div>
                    <ELements.BUTTON onClick={handleModalCreateBrand} type="primary">
                        <Link to={{
                            pathname: '/brands/create-brand',
                            state: {background: location}
                        }}>Create Brand</Link>
                    </ELements.BUTTON>
                    <ELements.BUTTON onClick={handleModalCreateModel} style={{marginLeft: '20px'}} type="primary">
                        <Link to={{
                            pathname: 'models/create-model',
                            state: {background: location}
                        }}>Create Model</Link>
                    </ELements.BUTTON>
                    <ELements.BUTTON style={{marginLeft: '20px'}} type="primary" onClick={handleLoadPage}>
                        {
                            isLoad ?
                                <Icons.SYNCOUTLINED spin />
                                : <Icons.SYNCOUTLINED />
                        }
                        Reload
                    </ELements.BUTTON>
                </div>
                <Search placeholder="Search products" onSearch={onSearch} enterButton />
            </ELements.SPACE>
            <ELements.TABLE bordered={true} rowKey={record => record.code} onExpand={onTableRowExpand} expandedRowKeys={expandedRowKeys} columns={columns} dataSource={brand} expandable={{expandedRowRender}} />
            */}

 

        
            {/* <ModalBrand visible={isModalCreateBrand} title={"Create Brand"} onCancel={onCancel}>
                <FormBrand onCancel={onCancel} btnSubmitName={"Create"} btnCancelName={"Cancel"}/>
            </ModalBrand>
            <ModalBrand visible={isModalUpdateBrand} title={"Update Brand"} onCancel={onCancel}>
                <FormBrand onCancel={onCancel} btnCancelName={"Cancel"} btnSubmitName={"Update"}/>
            </ModalBrand>
            <ModalBrand visible={isModalDetailBrand} title={"Brand Detail"} onCancel={onCancel}>
                <CardBrand/>
            </ModalBrand>



           
            <ModalBrand visible={isModalCreateModel} title={"Create Model"} onCancel={onCancel}>
                <FormModel onCancel={onCancel} btnSubmitName={"Create"} btnCancelName={"Cancel"}/>
            </ModalBrand>
            <ModalBrand visible={isModalUpdateModel} title={"Update Model"} onCancel={onCancel}>
                <FormModel onCancel={onCancel} btnSubmitName={"Update"} btnCancelName={"Cancel"}/>
            </ModalBrand> */}

            
        </MainLayout>
    )
}

export default withAuth()(BrandPage)