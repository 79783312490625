import React,{ FunctionComponent } from "react";
import styled from "styled-components";
import SpecificationTable from "../../components/table/SpecificationTable";
import { withAuth } from "../../provider/AuthWrapper";
import MainLayout from "../MainLayout";


const Container = styled.div`


`




const SpecificationPage:FunctionComponent = () => {
    return(
       <MainLayout>
            <Container>
                <SpecificationTable/>
            </Container>
       </MainLayout>
    )
}

export default withAuth()(SpecificationPage)