"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.getItemSpecificationByItemCodeService = exports.updateCostItemService = exports.deleteItemService = exports.updateItemService = exports.getfilterItemSearchService = exports.filterItemSearchService = exports.createItem = exports.getPopularItemService = exports.getItemByCodeService = exports.adminGetItemByCodeService = exports.adminGetAllItemService = exports.getAllItems = void 0;
var AxiosConfig_1 = require("../../config/axios/AxiosConfig");
var Route_1 = require("../../route/Route");
var AuthToken_1 = require("../../util/token/AuthToken");
function getAllItems(params) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_c) {
            token = (0, AuthToken_1.getClientToken)();
            if (token === null)
                return [2 /*return*/, (_a = (0, AxiosConfig_1.getAxiosAnonymousInstance)()) === null || _a === void 0 ? void 0 : _a.get(Route_1.FRONT_GET_ALL_ITEMS, { params: params }).then(function (r) { return r.data; })];
            else
                return [2 /*return*/, (_b = (0, AxiosConfig_1.getAxiosInstance)()) === null || _b === void 0 ? void 0 : _b.get(Route_1.FRONT_GET_ALL_ITEMS, { params: params }).then(function (r) { return r.data; })];
            return [2 /*return*/];
        });
    });
}
exports.getAllItems = getAllItems;
function adminGetAllItemService(params) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, (_a = (0, AxiosConfig_1.getAxiosInstance)()) === null || _a === void 0 ? void 0 : _a.get(Route_1.BACK_ITEM, { params: params }).then(function (r) { return r.data; })];
        });
    });
}
exports.adminGetAllItemService = adminGetAllItemService;
function adminGetItemByCodeService(code, params) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var url;
        return __generator(this, function (_b) {
            url = "".concat(Route_1.BACK_ITEM, "/").concat(code);
            return [2 /*return*/, (_a = (0, AxiosConfig_1.getAxiosInstance)()) === null || _a === void 0 ? void 0 : _a.get(url, { params: params }).then(function (r) { return r.data; })];
        });
    });
}
exports.adminGetItemByCodeService = adminGetItemByCodeService;
function getItemByCodeService(code, params) {
    return __awaiter(this, void 0, void 0, function () {
        var axios, url;
        return __generator(this, function (_a) {
            axios = (0, AuthToken_1.getClientToken)() == null ? (0, AxiosConfig_1.getAxiosAnonymousInstance)() : (0, AxiosConfig_1.getAxiosInstance)();
            url = "".concat(Route_1.FRONT_GET_ALL_ITEMS, "/").concat(code);
            return [2 /*return*/, axios === null || axios === void 0 ? void 0 : axios.get(url, params).then(function (r) { return r.data; })];
        });
    });
}
exports.getItemByCodeService = getItemByCodeService;
function getPopularItemService(params) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, (_a = (0, AxiosConfig_1.getAxiosAnonymousInstance)()) === null || _a === void 0 ? void 0 : _a.get(Route_1.FRONT_GET_ALL_ITEMS_POPULAR, { params: params }).then(function (r) { return r.data; })];
        });
    });
}
exports.getPopularItemService = getPopularItemService;
function createItem(data, files, params) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var formData, _i, files_1, file;
        return __generator(this, function (_b) {
            formData = new FormData();
            formData.append('content', JSON.stringify(data));
            // @ts-ignore
            for (_i = 0, files_1 = files; _i < files_1.length; _i++) {
                file = files_1[_i];
                formData.append('files', file);
            }
            return [2 /*return*/, (_a = (0, AxiosConfig_1.getAxiosInstance)()) === null || _a === void 0 ? void 0 : _a.post(Route_1.BACK_ITEM, formData, { params: params }).then(function (r) { return r.data; })];
        });
    });
}
exports.createItem = createItem;
function filterItemSearchService(data, params) {
    return __awaiter(this, void 0, void 0, function () {
        var axios;
        return __generator(this, function (_a) {
            axios = (0, AuthToken_1.getClientToken)() == null ? (0, AxiosConfig_1.getAxiosAnonymousInstance)() : (0, AxiosConfig_1.getAxiosInstance)();
            return [2 /*return*/, axios.post(Route_1.FRONT_GET_ITEM_FILTER, data, { params: params }).then(function (r) { return r.data; })];
        });
    });
}
exports.filterItemSearchService = filterItemSearchService;
function getfilterItemSearchService(params) {
    return __awaiter(this, void 0, void 0, function () {
        var axios;
        return __generator(this, function (_a) {
            axios = (0, AuthToken_1.getClientToken)() == null ? (0, AxiosConfig_1.getAxiosAnonymousInstance)() : (0, AxiosConfig_1.getAxiosInstance)();
            return [2 /*return*/, axios.get(Route_1.FRONT_GET_ITEM_FILTER_RECENT, { params: params }).then(function (r) { return r.data; })];
        });
    });
}
exports.getfilterItemSearchService = getfilterItemSearchService;
function updateItemService(code, data, params) {
    return __awaiter(this, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = "".concat(Route_1.BACK_ITEM, "/").concat(code);
            return [2 /*return*/, (0, AxiosConfig_1.getAxiosInstance)()
                    .put(url, data, { params: params })
                    .then(function (r) { return r.data; })];
        });
    });
}
exports.updateItemService = updateItemService;
function deleteItemService(code, params) {
    return __awaiter(this, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = "".concat(Route_1.BACK_ITEM, "/").concat(code);
            return [2 /*return*/, (0, AxiosConfig_1.getAxiosInstance)()["delete"](url, { params: params })
                    .then(function (r) { return r.data; })];
        });
    });
}
exports.deleteItemService = deleteItemService;
function updateCostItemService(code, data, params) {
    return __awaiter(this, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = "".concat(Route_1.BACK_ITEM, "/cost/").concat(code);
            return [2 /*return*/, (0, AxiosConfig_1.getAxiosInstance)()
                    .put(url, data, { params: params })
                    .then(function (r) { return r.data; })];
        });
    });
}
exports.updateCostItemService = updateCostItemService;
function getItemSpecificationByItemCodeService(codeItem, params) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var url;
        return __generator(this, function (_b) {
            url = "".concat(Route_1.FRONT_GET_ALL_ITEMS, "/").concat(codeItem);
            return [2 /*return*/, (_a = (0, AxiosConfig_1.getAxiosAnonymousInstance)()) === null || _a === void 0 ? void 0 : _a.get(url, { params: params }).then(function (r) { return r.data; })];
        });
    });
}
exports.getItemSpecificationByItemCodeService = getItemSpecificationByItemCodeService;
