import { OrderSortStatus, OrderStatus } from "@cubetiq/computer-web-sdk"
import { Select } from "antd"
import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { renderColorTag, renderOrderTag } from "../../util/ComponentUtils"


const Container = styled.div`
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const Title = styled.div`
    margin-right: 10px;
`

const { Option } = Select




interface OrderSortProps {
    onChange?:(value:any) => void
}



const OrderSort: FunctionComponent<OrderSortProps> = (props) => {
    const [value, setValue] = useState<OrderSortStatus>(OrderSortStatus.PENDING)


    return (
        <Container>
            <Title>
                Sort By:
            </Title>
            <Select defaultValue={OrderSortStatus.ALL} style={{ minWidth: 120 }} onChange={props.onChange}>
                <Option value={OrderSortStatus.ALL}>{OrderSortStatus.ALL}</Option>
                <Option value={OrderSortStatus.PENDING}>{OrderSortStatus.PENDING}</Option>
                <Option value={OrderSortStatus.APPROVE}>{OrderSortStatus.APPROVE}</Option>
                <Option value={OrderSortStatus.DONE} >{OrderSortStatus.DONE}</Option>
                <Option value={OrderSortStatus.CANCEL}>{OrderSortStatus.CANCEL}</Option>
            </Select>

        </Container>
    )
}

export default OrderSort