import React, {FunctionComponent, useEffect, useState} from "react";
import {ClientService, ImportResponse, SupplierResponse} from "@cubetiq/computer-web-sdk";
import styled from "styled-components";
import {Input, message, Popconfirm, Table, TablePaginationConfig} from "antd";
import {ColumnType} from "antd/es/table";
import ImportDetailTable from "./ImportDetailTable";
import {getErrorMessage, getTotalWithQty, toUSD} from "../../util/utils";
import ImportForm from "../form/ImportForm";
import ModalCustom from "../ModalCustom";
import {AddIconCustom, CreateButton} from "../../pages/import";
import {ReloadButton} from "./BrandTable";
import {QuestionCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import {DeleteIcon} from "../icon/IconCustom";


const TableCustom = styled(Table)`
  padding: 20px 0 0 0;

  .ant-table-expanded-row {
    .ant-table-cell {
      background: none;
    }

    .ant-table-content {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
`


export const ReloadIcon = styled(ReloadOutlined)`
  font-size: 18px;
  color: white;
`


const Container = styled.div`

`


const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonContainer = styled.div`
`

const {Search} = Input

const client = ClientService.getInstance()
const ImportTable: FunctionComponent = () => {

    const [imports, setImports] = useState<Array<ImportResponse>>()
    const [isLoading, setIsLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
    const [isFirstRender, setIsFirstRender] = useState(true)


    const query = client.getAllImportService

    useEffect(() => {
        onLoadData()
        setIsFirstRender(false)
    }, [])


    const onLoadData = () => {
        queryData(0, 10, searchValue)
    }


    const columns: Array<ColumnType<any>> = [
        {
            title: 'Import Code',
            dataIndex: 'code',
            key: 'code',
            width: 200,
        },
        {
            title: 'Invoice No',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
            width: 200,
        },
        {
            title: 'Date',
            dataIndex: 'importDate',
            key: 'importDate',
            width: 200,
            render: (value) => {
                const date: Date = new Date(value)
                return (
                    <div>
                        {date.toDateString()}
                    </div>
                )
            }

        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',
            key: 'supplier',
            width: 250,
            render: (value: SupplierResponse) => {

                return (
                    <div>
                        {value.name}
                    </div>
                )
            }

        },
        {
            title: 'Memo',
            dataIndex: 'memo',
            key: 'memo',
            render: (value) => {
                return (
                    <div>{value}</div>
                )
            }

        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: "center",
            render: (_, row) => {
                const total = getTotalWithQty(row.details, "amount", "qty")
                return (
                    <div>{toUSD.format(total)}</div>
                )
            }

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: "center",
            render: (_, row) => {

                return (
                    <Popconfirm title="Are you sure to delete this import？"
                                onConfirm={() => onRemoveImport(row)}
                                icon={<QuestionCircleOutlined
                                    style={{color: 'red', fontSize: 16}}/>}>
                        <DeleteIcon/>
                    </Popconfirm>
                )
            }

        }
    ];


    const onRemoveImport =  (value: ImportResponse) => {
        client.deleteImportService(value.code).then(() => {
            const current = imports?.filter(it => it.id !== value.id)
            setImports([...current ?? []])
             message.success("Delete Successfully").then()
        }).catch(r => {
            message.error(getErrorMessage(r)).then()
        })
    }


    const [isCreate, setIsCreate] = useState(false)

    const onCreateHandle = () => {
        setIsCreate(!isCreate)
    }


    const PaginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            queryData((v - 1), 10, searchValue)
        },
        total: totalCount,
    }

    const onSearchHandle = (v: any) => {
        if (v.toString().trim().length > 0) {
            setSearchValue(v)
            queryData(0, 10, v)
        } else {
            setSearchValue(undefined)
        }
    }


    useEffect(() => {
        if (searchValue === undefined && !isFirstRender) {
            setIsLoading(true)
            queryData(0, 10, undefined)
        }
    }, [searchValue])

    const queryData = (page: number, size: number, q?: string) => {
        setIsLoading(true)
        query({
            page: 0,
            size: 10,
            q: q
        }).then(r => {
            setImports(r.data)
            setTotalCount(r.pages.totalCount)
        }).finally(() => setIsLoading(false))
    }




    return (

        <Container>
            <ModalCustom visible={isCreate}
                         footer={null}
                         width={1100}
                         centered
                         onCancel={onCreateHandle}
                         children={<ImportForm/>}/>

            <HeaderContainer>
                <ButtonContainer>
                    <CreateButton icon={<AddIconCustom/>}
                                  onClick={onCreateHandle}
                                  children={"Create"}/>

                    <ReloadButton icon={<ReloadIcon/>}
                                  onClick={onLoadData}
                                  children={"Reload"}/>
                </ButtonContainer>

                <Search placeholder="Please input import code or invoice code "
                        onSearch={onSearchHandle}
                        allowClear
                        style={{width: "400px", marginRight: 20}} enterButton/>
            </HeaderContainer>

            <TableCustom columns={columns}
                         bordered
                         pagination={PaginationConfig}
                         loading={isLoading}
                         rowKey={(value: any) => value.id}
                         expandable={{
                             expandedRowRender: (row: any, index: number) => (
                                 <ImportDetailTable data={row.details} key={index}/>
                             ),
                         }}
                         dataSource={imports}/>
        </Container>

    )
}

export default ImportTable