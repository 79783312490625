import React, {FunctionComponent, useState} from "react";
import {ImportDetailResponse, ItemResponse, SerialNumberResponse} from "@cubetiq/computer-web-sdk";
import {ColumnType} from "antd/es/table";
import styled from "styled-components";
import {Table, TableProps} from "antd";
import {toUSD} from "../../util/utils";
import {EyeFilled} from "@ant-design/icons";
import ModalCustom from "../ModalCustom";
import SerialNumberUpdateForm from "../../pages/import/form/SerialNumberUpdateForm";


interface ImportDetailTableProps extends TableProps<any> {
    data: Array<ImportDetailResponse>
}


const TableCustom = styled(Table)`
  padding: 20px 20px 20px 20px;
`

const ProductContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProductImage = styled.img`
  width: 75px;
  height: 75px;
  object-fit: contain;
  margin-right: 10px;
`

const ProductName = styled.div`
`

const ImportDetailTable: FunctionComponent<ImportDetailTableProps> = (props) => {
    const {data, ...rest} = props

    const [serials, setSerial] = useState<SerialNumberResponse[] | undefined>(undefined)
    const [limit, setLimit ] = useState(0)
    const [isViewSerial,setIsViewSerial] = useState(false)

    const onIsViewSerialHandle = () => {
        setIsViewSerial(!isViewSerial)
    }


    const ModalViewSerial = () => {
        if(serials === undefined || limit <= 0 ) return <></>
        return (
            <ModalCustom visible={isViewSerial}
                         footer={null}
                         children={<SerialNumberUpdateForm  limit={limit}
                                                            serials={serials}/>}
                         onCancel={onIsViewSerialHandle}/>
        )
    }

    const columns: Array<ColumnType<any>> = [
        {
            title: 'Product Avatar',
            dataIndex: 'item',
            align: "center",
            key: 'item',
            render: ((value: ItemResponse) => {
                return (
                    <ProductContainer>
                        <ProductImage src={value.attachments?.[0].originalLink}/>
                    </ProductContainer>
                )
            })
        },
        {
            title: 'Product Name',
            dataIndex: 'item',
            align: "center",
            key: 'item',
            render: ((value: ItemResponse) => {
                return (
                    <ProductContainer>
                        <ProductName>
                            {value.name}
                        </ProductName>
                    </ProductContainer>
                )
            })
        },
        {
            title: 'Serials Number',
            dataIndex: 'serials',
            align: "center",
            key: 'serials',
            render: (v,row) => <EyeFilled style={{
                fontSize: 18
            }} onClick={() => {
                setLimit(row.qty)
                setSerial(v)
                onIsViewSerialHandle()
            }}/>
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            align: "center",
            key: 'qty',
            width: 300,
        },
        {
            title: 'Price',
            dataIndex: 'amount',
            key: 'amount',
            align: "center",
            width: 300,
            render: value => {
                return (
                    <div>
                        {toUSD.format(value)}
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: "center",
            width: 300,
            render: ((value, row) => {
                return (
                    <div>
                        {toUSD.format(row.qty * row.amount)}
                    </div>
                )
            })
        }
    ];

    return (
        <React.Fragment>
            <ModalViewSerial/>
            <TableCustom
                bordered
                pagination={false}
                dataSource={data}
                rowKey={(value: any) => value.id}
                columns={columns}/>
        </React.Fragment>
    )
}
export default ImportDetailTable