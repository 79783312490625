
export default function text(text: string): string {
    return text
}

export const toUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})


export const getTotalWithQty = (value: Array<any>, key1: string, key2: string):number => {
    let total = 0 ;
    value.forEach(it => {
        total += Number(it[key1]) * Number(it[key2])
    })
    return  total
}


export const getErrorMessage = (r:any) => {
    const responseData = r.response?.data
    const serverMessage = responseData.status?.message || responseData.message || responseData.error
    return serverMessage
}


export const pasreParamaterUrl = (value: string, replace:string) =>{
    const parse = value.split(":")
    if(parse.length <= 0)
        return ""
    return `${parse[0]}${replace}`
}

export const onErrorImageFallback = (event:any) => {
    event.target.src = "/logo.svg"
}
