import React,{ FunctionComponent } from "react";
import FileSelectWithPreview from "../../components/upload/FileSelectWithPreview";
import SliderUploadFileWithPreview from "../../components/upload/SliderUploadFileWithPreview";
import { withAuth } from "../../provider/AuthWrapper";
import MainLayout from "../MainLayout";



const SliderPage:FunctionComponent = () => {

 


    return(
        <MainLayout>
            <SliderUploadFileWithPreview/>
        </MainLayout>
    )
}   

export default withAuth()(SliderPage)