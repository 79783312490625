
import { BrandResponse, CategoryResponse, ClientService, LocationRequest, SubCategoryResponse } from "@cubetiq/computer-web-sdk"
import { SubCategoryRequest } from "@cubetiq/computer-web-sdk/dist/interface/request/sub_category/SubCategoryRequest"
import { ModelRequest } from "@cubetiq/computer-web-sdk/src/interface/request/model/ModelRequest"
import { Button, InputNumber, message, Spin } from "antd"
import { Form, Input } from "antd"
import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"





const Container = styled.div`


`


interface CreateLocationFormProps {
  
}

const ButtonSubmit= styled(Button)`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 5px;
    border: none;
    background-color: #19aa8d;
    color: white;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
`





const client = ClientService.getInstance()
const CreateLocationForm: FunctionComponent<CreateLocationFormProps> = (props) => {
    const [isLoading,setIsLoading] = useState(false)
    const form = Form.useForm()[0]
   

    const onFinish = (values:any) => {
        setIsLoading(true)
        const request: LocationRequest = {
            location: values["location"],
            price: values["price"]
        }

        client.createLocationService(request).then(r => {
            message.success("Create location successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=>setIsLoading(false))

        form.resetFields()
    }


    


    return (
        <Container>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>
                <Form.Item name={"location"}
                    hasFeedback
                    label={"Location"}
                    required rules={[
                        {
                            required: true,
                            message: "Please input location"
                        }]}>
                        <Input/>
                </Form.Item>


                <Form.Item name={"price"}
                    hasFeedback
                    label={"Price"}
                    required rules={[
                        {
                            required: true,
                            message: "Please input price"
                        }]}>
                        <InputNumber min={0} max={9999} style={{ width: "100%"}}/>
                </Form.Item>

                <Form.Item style={{marginBottom: 0}}>
                    <Spin spinning={isLoading}>
                        <ButtonSubmit children={"Create"} htmlType={"submit"}/>
                    </Spin>
                </Form.Item>
            </Form>
        </Container>
    )

}

export default CreateLocationForm