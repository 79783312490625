"use strict";
exports.__esModule = true;
exports.ClientService = void 0;
var __1 = require("..");
var AuthService_1 = require("../service/auth/AuthService");
var FavoriteService_1 = require("../service/FavoriteService");
var ItemService_1 = require("../service/item/ItemService");
var SliderService_1 = require("../service/slider/SliderService");
var LocationService_1 = require("../service/location/LocationService");
var CategoryService_1 = require("../service/category/CategoryService");
var BrandService_1 = require("../service/brand/BrandService");
var FileService_1 = require("../service/file/FileService");
var SubCategoryService_1 = require("../service/sub_category/SubCategoryService");
var OrderService_1 = require("../service/order/OrderService");
var UtilsService_1 = require("../service/utils/UtilsService");
var SpecificationService_1 = require("../service/specification/SpecificationService");
var SupplierService_1 = require("../service/supplier/SupplierService");
var ImportService_1 = require("../service/import/ImportService");
var UserService_1 = require("../service/user/UserService");
var ItemSpecificationService_1 = require("../service/item_specification/ItemSpecificationService");
var VisitorService_1 = require("../service/visitor/VisitorService");
var StockService_1 = require("../service/stock/StockService");
var CartService_1 = require("../service/cart/CartService");
var MeasurementService_1 = require("../service/measurement/MeasurementService");
var PaymentMethodService_1 = require("../service/payment/PaymentMethodService");
var TranslationService_1 = require("../service/translation/TranslationService");
var BrandService_2 = require("../service/brand/BrandService");
var BrandService_3 = require("../service/brand/BrandService");
var ModelService_1 = require("../service/model/ModelService");
var ClientService = /** @class */ (function () {
    function ClientService() {
    }
    ClientService.getInstance = function () {
        if (!ClientService.instance) {
            ClientService.instance = new ClientService();
        }
        return ClientService.instance;
    };
    // Authentication User
    ClientService.prototype.getLoginService = function (request, params) {
        return (0, AuthService_1.loginService)(request, params);
    };
    ClientService.prototype.fetchUserService = function (params) {
        return (0, AuthService_1.fetchUserService)(params);
    };
    ClientService.prototype.getRegisterService = function (request, params) {
        return (0, AuthService_1.registerService)(request, params);
    };
    ClientService.prototype.userProfileUpdateService = function (request, params) {
        return (0, AuthService_1.userProfileUpdate)(request, params);
    };
    ClientService.prototype.updateAvatarProfileService = function (file, params) {
        return (0, AuthService_1.updateAvatarProfileService)(file, params);
    };
    ClientService.prototype.sendResetCodeService = function (request, params) {
        return (0, AuthService_1.sendResetCodeService)(request, params);
    };
    ClientService.prototype.resetCodeVerifyService = function (request, params) {
        return (0, __1.resetVerifyCodeService)(request, params);
    };
    ClientService.prototype.resetPasswordChangeService = function (request, params) {
        return (0, AuthService_1.resetChangePasswordService)(request, params);
    };
    ClientService.prototype.userChangePasswordService = function (request, params) {
        return (0, AuthService_1.userChangePasswordService)(request, params);
    };
    ClientService.prototype.sendVerifyCodeService = function (request, params) {
        return (0, AuthService_1.sendVerifyCodeService)(request, params);
    };
    // Back End User
    ClientService.prototype.backendLoginService = function (request, params) {
        return (0, __1.backendLoginService)(request, params);
    };
    ClientService.prototype.getAllUserService = function (params) {
        return (0, UserService_1.getAllUserService)(params);
    };
    ClientService.prototype.getUserByCodeService = function (code, params) {
        return (0, UserService_1.getUserByCodeService)(code, params);
    };
    ClientService.prototype.deleteUserByCodeService = function (code, params) {
        return (0, UserService_1.deleteUserByCodeService)(code, params);
    };
    ClientService.prototype.updateIsEnableUserService = function (code, params) {
        return (0, UserService_1.updateIsEnableUserService)(code, params);
    };
    ClientService.prototype.resetChangePasswordUser = function (code, request, params) {
        return (0, UserService_1.resetChangePasswordUser)(code, request, params);
    };
    ClientService.prototype.createFavoriteService = function (code, params) {
        return (0, FavoriteService_1.createFavoriteService)(code, params);
    };
    // Slider
    ClientService.prototype.getSliderService = function (params) {
        return (0, SliderService_1.getSliderService)(params);
    };
    ClientService.prototype.createSliderService = function (request, params) {
        return (0, SliderService_1.createSliderService)(request, params);
    };
    ClientService.prototype.deleteSliderService = function (code, params) {
        return (0, SliderService_1.deleteSliderService)(code, params);
    };
    // Visitor
    ClientService.prototype.getAllVisitorService = function (params) {
        return (0, VisitorService_1.getAllVisitorService)(params);
    };
    // Items
    ClientService.prototype.getAllItemsService = function (params) {
        return (0, ItemService_1.getAllItems)(params);
    };
    ClientService.prototype.adminGetAllItemService = function (params) {
        return (0, __1.adminGetAllItemService)(params);
    };
    ClientService.prototype.getItemByCodeService = function (code, params) {
        return (0, ItemService_1.getItemByCodeService)(code, params);
    };
    ClientService.prototype.getFilterItemService = function (request, params) {
        return (0, ItemService_1.filterItemSearchService)(request, params);
    };
    ClientService.prototype.getFilterItemSearchService = function (params) {
        return (0, ItemService_1.getfilterItemSearchService)(params);
    };
    ClientService.prototype.getItemSpecificationByItemCodeService = function (codeItem, params) {
        return (0, __1.getItemSpecificationByItemCodeService)(codeItem, params);
    };
    ClientService.prototype.createItem = function (data, files, params) {
        return (0, __1.createItem)(data, files, params);
    };
    ClientService.prototype.updateItemService = function (code, request, params) {
        return (0, __1.updateItemService)(code, request, params);
    };
    ClientService.prototype.deleteItemService = function (code, params) {
        return (0, ItemService_1.deleteItemService)(code, params);
    };
    ClientService.prototype.updateCostItemService = function (code, request, params) {
        return (0, ItemService_1.updateCostItemService)(code, request, params);
    };
    // Location
    ClientService.prototype.getAllLocationService = function (params) {
        return (0, LocationService_1.getAllLocationService)(params);
    };
    ClientService.prototype.createLocationService = function (request, params) {
        return (0, LocationService_1.createLocationService)(request, params);
    };
    ClientService.prototype.updateLocationService = function (code, request, params) {
        return (0, LocationService_1.updateLocationService)(code, request, params);
    };
    ClientService.prototype.deleteLocationService = function (code, params) {
        return (0, LocationService_1.deleteLocationService)(code, params);
    };
    // Category
    ClientService.prototype.getAllCategoryService = function (params) {
        return (0, CategoryService_1.getAllCategoryService)(params);
    };
    ClientService.prototype.getCategoryByCodeService = function (code, params) {
        return (0, __1.getCategoryByCodeService)(code, params);
    };
    ClientService.prototype.getCategoryByIdService = function (id, params) {
        return (0, __1.getCategoryByIdService)(id, params);
    };
    ClientService.prototype.getSubCategoryByCategoryCodeService = function (codeCategory, params) {
        return (0, __1.getSubCategoryByCategoryCodeService)(codeCategory, params);
    };
    // Backend Category
    ClientService.prototype.createCategoryService = function (data, params) {
        return (0, __1.createCategoryService)(data, params);
    };
    ClientService.prototype.updateCategoryService = function (code, data, params) {
        return (0, __1.updateCategoryService)(code, data, params);
    };
    ClientService.prototype.deleteCategoryService = function (code, params) {
        return (0, CategoryService_1.deleteCategoryService)(code, params);
    };
    // Sub Category
    ClientService.prototype.getAllSubCategoryService = function (params) {
        return (0, SubCategoryService_1.getAllSubCategoryService)(params);
    };
    ClientService.prototype.getSubCategoryByCodeService = function (code, params) {
        return (0, SubCategoryService_1.getSubCategoryByCodeService)(code, params);
    };
    ClientService.prototype.createSubCategoryService = function (data, params) {
        return (0, SubCategoryService_1.createSubCategoryService)(data, params);
    };
    ClientService.prototype.updateSubCategoryService = function (code, data, params) {
        return (0, SubCategoryService_1.updateSubCategoryService)(code, data, params);
    };
    ClientService.prototype.deleteSubCategoryService = function (code, params) {
        return (0, SubCategoryService_1.deleteSubCategoryService)(code, params);
    };
    // Brand Service
    ClientService.prototype.getAllBrandService = function (params) {
        return (0, BrandService_1.getAllBrandService)(params);
    };
    ClientService.prototype.getBrandByCodeService = function (code, params) {
        return (0, BrandService_2.getBrandByCodeService)(code, params);
    };
    ClientService.prototype.getModelByBrandCodeService = function (codeBrand, params) {
        return (0, BrandService_3.getModelByBrandCodeService)(codeBrand, params);
    };
    // Backend Brand
    ClientService.prototype.createBrandService = function (data, params) {
        return (0, __1.createBrandService)(data, params);
    };
    ClientService.prototype.updateBrandService = function (code, data, params) {
        return (0, __1.updateBrandService)(code, data, params);
    };
    ClientService.prototype.deleteBrandService = function (code, params) {
        return (0, __1.deleteBrandService)(code, params);
    };
    // Model
    ClientService.prototype.getModelByCodeService = function (code, params) {
        return (0, ModelService_1.getModelByCodeService)(code, params);
    };
    ClientService.prototype.getAllModelService = function (params) {
        return (0, ModelService_1.getAllModelService)(params);
    };
    ClientService.prototype.createModelService = function (data, params) {
        return (0, ModelService_1.createModelService)(data, params);
    };
    ClientService.prototype.updateModelService = function (code, data, params) {
        return (0, ModelService_1.updateModelService)(code, data, params);
    };
    ClientService.prototype.deleteModelService = function (code, params) {
        return (0, ModelService_1.deleteModelService)(code, params);
    };
    // File Service
    ClientService.prototype.fileUploadService = function (file, param) {
        return (0, FileService_1.fileUploadService)(file, param);
    };
    ClientService.prototype.otherUploadFileService = function (file, params) {
        return (0, FileService_1.otherFileUploadService)(file, params);
    };
    // Order Service
    ClientService.prototype.createOrderService = function (request, file, params) {
        return (0, OrderService_1.createOrderService)(request, file, params);
    };
    ClientService.prototype.orderModifyService = function (code, data, params) {
        return (0, OrderService_1.orderModifyService)(code, data, params);
    };
    ClientService.prototype.adminCreateOrderService = function (data, file, params) {
        return (0, OrderService_1.adminCreateOrderService)(data, file, params);
    };
    // Fetch Price
    ClientService.prototype.fetchMinAndMaxPriceService = function (params) {
        return (0, UtilsService_1.fetchMinAndMaxPriceService)(params);
    };
    ClientService.prototype.fetchMyOrderService = function (params) {
        return (0, OrderService_1.fetchMyOrderService)(params);
    };
    ClientService.prototype.userOrderUpdateStatusService = function (request, params) {
        return (0, OrderService_1.userOrderUpdateService)(request, params);
    };
    ClientService.prototype.multipleFilesUploadService = function (file, params) {
        return (0, FileService_1.multipleFilesUploadService)(file, params);
    };
    // Specification
    ClientService.prototype.getAllSpecificationService = function (params) {
        return (0, SpecificationService_1.getAllSpecificationService)(params);
    };
    ClientService.prototype.getSpecificationByCodeService = function (code, params) {
        return (0, SpecificationService_1.getSpecificationByCodeService)(code, params);
    };
    ClientService.prototype.createSpecificationService = function (data, params) {
        return (0, SpecificationService_1.createSpecificationService)(data, params);
    };
    ClientService.prototype.updateSpecificationService = function (code, data, params) {
        return (0, SpecificationService_1.updateSpecificationService)(code, data, params);
    };
    ClientService.prototype.deleteSpecificationService = function (code, params) {
        return (0, SpecificationService_1.deleteSpecificationService)(code, params);
    };
    // Item Specification Backend
    ClientService.prototype.updateItemSpecificationService = function (code, request, params) {
        return (0, ItemSpecificationService_1.updateItemSpecificationService)(code, request, params);
    };
    // Supplier
    ClientService.prototype.getAllSupplierService = function (params) {
        return (0, SupplierService_1.getAllSupplierService)(params);
    };
    ClientService.prototype.getTotalCostAndStockService = function (params) {
        return (0, StockService_1.getTotalCostAndStockService)(params);
    };
    ClientService.prototype.createSupplierService = function (request, params) {
        return (0, SupplierService_1.createSupplierService)(request, params);
    };
    ClientService.prototype.updateSupplierService = function (code, request, params) {
        return (0, SupplierService_1.updateSupplierService)(code, request, params);
    };
    ClientService.prototype.deleteSupplierService = function (code, params) {
        return (0, SupplierService_1.deleteSupplierService)(code, params);
    };
    // Import
    ClientService.prototype.createImportService = function (request, params) {
        return (0, ImportService_1.createImportService)(request, params);
    };
    ClientService.prototype.deleteImportService = function (code, params) {
        return (0, ImportService_1.deleteImportService)(code, params);
    };
    // Order
    ClientService.prototype.getAllOrderService = function (params) {
        return (0, OrderService_1.getAllOrderService)(params);
    };
    ClientService.prototype.orderDetailMultipleUpdateService = function (code, request, params) {
        return (0, OrderService_1.orderDetailMultipleUpdateService)(code, request, params);
    };
    ClientService.prototype.getAllImportService = function (params) {
        return (0, ImportService_1.getAllImportService)(params);
    };
    ClientService.prototype.adminOrderUpdateService = function (code, params) {
        return (0, OrderService_1.adminOrderUpdateService)(code, params);
    };
    ClientService.prototype.deleteOrderService = function (code, params) {
        return (0, OrderService_1.deleteOrderService)(code, params);
    };
    // Cart Service
    ClientService.prototype.fetchMyCartService = function (params) {
        return (0, CartService_1.fetchMyCartService)(params);
    };
    ClientService.prototype.addToCartService = function (request, params) {
        return (0, CartService_1.addToCartService)(request, params);
    };
    ClientService.prototype.addMultipleCartService = function (request, params) {
        return (0, CartService_1.addMultipleCartService)(request, params);
    };
    ClientService.prototype.removeCartService = function (code, params) {
        return (0, CartService_1.removeCartService)(code, params);
    };
    ClientService.prototype.getAllMeasurementService = function (params) {
        return (0, MeasurementService_1.getAllMeasurementService)(params);
    };
    ClientService.prototype.getOneMeasurementService = function (code, params) {
        return (0, MeasurementService_1.getOneMeasurementService)(code, params);
    };
    ClientService.prototype.createMeasurementService = function (data, params) {
        return (0, MeasurementService_1.createMeasurementService)(data, params);
    };
    ClientService.prototype.deleteMeasurementService = function (code, params) {
        return (0, MeasurementService_1.deleteMeasurementService)(code, params);
    };
    ClientService.prototype.updateMeasurementService = function (code, data, params) {
        return (0, MeasurementService_1.updateMeasurementService)(code, data, params);
    };
    ClientService.prototype.getAllPaymentMethodService = function (params) {
        return (0, PaymentMethodService_1.getAllPaymentMethodService)(params);
    };
    ClientService.prototype.getOnePaymentMethodService = function (code, params) {
        return (0, PaymentMethodService_1.getOnePaymentMethodService)(code, params);
    };
    ClientService.prototype.createPaymentMethodService = function (data, params) {
        return (0, PaymentMethodService_1.createPaymentMethodService)(data, params);
    };
    ClientService.prototype.deletePaymentMethodService = function (code, params) {
        return (0, PaymentMethodService_1.deletePaymentMethodService)(code, params);
    };
    ClientService.prototype.updatePaymentMethodService = function (code, data, params) {
        return (0, PaymentMethodService_1.updatePaymentMethodService)(code, data, params);
    };
    ClientService.prototype.createTranslationService = function (data, params) {
        return (0, TranslationService_1.createTranslationService)(data, params);
    };
    ClientService.prototype.getAllTranslationService = function (params) {
        return (0, TranslationService_1.getAllTranslationService)(params);
    };
    ClientService.prototype.getAllLanguageService = function (params) {
        return (0, TranslationService_1.getAllLanguageService)(params);
    };
    return ClientService;
}());
exports.ClientService = ClientService;
