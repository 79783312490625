import React, {FunctionComponent, useEffect, useState} from "react";
import MainLayout from "../MainLayout";
import ELements from "../../elements";
import {Link, Route, useHistory, useLocation, useParams} from "react-router-dom";
import Icons from '../../icons'
import {
    CategoryResponse, ClientService,
    getAllCategoryService,
    SubCategoryResponse
} from "@cubetiq/computer-web-sdk";
import ModalCategory from "./components/ModalCategory";
import FormCategory from "./components/FormCategory";
import {withAuth} from "../../provider/AuthWrapper";
import {ROUTE_URI_ENUM} from "../../routes/paths";
import {Form, FormInstance} from "antd";
import {logPrint} from "../../util/Logging";
import CardCategory from "./components/CardCategory";
import Elements from "../../elements";
import {ColumnsType} from "antd/es/table";
import ModalSubCategory from "./sub_categories/components/ModalSubCategory";
import FormSubCategory from "./sub_categories/components/FormSubCategory";
import CategoryTable from "../../components/table/CategoryTable";
const { Search } = ELements.INPUT;

const client  = ClientService.getInstance()

const CategoryPage: FunctionComponent = () =>{

    const [category, setCategory] = useState<Array<CategoryResponse>>([])
    const [subCategory, setSubCategory] = useState<Array<SubCategoryResponse>>([])
    const [isVisibleModalCreate, setIsVisibleModalCreate] = useState(false)
    const [isVisibleModalUpdate, setIsVisibleModalUpdate] = useState(false)
    const [isVisibleModalDetail, setIsVisibleModalDetail] = useState(false)
    const [isVisibleModalCreateSubCategory, setIsVisibleModalCreateSubCategory] = useState(false)
    const [isVisibleModalUpdateSubCategory, setIsVisibleModalUpdateSubCategory] = useState(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [form] = Form.useForm()
    const codeCategory = history.location.pathname.substr(28)
    const codeSubCategory = history.location.pathname.substr(36)

    const onSearch = (value:any) => console.log(value);

    const confirmDeleteCategory = (e:any) => {
        ELements.MESSAGE.success('Delete Successfully');
        e.stopPropagation()
        history.push(ROUTE_URI_ENUM.CATEGORY)
        
        client.deleteCategoryService(codeCategory)
            .then(r => {
                logPrint("Data delete", r.data)
            })
            .catch(err => {
                ELements.MESSAGE.error('Delete Failed');
            })

        client.deleteSubCategoryService(codeSubCategory)
    }

    const confirmDeleteSubCategory = (e:any) => {
        ELements.MESSAGE.success('Delete Successfully');
        e.stopPropagation()
        history.push(ROUTE_URI_ENUM.CATEGORY)

        client.deleteSubCategoryService(codeSubCategory)
            .then(r => {
                logPrint("Data Delete", r.data)
            })
            .catch(err => {
                Elements.MESSAGE.error("Delete Failed");
            })
    }

    const cancel = (e:any) => {
        e.stopPropagation()
        history.push(ROUTE_URI_ENUM.CATEGORY)
    }

    const modalCreateCategory = () => {
        setIsVisibleModalCreate(true)
    }

    const modalUpdateCategory = () => {
        setIsVisibleModalUpdate(true)
    }

    const modalDetailCategory = () => {
        setIsVisibleModalDetail(true)
    }

    const modalCreateSubCategory = () => {
        setIsVisibleModalCreateSubCategory(true)
    }

    const modalUpdateSubCatgory = () => {
        setIsVisibleModalUpdateSubCategory(true)
    }

    const handleLoadPage = () => {
        window.location.reload()
        setIsLoad(!isLoad)
    }

    const onCancel = (e:any) => {
        history.push(ROUTE_URI_ENUM.CATEGORY)
        setIsVisibleModalCreate(false)
        setIsVisibleModalUpdate(false)
        setIsVisibleModalDetail(false)
        setIsVisibleModalCreateSubCategory(false)
        setIsVisibleModalUpdateSubCategory(false)
    }

    const expandedRowRender = () => {
        const columns: ColumnsType<SubCategoryResponse> = [
             {
                 title: 'Code',
                 dataIndex: 'code',
                 key: 'code',
             },
             {
                 title: 'Name',
                 dataIndex: 'name',
                 key: 'name',
             },
             {
                 title: 'Action',
                 key: 'action',
                 dataIndex: '',
                 render: (value?: string, row?: SubCategoryResponse) => {
                     return (<>
                         <ELements.BUTTON onClick={modalUpdateSubCatgory} size={"small"} type="primary">
                         <Link to={{
                             pathname: `/sub-categories/edit-sub-category/${row?.code}`,
                             state: {background: location}
                         }}>Edit</Link>
                         </ELements.BUTTON>
                         <ELements.POP_CONFIRM
                             title="Are you sure to delete this sub category?"
                             onConfirm={confirmDeleteSubCategory}
                             onCancel={cancel}
                             okText="Yes"
                             cancelText="No"
                         >
                             <ELements.BUTTON style={{marginLeft: '10px'}} size={"small"} type="primary" danger>
                                 <Link to={{
                                     pathname: `sub-categories/delete-sub-category/${row?.code}`,
                                     state: {background: location}
                                 }}>Delete</Link>
                             </ELements.BUTTON>
                         </ELements.POP_CONFIRM>
                     </>)
                 }
             }
        ]
        return <Elements.TABLE size={"small"} columns={columns} bordered rowKey={record => record.code} dataSource={subCategory} pagination={false} />
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Icon',
            dataIndex: 'config',
            key: 'config',
            width: 300,
            render:(value:string, row:any)=>{
                return(
                    <>
                        <img width={80} src={`http://computer-dev-ui.osa.cubetiqs.com${row.config.icon}`} alt="icon"/>
                        {/*<img width={80} src={`${row.config.icon}`} alt="icon"/>*/}
                    </>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: '',
            render: (value?: string, row?: CategoryResponse) => {
                return (<>
                    <ELements.BUTTON type="primary" onClick={modalUpdateCategory}>
                        <Link to={{
                            pathname: `/categories/edit-category/${row?.code}`,
                            state: {background: location},
                        }}>Edit</Link>
                    </ELements.BUTTON>
                    &nbsp;&nbsp;
                    <ELements.BUTTON type="primary" style={{backgroundColor: 'yellowgreen', borderColor: 'yellowgreen'}} onClick={modalDetailCategory}>
                        <Link to={{
                            pathname: `/categories/detail-category/${row?.code}`,
                            state: {background: location}
                        }}>Detail</Link>
                    </ELements.BUTTON>
                    &nbsp;&nbsp;
                    <ELements.POP_CONFIRM
                        title="Are you sure to delete this category?"
                        onConfirm={confirmDeleteCategory}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <ELements.BUTTON type="primary" danger>
                            <Link to={{
                                pathname: `categories/delete-category/${row?.code}`,
                                state: {background: location}
                            }}>Delete</Link>
                        </ELements.BUTTON>
                    </ELements.POP_CONFIRM>
                </>)
            }
        }
    ];

    const onTableRowExpand = (expanded:any, record:any) => {
        let keys:any = [];
        if(expanded){
            keys.push(record.code); // I have set my record.id as row key. Check the documentation for more details.
        }
        setExpandedRowKeys(keys)
    }

    useEffect(()=>{
        getAllCategoryService().then(r => setCategory(r.data))
    },[])

    useEffect(()=>{
        // @ts-ignore
        client.getSubCategoryByCategoryCodeService(expandedRowKeys).then( (r:any) =>  setSubCategory(r.data.subCategory))
    },[expandedRowKeys])

    return(
        <MainLayout>
            {/* <ELements.SPACE style={{marginBottom: 16, display: 'flex',justifyContent: 'space-between'}}>
                <div>
                    <ELements.BUTTON type="primary" onClick={modalCreateCategory}>
                        <Link to={{
                            pathname: '/categories/create-category',
                            state: {background: location}
                        }}>Create Category</Link>
                    </ELements.BUTTON>
                    <ELements.BUTTON style={{marginLeft: '20px'}} type="primary" onClick={modalCreateSubCategory}>
                        <Link to={{
                            pathname: 'sub-categories/create-sub-category',
                            state: {background: location}
                        }}>Create Sub Category</Link>
                    </ELements.BUTTON>
                    <ELements.BUTTON style={{marginLeft: '20px'}} type="primary" onClick={handleLoadPage}>
                            {
                                isLoad ?
                                    <Icons.SYNCOUTLINED spin />
                                    : <Icons.SYNCOUTLINED />
                            }
                            Reload
                    </ELements.BUTTON>
                </div>
                <Search placeholder="Search products" onSearch={onSearch} enterButton />
            </ELements.SPACE>
            <ELements.TABLE bordered={true} rowKey={record => record.code} expandedRowKeys={expandedRowKeys} columns={columns} onExpand={onTableRowExpand} dataSource={category} expandable={{ expandedRowRender }}/>

            <ModalCategory title={"Create Category"} visible={isVisibleModalCreate} cancel={onCancel}>
                <FormCategory onCancelButton={onCancel} btnSubmitName={"Create"} btnCancelName={"Cancel"}/>
            </ModalCategory>
            <ModalCategory title={"Update Category"} visible={isVisibleModalUpdate} cancel={onCancel}>
                <FormCategory onCancelButton={onCancel} btnSubmitName={"Update"} btnCancelName={"Cancel"}/>
            </ModalCategory>
            <ModalCategory title={"Category Details"} visible={isVisibleModalDetail} cancel={onCancel}>
                <CardCategory/>
            </ModalCategory>
       
            <ModalSubCategory title={"Create Sub Category"} visible={isVisibleModalCreateSubCategory} onCancel={onCancel}>
                <FormSubCategory onCancel={onCancel} btnSubmitName={"Create"} btnCancelName={"Cancel"}/>
            </ModalSubCategory>
            <ModalSubCategory title={"Update Sub Category"} visible={isVisibleModalUpdateSubCategory} onCancel={onCancel}>
                <FormSubCategory onCancel={onCancel} btnSubmitName={"Update"} btnCancelName={"Cancel"}/>
            </ModalSubCategory> */}



            <CategoryTable/>

        </MainLayout>
    )
}

export default withAuth()(CategoryPage)