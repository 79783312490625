import React, { FunctionComponent } from 'react';
import routes from './routes';
import {Route, Switch , useLocation} from "react-router-dom";

interface RouterViewProps {}

const RouterView: FunctionComponent<RouterViewProps> = (props) => {
    const location = useLocation()
    // @ts-ignore
    const background = location.state && location.state.background
    let routeId = 0;
    return (
        <Switch location={background || location}>
            {routes.map((routeProps) => {
                return <Route path={routeProps.path} {...routeProps} key={routeId++} />;
            })}
            <Route>Not Found</Route>
        </Switch>
    );
};

export default RouterView;