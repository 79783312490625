import {Button, Input, message, Popconfirm, Table, TablePaginationConfig, Tag, Tooltip} from "antd"
import {ColumnType} from "antd/es/table"
import React, {useEffect, useState} from "react"
import styled from "styled-components"
import ModalCustom from "../../../components/ModalCustom";
import CreateAdjustmentForm from "../form/CreateAdjustmentForm";
import {AdjustmentResponse, AdminService, ItemStockEnum} from "@cubetiq/computer-web-sdk";
import {getErrorMessage, toUSD} from "../../../util/utils";
import {formatDate} from "../../../util/DateUtils";
import {ReloadButton} from "../../../components/table/BrandTable";
import {DeleteIcon, EditIcon} from "../../../components/icon/IconCustom";
import AdjustmentDetailTable from "./AdjustmentDetailTable";
import UpdateAdjustmentForm from "../form/UpdateAdjustmentForm";
import {QuestionCircleOutlined} from "@ant-design/icons";


const TableCustom = styled(Table)`
  padding: 20px 0px 0px 0px;

  .ant-table-expanded-row {
    .ant-table-cell {
      background: none;
    }

    .ant-table-content {
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
`


const Container = styled.div`

`

const CreateButton = styled(Button)`
  background-color: #00bfff;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  font-family: 'Poppins', sans-serif;
  width: 120px;
  transition: 0.2s;


  &:hover {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }

  &:focus {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.9);
  }

`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const {Search} = Input;

const SearchCustom = styled(Search)`
  width: 500px;
`


interface AdjustmentTableProps {

}

const admin = AdminService.getInstance()
const AdjustmentTable: React.FC<AdjustmentTableProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [adjustments, setAdjustments] = useState<AdjustmentResponse[]>([])
    const [selectedAdjustment, setSelectedAdjustment] = useState<AdjustmentResponse>()
    const [isEdit, setIsEdit] = useState(false)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)

    const query = admin.getAllAdjustmentService

    const onIsCreateHandle = () => {
        setIsCreate(!isCreate)
    }

    const onIsEditHandle = () => {
        setIsEdit(!isEdit)
    }

    const onEditHandleClick = (value: AdjustmentResponse) => {
        setSelectedAdjustment(value)
        onIsEditHandle()
    }

    const columns: Array<ColumnType<any>> = [
        {
            title: 'Adjustment Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: "center",
            key: 'date',
            width: 200,
            render: value => {
                return (
                    <React.Fragment>
                        {
                            formatDate(value)
                        }
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Total Amount',
            dataIndex: 'amount',
            align: "center",
            key: 'amount',
            width: 200,
            render: value => {
                return (
                    <React.Fragment>
                        {
                            toUSD.format(value)
                        }
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Memo',
            dataIndex: 'memo',
            align: "center",
            key: 'memo'
        },
        {
            title: 'Stock Type',
            dataIndex: 'stockType',
            align: "center",
            key: 'stockType',
            width: 150,
            render: value => {
                return (
                    <React.Fragment>
                        {
                            value === ItemStockEnum.In
                                ?
                                <Tag color={"success"} children={value} style={{minWidth: 50}}/>
                                :
                                <Tag color={"error"} children={value} style={{minWidth: 50}}/>
                        }
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: "center",
            key: 'action',
            width: 200,
            render: (value, row) => {
                return (
                    <React.Fragment>
                        <Tooltip placement="top" title={"Edit Adjustment"}>
                            <EditIcon style={{marginRight: 20}} onClick={() => onEditHandleClick(row)}/>
                        </Tooltip>

                        <Tooltip placement="top" title={"Delete Adjustment"}>

                            <Popconfirm title="Are you sure to delete this adjustment？"
                                        onConfirm={() => onRemoveAdjustment(row)}
                                        icon={<QuestionCircleOutlined
                                            style={{color: 'red', fontSize: 16}}/>}>
                                <DeleteIcon/>
                            </Popconfirm>
                        </Tooltip>
                    </React.Fragment>
                )
            }
        }
    ]


    const onRemoveAdjustment = (value: AdjustmentResponse) => {
        admin.deleteAdjustmentService(value.code).then(r => {
            const curr = adjustments.filter(it => it.id !== value.id)
            setAdjustments([...curr])
            message.success("Remove Adjustment Successfully")
        }).catch((r) => message.error(getErrorMessage(r)))
    }


    const onLoadData = () => {
        setIsLoading(true)
        admin.getAllAdjustmentService().then(r => {
            setAdjustments(r.data)
            setTotalCount(r.pages.totalCount)
        }).catch((r) => message.error(getErrorMessage(r)))
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        onLoadData()
    }, [])


    const CreateAdjustmentModal = () => {
        return <ModalCustom onCancel={onIsCreateHandle}
                            children={<CreateAdjustmentForm/>}
                            footer={null}
                            width={1000}
                            visible={isCreate}/>
    }

    const UpdateAdjustmentModal = () => {
        if (selectedAdjustment === undefined) return <></>
        return (
            <ModalCustom visible={isEdit}
                         footer={null}
                         width={1000}
                         children={<UpdateAdjustmentForm adjustment={selectedAdjustment}/>}
                         onCancel={onIsEditHandle}/>
        )
    }


    const keyChange = (key: any): string => {
        return key.id
    }

    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        setPageSize(pagination.pageSize)
    }


    const paginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            setIsLoading(true)
            query({
                page: (v - 1),
                size: pageSize,
                q: searchValue
            }).then(r => {
                setAdjustments(r.data)
                setTotalCount(r.pages.totalCount)
            }).catch(r => {
                message.error(getErrorMessage(r))
            }).finally(() => setIsLoading(false))
        },
        total: totalCount,
        pageSize: pageSize
    }

    const onSearch = (v: any) => {
        setIsLoading(true)
        setSearchValue(v)
        query({
            q: v.toString()
        }).then((r: any) => {
            setAdjustments(r.data)
        }).finally(() => setIsLoading(false))
    }


    return (
        <Container>
            <CreateAdjustmentModal/>
            <UpdateAdjustmentModal/>
            <HeaderContainer>
                <HeaderButtonWrapper>
                    <CreateButton children={"Create"} onClick={onIsCreateHandle}/>
                    <ReloadButton children={"Reload"} onClick={() => {
                        setSearchValue(undefined)
                        onLoadData()
                    }}/>
                </HeaderButtonWrapper>
                <div>
                    <SearchCustom
                        placeholder="Input adjustment code or memo"
                        allowClear
                        enterButton="Search"
                        onSearch={onSearch}
                    />
                </div>
            </HeaderContainer>
            <TableCustom bordered
                         loading={isLoading}
                         columns={columns}
                         onChange={onChange}
                         pagination={paginationConfig}
                         rowKey={(value) => keyChange(value)}
                         expandable={{
                             expandedRowRender: (row: any, index: number) => (
                                 <AdjustmentDetailTable adjustment={row} key={index}/>
                             ),
                         }}
                         dataSource={adjustments}
            />
        </Container>
    )
}

export default AdjustmentTable