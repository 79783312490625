import React,{ FunctionComponent } from "react";
import styled from "styled-components";
import LocationTable from "../../components/table/LocationTable";
import MainLayout from "../MainLayout";


const Container = styled.div`
    
`


const LocationPage:FunctionComponent = () => {

    return(
        <MainLayout>
            <LocationTable/>
        </MainLayout>
    )

}

export default LocationPage