"use strict";
exports.__esModule = true;
exports.NotificationEnumStatus = void 0;
var NotificationEnumStatus;
(function (NotificationEnumStatus) {
    NotificationEnumStatus["Warning"] = "Warning";
    NotificationEnumStatus["Error"] = "Error";
    NotificationEnumStatus["Alert"] = "Alert";
    NotificationEnumStatus["Processing"] = "Processing";
    NotificationEnumStatus["Success"] = "Success";
})(NotificationEnumStatus = exports.NotificationEnumStatus || (exports.NotificationEnumStatus = {}));
