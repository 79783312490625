import {QuestionCircleOutlined} from "@ant-design/icons";

import {Col, Input, message, Popconfirm, Row, Table, TablePaginationConfig} from "antd";
import {ColumnType} from "antd/es/table";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useHistory} from "react-router";
import styled from "styled-components";
import {AddIconCustom, CreateButton} from "../../pages/import";
import {ROUTE_URI_ENUM} from "../../routes/paths";
import {getErrorMessage, onErrorImageFallback, pasreParamaterUrl} from "../../util/utils";
import ItemDescription from "../description/ItemDescription";
import {DeleteIcon, EditIcon} from "../icon/IconCustom";
import {ReloadButton} from "./BrandTable";
import {ReloadIcon} from "./ImportTable";
import {ClientService, ItemResponse, ServerResponse} from "@cubetiq/computer-web-sdk";

const TableCustom = styled(Table)`
  padding: 20px 0 0 0;
`;

const Container = styled.div``;

const AvatarCustom = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

const Value = styled.div`
  & table {
    width: 100% !important;
  }
`;


const {Search} = Input;

const SearchCustom = styled(Search)`
  width: 400px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const client = ClientService.getInstance();
const ItemTable: FunctionComponent = () => {
    const [items, setItems] = useState<Array<ItemResponse>>();
    const [totalCount, setTotalCount] = useState(0);
    const history = useHistory();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
    const [isFirstRender, setIsFirstRender] = useState(true)

    const query = client.adminGetAllItemService

    useEffect(() => {
        onLoadData();
        setIsFirstRender(false)
    }, []);

    const onLoadData = () => {
        query({
            size: pageSize,
        })
            .then((r: ServerResponse<ItemResponse[]>) => {
                setItems(r.data);
                setTotalCount(r.pages.totalCount);
            });
    };

    const columns: Array<ColumnType<any>> = [
        {
            title: "Item Code",
            dataIndex: "code",
            align: "center",
            key: "code",
            width: 200,
        },
        {
            title: "Product Cover",
            dataIndex: "attachments",
            align: "center",
            key: "code",
            width: 200,
            render: (v) => {
                return <AvatarCustom src={v?.[0]?.originalLink} onError={onErrorImageFallback}/>;
            },
        },
        {
            title: "Product Name",
            dataIndex: "name",
            align: "center",
            key: "name",
            width: 200,
        },
        {
            title: "View",
            dataIndex: "view",
            align: "center",
            key: "view",
            width: 150,
        },
        {
            title: "Actions",
            dataIndex: "action",
            align: "center",
            key: "action",
            width: 30,
            render: (value, row: any) => {
                return (
                    <IconContainer>
                        <EditIcon
                            onClick={() => {
                                const url = pasreParamaterUrl(
                                    ROUTE_URI_ENUM.UPDATE_ITEM,
                                    row.code
                                );
                                history.push(url);
                            }}
                        />
                        <Popconfirm
                            title={`Are you sure to delete ( ${row.name} ) ? `}
                            onConfirm={() => onRemoveItem(row)}
                            icon={
                                <QuestionCircleOutlined
                                    style={{color: "red", fontSize: 16}}
                                />
                            }
                        >
                            <DeleteIcon style={{marginLeft: 20}}/>
                        </Popconfirm>
                    </IconContainer>
                );
            },
        },
    ];

    const onRemoveItem = (item: ItemResponse) => {
        client
            .deleteItemService(item.code)
            .then((r: ServerResponse<ItemResponse>) => {
                const data = items?.filter((it) => it.code !== item.code);
                setItems([...(data ?? [])]);
                message.success("Delete Item Successfully !");
            })
            .catch((r: any) => {
                message.error(getErrorMessage(r));
            });
    };

    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        setPageSize(pagination["pageSize"]);
        setCurrentPage(pagination["current"])
    }

    const PaginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            query({
                page: v - 1,
                size: pageSize,
                q: searchValue
            })
                .then((r: ServerResponse<ItemResponse[]>) => {
                    setItems(r.data);
                    setTotalCount(r.pages.totalCount);
                });
        },
        total: totalCount,
        pageSize: pageSize,
        current: currentPage
    };

    const keyChange = (key: any): string => {
        return key.id;
    };

    const onSearch = (value: string) => {
        if (value.trim().length > 0) {
            setSearchValue(value)
            query({
                q: value,
                size: pageSize,
            })
                .then((r: ServerResponse<ItemResponse[]>) => {
                    setItems(r.data);
                    setTotalCount(r.pages.totalCount);
                    setCurrentPage(1)
                });
        } else {
            setSearchValue(undefined)
        }
    };


    useEffect(() => {
        if (isFirstRender) return
        if (searchValue === undefined) {
            query({
                size: pageSize
            }).then((r: ServerResponse<ItemResponse[]>) => {
                setItems(r.data)
                setTotalCount(r.pages.totalCount);
                setCurrentPage(1)
            })
        }
    }, [searchValue])

    const router = useHistory();

    return (
        <Container>
            <Row>
                <Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <CreateButton
                        icon={<AddIconCustom/>}
                        onClick={() => {
                            router.push(ROUTE_URI_ENUM.CREATE_ITEM);
                        }}
                        children={"Create"}
                    />

                    <ReloadButton
                        icon={<ReloadIcon/>}
                        onClick={onLoadData}
                        style={{marginLeft: 20}}
                        children={"Reload"}
                    />
                </Col>
                <Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <SearchCustom
                        placeholder="Please input item code or item name"
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </Col>
            </Row>

            <TableCustom
                columns={columns}
                bordered
                onChange={onChange}
                loading={items === undefined}
                pagination={PaginationConfig}
                rowKey={(value) => keyChange(value)}
                expandable={{
                    expandedRowRender: (row: any, index: number) => {

                        console.log(row)

                        return (
                            <Value>
                                <ItemDescription item={row} key={index}/>
                            </Value>
                        )
                    },
                }}
                dataSource={items}
            />
        </Container>
    );
};

export default ItemTable;
