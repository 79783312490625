"use strict";
exports.__esModule = true;
exports.BACK_MEASUREMENT = exports.BACK_STOCK = exports.BACK_LOCATION = exports.BACK_VISITOR = exports.BACK_SLIDER = exports.BACK_ITEM_SPECIFICATION = exports.BACK_SPECIFICATION = exports.BACK_ORDER_DETAIL_UPDATE = exports.BACK_USER = exports.BACK_ORDER = exports.BACK_SUPPLIER = exports.BACK_SUB_CATEGORY = exports.BACK_IMPORT = exports.BACK_ITEM = exports.FRONT_CHANGE_PASSWORD = exports.FRONT_AUTH_PROFILE = exports.FRONT_RESET_CHANGE_PASSWORD = exports.FRONT_VERIFY_RESET = exports.FRONT_SEND_RESET_CODE = exports.FRONT_AVATAR = exports.FRONT_VERIFY_CODE = exports.FRONT_AUTH_FETCH_ME = exports.FRONT_SEND_VERIFY_CODE = exports.FRONT_AUTH_LOGIN = exports.FRONT_AUTH_REGISTER = exports.FRONT_PAYMENT_METHOD = exports.FRONT_MULTI_UPLOAD = exports.FRONT_OTHER_UPLOAD = exports.FRONT_UPLOAD = exports.FRONT_ORDER = exports.FRONT_CART = exports.FRONT_LOCATION = exports.FRONT_SLIDER = exports.FRONT_FAVORITE = exports.FRONT_GET_ALL_SPECIFICATION = exports.FRONT_GET_ALL_BRAND = exports.FRONT_GET_ALL_CATEGORY = exports.FRONT_GET_ALL_ITEMS_POPULAR = exports.FRONT_GET_ITEM_FILTER_RECENT = exports.FRONT_GET_ITEM_FILTER = exports.FRONT_GET_MIN_MAX_PRICE = exports.FRONT_GET_ALL_ITEMS = exports.GET_FAVORITE = exports.URI_PROFILE = exports.GET_SLIDER = exports.GET_ALL_BRAND = exports.GET_ALL_PRODUCT = exports.FETCH_ME = exports.AUTH_LOGIN = exports.API_SERVER_ORIGIN = void 0;
exports.BACK_MODEL = exports.BACK_BRAND = exports.BACK_CATEGORY = exports.BACK_AUTH_LOGIN = exports.BACK_SERIAL = exports.BACK_REPORT = exports.BACK_ADJUSTMENT = exports.BACK_TRANSLATION = exports.BACK_PAYMENT_METHOD = void 0;
var url_1 = require("../config/url");
exports.API_SERVER_ORIGIN = url_1.BASE_URL;
exports.AUTH_LOGIN = '/auth/login';
exports.FETCH_ME = '/profile/me';
exports.GET_ALL_PRODUCT = '/public/items';
exports.GET_ALL_BRAND = '/brands';
exports.GET_SLIDER = '/sliders';
exports.URI_PROFILE = '/profile';
exports.GET_FAVORITE = "".concat(exports.URI_PROFILE, "/favourites");
var FrontendIndex = '/api/frontend';
exports.FRONT_GET_ALL_ITEMS = "".concat(FrontendIndex, "/items");
exports.FRONT_GET_MIN_MAX_PRICE = "".concat(FrontendIndex, "/items/min-max/price");
exports.FRONT_GET_ITEM_FILTER = "".concat(FrontendIndex, "/items/filter");
exports.FRONT_GET_ITEM_FILTER_RECENT = "".concat(FrontendIndex, "/items/filter/recent");
exports.FRONT_GET_ALL_ITEMS_POPULAR = "".concat(FrontendIndex, "/items/popular");
exports.FRONT_GET_ALL_CATEGORY = "".concat(FrontendIndex, "/categories");
exports.FRONT_GET_ALL_BRAND = "".concat(FrontendIndex, "/brands");
exports.FRONT_GET_ALL_SPECIFICATION = "".concat(FrontendIndex, "/specification");
exports.FRONT_FAVORITE = "".concat(FrontendIndex, "/favorite");
exports.FRONT_SLIDER = "".concat(FrontendIndex, "/slider");
exports.FRONT_LOCATION = "".concat(FrontendIndex, "/location");
exports.FRONT_CART = "".concat(FrontendIndex, "/cart");
exports.FRONT_ORDER = "".concat(FrontendIndex, "/order");
exports.FRONT_UPLOAD = "/files";
exports.FRONT_OTHER_UPLOAD = "/files/other";
exports.FRONT_MULTI_UPLOAD = "/files/batch";
exports.FRONT_PAYMENT_METHOD = "".concat(FrontendIndex, "/payment-method");
// Authentication
exports.FRONT_AUTH_REGISTER = "/auth/register";
exports.FRONT_AUTH_LOGIN = "/auth/login";
exports.FRONT_SEND_VERIFY_CODE = "/auth/send-verify";
exports.FRONT_AUTH_FETCH_ME = "auth/me";
exports.FRONT_VERIFY_CODE = "/auth/verify";
exports.FRONT_AVATAR = "/auth/avatar";
exports.FRONT_SEND_RESET_CODE = "/auth/reset/send";
exports.FRONT_VERIFY_RESET = "/auth/reset";
exports.FRONT_RESET_CHANGE_PASSWORD = "/auth/reset/change-password";
exports.FRONT_AUTH_PROFILE = "/auth/profile";
exports.FRONT_CHANGE_PASSWORD = "/auth/change-password";
var BackendIndex = '/api/backend';
exports.BACK_ITEM = "".concat(BackendIndex, "/items");
exports.BACK_IMPORT = "".concat(BackendIndex, "/import");
exports.BACK_SUB_CATEGORY = "".concat(BackendIndex, "/sub-category");
exports.BACK_SUPPLIER = "".concat(BackendIndex, "/supplier");
exports.BACK_ORDER = "".concat(BackendIndex, "/order");
exports.BACK_USER = "".concat(BackendIndex, "/user");
exports.BACK_ORDER_DETAIL_UPDATE = "".concat(exports.BACK_ORDER, "/detail");
exports.BACK_SPECIFICATION = "".concat(BackendIndex, "/specification");
exports.BACK_ITEM_SPECIFICATION = "".concat(BackendIndex, "/item-specification");
exports.BACK_SLIDER = "".concat(BackendIndex, "/slider");
exports.BACK_VISITOR = "".concat(BackendIndex, "/visitor");
exports.BACK_LOCATION = "".concat(BackendIndex, "/location");
exports.BACK_STOCK = "".concat(BackendIndex, "/stock");
exports.BACK_MEASUREMENT = "".concat(BackendIndex, "/measurement");
exports.BACK_PAYMENT_METHOD = "".concat(BackendIndex, "/payment-method");
exports.BACK_TRANSLATION = "".concat(BackendIndex, "/translation");
exports.BACK_ADJUSTMENT = "".concat(BackendIndex, "/adjustment");
exports.BACK_REPORT = "".concat(BackendIndex, "/report");
exports.BACK_SERIAL = "".concat(BackendIndex, "/serial");
// Authentication
exports.BACK_AUTH_LOGIN = "".concat(BackendIndex, "/auth/login");
// Category
exports.BACK_CATEGORY = "".concat(BackendIndex, "/category");
// Brand
exports.BACK_BRAND = "".concat(BackendIndex, "/brand");
// Model
exports.BACK_MODEL = "".concat(BackendIndex, "/model");
