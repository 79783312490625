import { AuthUserResetChangePassword, AuthUserResponse, ClientService } from "@cubetiq/computer-web-sdk";
import { Button, message } from "antd";
import { Form, Input } from "antd";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { CreateButton } from "../../pages/import";
import { getErrorMessage } from "../../util/utils";


const Container = styled.div`

`

interface UserResetPasswordFormProps {
    user: AuthUserResponse
}


const ProfileContainer = styled.div`
    
`

const AvatarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`

const AvatarCustom = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
`

const ProfileNameContainer = styled.div`

`

const ProfileName = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
`


const client = ClientService.getInstance()
const UserResetPasswordForm: FunctionComponent<UserResetPasswordFormProps> = (props) => {
    const { user } = props


    const onFinish = (values: any) => {
        const request: AuthUserResetChangePassword = {
            ...values
        }
        client.resetChangePasswordUser(user.code,request).then(r => {
            message.success("Reset Change Password Succcessfully !")
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }


    


    return (
        <Container>

            <ProfileContainer>
                <AvatarContainer>
                    <AvatarCustom src={user.profile?.avatar} />
                </AvatarContainer>
                <ProfileContainer>
                    <ProfileName>
                        {user.profile?.name}
                    </ProfileName>
                </ProfileContainer>
            </ProfileContainer>


            <Form onFinish={onFinish}
                layout={"vertical"}>


                <Form.Item label={"New Password"}
                            hasFeedback
                            name={"password"}
                            rules={[
                                {
                                    min: 8, message: "New Password must be greater than 8 chracters"
                                },
                                {
                                    required: true, message: "Please input new password !"
                                }
                            ]}
                >
                        <Input.Password/>
                </Form.Item>

                <Form.Item label={"Confirm Password"}
                            hasFeedback
                            name={"confirmPassword"}
                            required
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                })
                            ]}>
                        <Input.Password/>
                </Form.Item>


                <Form.Item>
                    <CreateButton children={"Save Change"} style={{
                        width: "100%",
                        height: "40px"
                    }} htmlType={"submit"}/>
                </Form.Item>

            </Form>
        </Container>
    )
}

export default UserResetPasswordForm