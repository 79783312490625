import React from 'react'
import {AdjustmentResponse} from "@cubetiq/computer-web-sdk";
import {ColumnType} from "antd/es/table";
import {Table, TableProps} from "antd";
import styled from "styled-components";
import {toUSD} from "../../../util/utils";


const TableCustom = styled(Table)`
   padding: 20px 0 0 0;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`

const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`





interface AdjustmentDetailTableProps extends TableProps<any>{
    adjustment: AdjustmentResponse
}



const AdjustmentDetailTable:React.FC<AdjustmentDetailTableProps> = (props) => {


    const { adjustment, ...rest } = props

    const columns: Array<ColumnType<any>> = [
        {
            title: 'Item Code',
            dataIndex: 'item',
            align: "center",
            key: 'itemCode',
            width: 200,
            render: value => value.code
        },
        {
            title: 'Image',
            dataIndex: 'item',
            align: "center",
            key: 'image',
            width: 150,
            render: value => <ImageCustom src={value.attachments?.[0]?.originalLink}/>
        },
        {
            title: 'Product Name',
            dataIndex: 'item',
            align: "center",
            key: 'productName',
            render: value => value.name
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            align: "center",
            key: 'qty',
            width: 100,
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            align: "center",
            key: 'cost',
            width: 200,
            render: value => toUSD.format(value)
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            align: "center",
            key: 'amount',
            width: 200,
            render: value => toUSD.format(value)
        },
    ]


    return (
       <div>
           <TableCustom
               bordered
               columns={columns}
               pagination={false}
               dataSource={adjustment.details}
               loading={false}
               {...rest}/>
       </div>
    )
}

export default AdjustmentDetailTable