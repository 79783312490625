import {RouteProps} from 'react-router';
import HomePage from '../pages/index';
import {ROUTE_URI_ENUM} from './paths';
import {AUTHORITY_GROUPS, AUTHORITY_TYPES} from "../enum/AUTHORITY_TYPES";
import CategoryPage from "../pages/categories";
import LoginPage from "../pages/login";
import TestTwoPage from "../test/test_two";
import BrandPage from "../pages/brands";
import ItemPage from "../pages/items";
import CreateItemPage from '../pages/items/CreateItemPage';
import ImportPage from "../pages/import";
import OrderPage from "../pages/order";
import StockPage from '../pages/stock';
import SpecificationPage from '../pages/specification';
import UserPage from '../pages/user';
import UpdateItemPage from '../pages/items/UpdateItemPage';
import VisitorPage from '../pages/visitor';
import SliderPage from '../pages/slider';
import SupplierPage from '../pages/supplier';
import LocationPage from '../pages/location';
import ResetPage from '../pages/reset/ResetPage';
import AdjustmentPage from "../pages/adjustment";
import ReportPage from "../pages/report";

export interface RoutePropsWithAuthority extends RouteProps {
    authorities?: Array<string> | string,
}

const routes: Array<RoutePropsWithAuthority> = [
    {
        path: ROUTE_URI_ENUM.DEFAULT,
        exact: true,
        component: OrderPage,
        authorities: AUTHORITY_TYPES.VIEW_ORDER,
    },
    {
        path: ROUTE_URI_ENUM.HOME,
        component: HomePage,
        authorities: AUTHORITY_TYPES.VIEW_HOME,
    },
    {
        path: ROUTE_URI_ENUM.RESET,
        component: ResetPage,
        exact: true,
        authorities: [],
    },
    {
        path: ROUTE_URI_ENUM.ITEM,
        component: ItemPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_ITEM,
    },
    {
        path: ROUTE_URI_ENUM.LOCATION,
        component: LocationPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_LOCATION,
    },
    {
        path: ROUTE_URI_ENUM.SUPPLIER,
        component: SupplierPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_SUPPLIER,
    },
    {
        path: ROUTE_URI_ENUM.ADJUSTMENT,
        component: AdjustmentPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_ADJUSTMENT,
    },
    {
        path: ROUTE_URI_ENUM.REPORT,
        component: ReportPage,
        exact: true,
        authorities: [],
    },
    {
        path: ROUTE_URI_ENUM.USER,
        component: UserPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_USER,
    },
    {
        path: ROUTE_URI_ENUM.CREATE_ITEM,
        component: CreateItemPage,
        exact: true,
        authorities: AUTHORITY_TYPES.CREATE_ITEM,
    },
    {
        path: ROUTE_URI_ENUM.UPDATE_ITEM,
        component: UpdateItemPage,
        exact: true,
        authorities: AUTHORITY_TYPES.UPDATE_ITEM,
    },
    {
        path: ROUTE_URI_ENUM.CATEGORY,
        component: CategoryPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_CATEGORY,
    },
    {
        path: ROUTE_URI_ENUM.BRAND,
        component: BrandPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_BRAND,
    },
    {
        path: ROUTE_URI_ENUM.SPECIFICATION,
        component: SpecificationPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_SPECIFICATION,
    },
    {
        path: ROUTE_URI_ENUM.IMPORT,
        component: ImportPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_ORDER,
    },
    {
        path: ROUTE_URI_ENUM.STOCK,
        component: StockPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_STOCK,
    },
    {
        path: ROUTE_URI_ENUM.ORDER,
        component: OrderPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_IMPORT,
    },
    {
        path: ROUTE_URI_ENUM.VISITOR,
        component: VisitorPage,
        exact: true,
        authorities: AUTHORITY_GROUPS.VISITOR,
    },
    {
        path: ROUTE_URI_ENUM.SLIDER,
        component: SliderPage,
        exact: true,
        authorities: AUTHORITY_GROUPS.SLIDER,
    },
    {
        path: ROUTE_URI_ENUM.LOGIN,
        component: LoginPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_LOGIN,
    },
    {
        path: ROUTE_URI_ENUM.TEST,
        component: TestTwoPage,
        exact: true,
        authorities: AUTHORITY_TYPES.VIEW_LOGIN,
    }
];

export default routes;