import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons"
import { AuthUserResponse, BrandResponse, ClientService, VisitorResponse } from "@cubetiq/computer-web-sdk"
import { Avatar, TablePaginationConfig } from "antd"
import { Button, message, Switch } from "antd"
import { Spin, Table, Tooltip } from "antd"
import { ColumnType } from "antd/es/table"
import React, { FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"
import CreateBrandForm from "../form/CreateBrandForm"
import CreateModelForm from "../form/CreateModelForm"
import UpdateBrandForm from "../form/UpdateBrandForm"
import ModalCustom from "../ModalCustom"
import { ReloadButton } from "./BrandTable"
import { ReloadIcon } from "./ImportTable"
import ModelTable from "./ModelTable"



const TableCustom = styled(Table)`
   padding: 20px 0px 0px 0px;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`


const Container = styled.div`

`


const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`


const PlusIcon = styled(PlusCircleFilled)`
    font-size: 24px;
    color: #19aa8d;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`



const CreateButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 120px;
    &:hover {
        background-color: #00bfff;
        color: white;
        opacity: 0.8;
    }
    &:active {
        background-color: black;
        color: white;
        opacity: 0.8;
    }
`


const EditIcon = styled(EditFilled)`
    font-size: 24px;
    color: #00bfff;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`


const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`



const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

`


const VisitorCount = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
`


const client = ClientService.getInstance()
const VisitorTable: FunctionComponent = () => {


    const [isLoading, setIsLoaindg] = useState(false)
    const [visitors, setVisitors] = useState<Array<VisitorResponse>>()
    const [totalCount, setTotalCount] = useState(0)

    useEffect(() => {
        LoadData()
    }, [])

    const LoadData = () => {
        setIsLoaindg(true)
        client.getAllVisitorService({
            size: 10
        }).then(r => {
            setVisitors(r.data)
            setTotalCount(r.pages.totalCount)
        }).finally(() => setIsLoaindg(false))
    }

    const columns: Array<ColumnType<any>> = [
        {
            title: 'Visitor Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            align: "center",
            key: 'createdDate',
            width: 200,
            render: v => {
                return (
                    <React.Fragment>
                        {new Date(v).toDateString()}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'IP Address',
            dataIndex: 'ipAddress',
            align: "center",
            key: 'ipAddress',
            width: 200,
        },
        {
            title: 'User Access',
            dataIndex: 'userAccess',
            align: "center",
            key: 'userAccess',
            width: 200,
        }
    ]




    const keyChange = (key: any): string => {
        return key.id
    }


    const PaginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            client.getAllVisitorService({
                page: (v - 1),
                size: 10
            }).then(r => {
                setVisitors(r.data)
                setTotalCount(r.pages.totalCount)
            })
        },
        total: totalCount,
    }

    return (
        <Container>
            <ButtonContainer>
                <ReloadButton icon={<ReloadIcon />} children={"Reload"} onClick={LoadData} style={{ marginLeft: 0 }} />
                <VisitorCount>
                    Count: {totalCount}
                </VisitorCount>
            </ButtonContainer>
            {
                <TableCustom columns={columns}
                    rowKey={(value) => keyChange(value)}
                    bordered
                    loading={isLoading}
                    pagination={PaginationConfig}
                    dataSource={visitors} />
            }
        </Container>
    )
}

export default VisitorTable