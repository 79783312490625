"use strict";
exports.__esModule = true;
exports.OrderSortStatus = exports.OrderStatus = void 0;
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["PENDING"] = "PENDING";
    OrderStatus["APPROVE"] = "APPROVE";
    OrderStatus["CANCEL"] = "CANCEL";
    OrderStatus["DONE"] = "DONE";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
var OrderSortStatus;
(function (OrderSortStatus) {
    OrderSortStatus["ALL"] = "ALL";
    OrderSortStatus["PENDING"] = "PENDING";
    OrderSortStatus["APPROVE"] = "APPROVE";
    OrderSortStatus["CANCEL"] = "CANCEL";
    OrderSortStatus["DONE"] = "DONE";
})(OrderSortStatus = exports.OrderSortStatus || (exports.OrderSortStatus = {}));
