import React, {FunctionComponent} from "react";
import {Modal, ModalProps} from "antd";


interface ModalCustomProps extends ModalProps{

}


const ModalCustom:FunctionComponent<ModalCustomProps> = (props) => {
    return (
       <Modal {...props}/>
    )
}

export default ModalCustom