import {EditFilled} from "@ant-design/icons";
import {DiscountType, ItemResponse, Specification} from "@cubetiq/computer-web-sdk";
import {Badge, Col, Descriptions, Row, Switch} from "antd";
import React, {FunctionComponent, useState} from "react";
import styled from "styled-components";
import {renderHTML} from "../../util/ComponentUtils";
import {toUSD} from "../../util/utils";
import UpdateItemSpecificationForm from "../form/UpdateItemSpecificationForm";
import ModalCustom from "../ModalCustom";
import {dateFnsFormat} from "../../util/DateUtils";

interface ItemDescriptionProps {
    item: ItemResponse;
}

const SpecificationContainer = styled.div``;

const SpecificationTitle = styled.span`
  font-weight: bold;
`;

const SpecificationValue = styled.span``;

const Style = styled.div`
  .ant-descriptions-bordered .ant-descriptions-item-label {
    border-bottom: 1px solid #f0f0f0;
    font-weight: bold;
  }

  .ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    border-bottom: 1px solid #f0f0f0;
  }

  .tr.ant-table-expanded-row .ant-descriptions-view table {
    width: 100%;
  }
`;

const EditIcon = styled(EditFilled)`
  font-size: 24px;
  color: #00bfff;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const ItemDescription: FunctionComponent<ItemDescriptionProps> = (props) => {
    const {item} = props;
    const [isUpdateSpecification, setIsUpdateSpecification] = useState(false);

    const onIsUpdateSpecificationHandle = () => {
        setIsUpdateSpecification(!isUpdateSpecification);
    };

    const UpdateSpecificationModal = () => {
        return (
            <ModalCustom
                visible={isUpdateSpecification}
                footer={null}
                width={700}
                onCancel={onIsUpdateSpecificationHandle}
                children={<UpdateItemSpecificationForm item={item}/>}
            />
        );
    };



    return (
        <Style>
            <UpdateSpecificationModal/>
            <Descriptions bordered column={4}>
                <Descriptions.Item label="Category">
                    {item.category.name ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Sub Category">
                    {item.subCategory.name ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Brand">{item.brand?.name ?? "N/A"}</Descriptions.Item>
                <Descriptions.Item label="Model">{item.model?.name ?? "N/A"}</Descriptions.Item>
                <Descriptions.Item label="Post Date: ">
                    {dateFnsFormat(item.createdDate)}
                </Descriptions.Item>

                <Descriptions.Item label="Sale Price">
                    {toUSD.format(Number(item.price))}
                </Descriptions.Item>

                <Descriptions.Item label="Cost">
                    {toUSD.format(Number(item.cost))}
                </Descriptions.Item>

                <Descriptions.Item label="Discount">
                    {
                        item.discount?.discountValue
                    }
                    {
                        item.discount?.discountType === DiscountType.PERCENTAGE ? "%" : "$"
                    }
                </Descriptions.Item>
                <Descriptions.Item label="In Stock" span={2}>
                    <Badge
                        status="processing"
                        text="Available In Stock "
                        style={{marginRight: 10}}
                    />
                    ({item.stockQty})
                </Descriptions.Item>

                <Descriptions.Item label="In Stock Hold">
                    <Badge
                        status="warning"
                        text="Available In Stock Hold "
                        style={{marginRight: 10}}
                    />
                    ({item.stockHold})
                </Descriptions.Item>

                <Descriptions.Item label="Product Used">
                    {item.productUsed || ""}
                </Descriptions.Item>

                <Descriptions.Item label="Show Unavailable">
                    <Switch checked={item.isShowUnavailable} defaultChecked={item.isShowUnavailable}/>
                </Descriptions.Item>

                <Descriptions.Item label="Specification" span={4}>
                    <Row>
                        <Col span={23}>
                            {item.specifications.map((it: Specification, index:number) => {
                                return (
                                    <SpecificationContainer key={index}>
                                        <SpecificationTitle children={it.name + " : "}/>
                                        <SpecificationValue children={it.value}/>
                                    </SpecificationContainer>
                                );
                            })}
                        </Col>
                        <Col span={1}>
                            <EditIcon onClick={onIsUpdateSpecificationHandle}/>
                        </Col>
                    </Row>
                </Descriptions.Item>

                <Descriptions.Item
                    label="Description"
                    span={4}
                >
                    {renderHTML(item.memo)}
                </Descriptions.Item>

                <Descriptions.Item
                    label="Visited View"
                    span={4}
                >
                    {item.view}
                </Descriptions.Item>
            </Descriptions>
        </Style>
    );
};
export default ItemDescription;
