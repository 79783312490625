import { CloudUploadOutlined } from '@ant-design/icons'
import { ClientService, SliderRequest, SliderResponse } from '@cubetiq/computer-web-sdk'
import { Divider } from 'antd'
import { message } from 'antd'
import { Button, Col, Row } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import ImageCard from '../../card/ImageCard'
import ImageCardLink from '../../card/ImageCard copy'
import { AddIconCustom } from '../../pages/import'
import { SelectMultipleFileUtils } from '../../util/Logging'
import { getErrorMessage } from '../../util/utils'
import CreateSilderForm from '../form/CreateSliderForm'
import ModalCustom from '../ModalCustom'
import { ReloadButton } from '../table/BrandTable'
import { ReloadIcon } from '../table/ImportTable'
import FileSelectWithPreview from './FileSelectWithPreview'



const Container = styled.div`
    width: 100%;
`

const Contain = styled.div`
    min-height: 400px;
    margin-top: 20px;
    border-radius: 4px;
    padding-bottom: 20px;
    background-color: #808080;
`

const ButtonUploadCustom = styled(Button)`
    text-align: center;
    background-color: #00bfff;
    font-family: 'Poppins', sans-serif;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;

`


interface UploadFileWithPreviewProps {
    onChange?: (value: any) => void
}


const client = ClientService.getInstance()
const SliderUploadFileWithPreview: FunctionComponent<UploadFileWithPreviewProps> = (props) => {

    const [files, setFiles] = useState<FileList>();

    const [sliders, setSliders] = useState<Array<SliderResponse>>([])
    const [ads, setAds] = useState<Array<SliderResponse>>([])




    useEffect(() => {
        onLoadData()
    }, [])


    const onLoadData = () => {
        client.getSliderService().then(r => {
            setSliders(r.data)
        })
        client.getSliderService({
            slider: 'false'
        }).then(r => {
            setAds(r.data)
        })
    }

    const onUploadFileHandle = () => {
        SelectMultipleFileUtils(setFiles)
    }


    useEffect(() => {
        if (files === undefined)
            return
        typeof props.onChange === "function" && props.onChange(files)
    }, [files])


    const [isCreate, setIsCreate] = useState(false)
    const onIsHandleCreate = () => {
        setIsCreate(!isCreate)
    }



    const [file, setFile] = useState<File>()




    useEffect(() => {
        if (file === undefined)
            return
        client.otherUploadFileService(file).then(r => {
            const request: SliderRequest = {
                url: r.data.url,
                isSlider: false
            }
            client.createSliderService(request).then(r => {
                message.success("Slider Category Successfully")
                setAds([...ads,r.data])
            }).catch(r => {
                message.error(getErrorMessage(r))
            })
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }, [file])


    return (
        <Container>

            <ModalCustom footer={null}
                children={<CreateSilderForm />}
                onCancel={onIsHandleCreate}
                visible={isCreate} />

            <ButtonUploadCustom icon={<AddIconCustom
                style={{ fontSize: 16 }} />}
                onClick={onIsHandleCreate}
                children={"Create"} />


            <ReloadButton icon={<ReloadIcon
                style={{ fontSize: 16 }} />}
                onClick={onLoadData}
                children={"Reload"} />
            <Contain>
                <Row gutter={[8, 16]}>
                    {
                        sliders.map((it, index) => {
                            return (
                                <Col span={8} style={{ display: "flex", justifyContent: "center" }} key={index}>
                                    <ImageCardLink attachment={it.url}
                                        index={index}
                                        setAttachments={setSliders}
                                        attachments={sliders} />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Contain>




            <Divider orientation="left" style={{ marginTop: 20 }}>Adversities</Divider>

            <Row gutter={[8, 16]}>
                {
                    ads.map((it, index) => {
                        return (
                            <Col span={8} style={{ display: "flex", justifyContent: "center" }} key={index}>
                                <ImageCardLink attachment={it.url}
                                    index={index}
                                    setAttachments={setAds}
                                    attachments={ads} />
                            </Col>
                        )
                    })
                }


                <Col span={8} style={{ display: "flex", justifyContent: "center" }} >
                    <FileSelectWithPreview onChange={setFile}
                        isPreview={false}
                        overrideStyle={{ width: "100%", marginTop: 20 }}
                        style={{ width: "100%", height: "100%" }} />
                </Col>
            </Row>


        </Container>
    )
}

export default SliderUploadFileWithPreview