// @ts-nocheck

import {DeleteFilled} from "@ant-design/icons"
import {
    AdjustmentDetailRequest,
    AdjustmentRequest,
    AdminService,
    ClientService,
    ItemResponse,
    ItemStockEnum
} from "@cubetiq/computer-web-sdk"
import {Button, Col, DatePicker, Divider, Form, Input, InputNumber, message, Row, Spin, Switch} from "antd"
import React, {FunctionComponent, useEffect, useState} from "react"
import styled from "styled-components"
import {getErrorMessage, toUSD} from "../../../util/utils";
import {ItemTotalAmount} from "../../../components/footer/TotalFooter";
import SelectItemCustom from "../../../components/select/SelectItemCustom";
import moment from "moment";


const Container = styled.div`
  margin-top: 20px;
  .ant-input-number-disabled .ant-input-number-input {
    text-align: center;
  }
  .ant-input-number-input {
    text-align: center;
  }
`



const AvatarCustom = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-top: 15px;
`

const DeleteIcon = styled(DeleteFilled)`
  font-size: 24px;
  color: red;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    opacity: 0.5;
  }
`


const ButtonSave = styled(Button)`
  width: 100%;
  color: white;
  height: 51px;
  background-color: #19aa8d;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  margin-top: 10px;


  transition: 0.2s;


  &:hover {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }

  &:focus {
    background-color: #19aa8d;
    color: white;
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.9);
  }
`


const Selector = styled.div`
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
  }
`

const {TextArea} = Input

const client = ClientService.getInstance()
const admin = AdminService.getInstance()
const CreateAdjustmentForm: FunctionComponent = () => {

    const [items, setItems] = useState<Array<ItemResponse>>([])
    const [itemAdjustments, setItemAdjustments] = useState<Array<ItemResponse>>([])
    const [isLoading, setIsLoading] = useState(false)
    const [total, setTotal] = useState<Array<ItemTotalAmount>>([])


    useEffect(() => {
        client.adminGetAllItemService({
            paged: false
        }).then(r => {
            setItems(r.data)
        })
    }, [])


    const onFinish = (values: any) => {

        try {
            setIsLoading(true)
            const d: Array<ItemResponse & { qty: number }> = values["items"]
            const details = d.map(it => {
                return {
                    qty: it.qty,
                    cost: it.cost,
                    itemId: it.id
                } as AdjustmentDetailRequest
            })

            const request: AdjustmentRequest = {
                date: values["date"].unix(),
                memo: values["memo"],
                details: details,
                stockType: values["stockType"] ? ItemStockEnum.Out : ItemStockEnum.In
            }

            admin.createAdjustmentService(request).then(r => {
                message.success("Create adjustment successfully").then()
            }).catch(r => message.error(getErrorMessage(r)))
                .finally(()=> setIsLoading(false))

        } catch (e) {
            setIsLoading(false)
        }

    }

    const onFinishFailed = (errorInfo: any) => {

    }


    const onHandleChange = (v: any) => {
        const item: ItemResponse = JSON.parse(v)
        setItemAdjustments([...itemAdjustments, item])
        return item
    }


    const form = Form.useForm()[0]


    const onItemChange = () => {
        const values = form.getFieldsValue()

        const currentItem: Array<ItemResponse & { qty: number | 1 }> = values["items"]

        const newValue = currentItem.map((it) => {
            return {
                ...it,
                amount: (it.cost ?? 0) * (it.qty ?? 1)
            }
        })

        form.setFieldsValue({
            items: newValue
        })


        const total = newValue.map(it => {
            return {
                name: it.name || 0,
                price: it.price || 0,
                qty: it.qty ?? 1,
                discount: it.discount?.discountValue || 0,
                amount: it.amount,
                cost: it.cost ?? 0
            }
        })

        setTotal([...total])
    }


    const onRemoveItemChange = () => {
        const values = form.getFieldsValue()
        const currentItem: Array<ItemResponse & { qty: number | 1 }> = values["items"]
        const newValue = currentItem.map((it) => {
            return {
                name: it.name || 0,
                price: it.price || 0,
                qty: it.qty ?? 1,
                discount: it.discount?.discountValue || 0,
                amount: (it.price ?? 0) * (it.qty ?? 1)
            }
        })

        const totalItem = newValue.filter(it => it.qty !== 0 && it.price !== 0)

        setTotal([...totalItem])
    }


    const getTotalPrice = () => {
        let sum = 0
        total.forEach(it => {
            sum += (it.qty * (it.cost ?? 0))
        })
        return sum
    }


    return (
        <Container>
            <Form layout={"vertical"}
                  onFinishFailed={onFinishFailed}
                  form={form}
                  onFinish={onFinish}>

                <Row gutter={[10, 10]}>

                    <Col span={15}>
                        <Form.Item name={"memo"} label={"Memo"}>
                            <TextArea placeholder={"Memo"} rows={1}/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name={"date"} label={"Date"} initialValue={moment(Date.now())}>
                            <DatePicker
                                format={"DD-MM-YYYY"}
                                style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>

                    <Col span={3}>
                        <Form.Item name={"stockType"}
                                   initialValue={true}
                                   valuePropName="checked"
                                   label={"Stock Type"}>
                            <Switch checkedChildren="Out" unCheckedChildren="In" defaultChecked />
                        </Form.Item>
                    </Col>

                </Row>

                <Form.List name="items" initialValue={items}>
                    {(fields, {add, remove}) => {
                        return (
                            <>

                                <Row>
                                    <Col span={24}>

                                        <Form.Item label={"Add Items"}>
                                            <Selector>
                                                <SelectItemCustom data={items} onChange={(v) => {
                                                    if (v === null)
                                                        return null
                                                    const it = onHandleChange(v)
                                                    add(it)
                                                    onItemChange()
                                                }}/>
                                            </Selector>
                                        </Form.Item>

                                        <Divider/>
                                    </Col>
                                </Row>


                                {
                                    fields.map((field, index) => {
                                        return (
                                            <div key={field.key}>
                                                <Row gutter={[10, 10]}>

                                                    <Col span={2}>
                                                        <Form.Item name={[field.name, 'attachments']}
                                                                   fieldKey={[field.fieldKey, 'attachments']}>
                                                            <AvatarCustom
                                                                src={itemAdjustments[index].attachments?.[0]?.originalLink}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={11}>
                                                        <Form.Item name={[field.name, 'name']}
                                                                   fieldKey={[field.fieldKey, 'name']}
                                                                   label={"Product Name"}
                                                                   required>
                                                            <Input style={{
                                                                backgroundColor: "white",
                                                                color: "#333"
                                                            }}
                                                                   disabled/>
                                                        </Form.Item>
                                                    </Col>


                                                    <Col span={3}>
                                                        <Form.Item name={[field.name, 'qty']}
                                                                   label={"Quantity"}
                                                                   required
                                                                   initialValue={1}
                                                                   fieldKey={[field.fieldKey, 'qty']}>
                                                            <InputNumber style={{width: "100%"}}
                                                                         onChange={(v) => {
                                                                             onItemChange()
                                                                             return v
                                                                         }}
                                                                         min={1}/>
                                                        </Form.Item>
                                                    </Col>


                                                    <Col span={3}>
                                                        <Form.Item name={[field.name, 'cost']}
                                                                   label={"Cost"}
                                                                   required
                                                                   fieldKey={[field.fieldKey, 'cost']}>
                                                            <InputNumber
                                                                onChange={(v) => {
                                                                    onItemChange()
                                                                    return v
                                                                }}
                                                                disabled
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor: "white",
                                                                    color: "#333"
                                                                }}
                                                                min={0}/>
                                                        </Form.Item>
                                                    </Col>


                                                    <Col span={3}>
                                                        <Form.Item name={[field.name, 'amount']}
                                                                   label={"Amount"}
                                                                   fieldKey={[field.fieldKey, 'amount']}>

                                                            <InputNumber style={{
                                                                width: "100%",
                                                                backgroundColor: "white",
                                                                color: "#333"
                                                            }}
                                                                         disabled
                                                                         min={0}/>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={2} style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        <Form.Item>
                                                            <DeleteIcon onClick={() => {
                                                                remove(field.name)
                                                                const dataDelete = [...itemAdjustments]
                                                                dataDelete.splice(index, 1)
                                                                setItemAdjustments([...dataDelete])
                                                                onRemoveItemChange()
                                                            }}/>
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        )
                    }}
                </Form.List>


                {
                    itemAdjustments.length > 0 &&
                    <Form.Item>
                        <Row>
                            <Col span={24}>
                                <Divider/>
                            </Col>
                            <Col style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                fontSize: 18,
                                color: "#333333"
                            }} span={24}>
                                Total Amount: {toUSD.format(getTotalPrice())}
                            </Col>
                        </Row>
                    </Form.Item>
                }


                {
                    itemAdjustments.length > 0 &&
                    <Form.Item>
                        <Row>
                            <Col span={24}>
                                <Spin spinning={isLoading}>
                                    <ButtonSave children={"Create Adjustment"} htmlType={"submit"}/>
                                </Spin>
                            </Col>
                        </Row>
                    </Form.Item>
                }
            </Form>


        </Container>
    )
}

export default CreateAdjustmentForm