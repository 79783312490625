import React,{ FunctionComponent } from "react";
import { withAuth } from "../../provider/AuthWrapper";
import MainLayout from "../MainLayout";
import OrderTable from "../../components/table/OrderTable";



const OrderPage:FunctionComponent = () => {

    return(
        <MainLayout>
            <OrderTable/>
        </MainLayout>
    )
}

export default withAuth()(OrderPage)