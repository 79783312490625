import React from 'react'
import MainLayout from "../MainLayout";
import AdjustmentTable from "./table/AdjustmentTable";


const AdjustmentPage = () => {
    return(
       <MainLayout>
           <AdjustmentTable/>
       </MainLayout>
    )
}

export  default  AdjustmentPage