import React, {FunctionComponent, useEffect, useState} from "react"
import {logPrint} from "../util/Logging"
import {AuthUser, AuthUserResponse, fetchUserService, getClientToken, ServerResponse} from "@cubetiq/computer-web-sdk";



interface ContextState{
    isLogin?: boolean
    token?: string | null
    isFetching?: boolean
    setIsLogin?: (value: boolean) => void
    fetchUser?: () => Promise<any>
    user?: AuthUserResponse
    FailedComponent?: React.ReactElement
    enableAllowAnonymous?: () => void
}

interface ProviderProps{
    onFailed?: () => void
    onSuccess?: () => void
    fetchService: () => Promise<ServerResponse<AuthUserResponse>>
    FailedComponent?: React.ReactElement
}

const Context = React.createContext<ContextState>({
    token: getClientToken()
})

const AuthProvider: FunctionComponent<ProviderProps> = (props) => {

    const [isLogin, setIsLogin] = useState<boolean | undefined>(undefined)
    const [isFetching, setIsFetching] = useState(false)
    const [user, setUser] = useState<AuthUserResponse | undefined>(undefined)
    const [isAllowAnonymous, setIsAllowAnonymous] = useState(false)
    const [token, setToken] = useState<string | undefined>("")

    useEffect(()=>{
        setToken(getClientToken()?.toString())
    },[])

    const enableAnonymous = () => setIsAllowAnonymous(true)

    const refAllowAnonymous = React.useRef({})
    refAllowAnonymous.current = isAllowAnonymous

    
    const fetchUser = () => {
        setIsFetching(true)
        return props.fetchService()
            .then((response)=>{
                setUser(response.data)
                setIsLogin(true)
                const isAllowAnonymousRef = refAllowAnonymous.current
                if(isAllowAnonymousRef === false){
                    typeof props.onSuccess === "function" && props.onSuccess()
                }
            })
            .catch(()=>{
                const isAllowAnonymousRef = refAllowAnonymous.current
                setIsLogin(false)
                if(isAllowAnonymousRef === false){
                    typeof props.onFailed === "function" && props.onFailed()
                }
            })
            .finally(()=>{
                setIsFetching(false)
            })
    }

    const contextValue: ContextState = {
        isLogin,
        isFetching,
        setIsLogin,
        fetchUser,
        token,
        user,
        enableAllowAnonymous: enableAnonymous
    }

    return(
        <Context.Provider value={contextValue} children={props.children}/>
    )
}

export function useAuth(): ContextState{
    return React.useContext(Context)
}

export default AuthProvider