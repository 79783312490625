import React, { FunctionComponent, useEffect, useState } from "react"
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Select } from 'antd';

const { Option } = Select;




interface SelectCustomProps {
    placeholder?: string
    data: Array<any>
    onChange?: (value:any) => void
    defaultValue?: any
    clear?: boolean
    keyValue?: string
}


const SelectCustom: FunctionComponent<SelectCustomProps> = (props) => {

    const { data, clear= false } = props
    const [value,setValue] = useState<any | undefined>()




    useEffect(()=>{
        if(value === undefined || value === null)
            return
        typeof props.onChange === "function" && props.onChange(value)
    },[value])

    function onBlur() {
        if(clear !== undefined &&  clear){
            console.log(null)
            setValue(null)
        }
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val: any) {
        console.log('search:', val);
    }



    const NullReturn = (v?: string) => {
        if(v === null || v === "null")
            return ""
        else
            return  v
    }

    return (
        <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={props.placeholder}
            value={value}
            optionFilterProp="children"
            onChange={props.onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={props.defaultValue}
            onSearch={onSearch}
        // filterOption={(input, option:any) =>
        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // }
        >

            {
               data && data.map((it, index) => {
                    return (
                        <Option value={JSON.stringify(it)} key={index}>
                            {
                                props.keyValue !== undefined ?
                                    NullReturn(it[props.keyValue])
                                    :
                                    NullReturn(it.name)
                            }
                        </Option>
                    )
                })
            }
        </Select>
    )
}

export default SelectCustom