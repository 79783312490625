import { DeleteFilled, EditFilled, PlusCircleFilled, QuestionCircleOutlined } from "@ant-design/icons"
import { AuthUserResponse, BrandResponse, ClientService, SliderResponse, SupplierRequest, SupplierResponse, VisitorResponse } from "@cubetiq/computer-web-sdk"
import { Avatar, TablePaginationConfig } from "antd"
import { Button, message, Switch } from "antd"
import { Popconfirm } from "antd"
import { Spin, Table, Tooltip } from "antd"
import { ColumnType } from "antd/es/table"
import React, { FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { AddIconCustom } from "../../pages/import"
import { getErrorMessage } from "../../util/utils"
import CreateBrandForm from "../form/CreateBrandForm"
import CreateModelForm from "../form/CreateModelForm"
import CreateSilderForm from "../form/CreateSliderForm"
import CreateSupplierForm from "../form/CreateSupplierForm"
import UpdateBrandForm from "../form/UpdateBrandForm"
import UpdateSUpplierForm from "../form/UpdateSupplierForm"
import ModalCustom from "../ModalCustom"
import { ReloadButton } from "./BrandTable"
import { ReloadIcon } from "./ImportTable"
import ModelTable from "./ModelTable"



const TableCustom = styled(Table)`
   padding: 20px 0px 0px 0px;
    .ant-table-expanded-row {
        .ant-table-cell{
            background: none;
        }
        .ant-table-content {
             border-top: 1px solid #f0f0f0;
             border-bottom: 1px solid #f0f0f0;
        }
    }
`


const Container = styled.div`

`


const ImageCustom = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`


const PlusIcon = styled(PlusCircleFilled)`
    font-size: 24px;
    color: #19aa8d;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`



const CreateButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
    font-family: 'Poppins', sans-serif;
    width: 120px;
    &:hover {
        background-color: #00bfff;
        color: white;
        opacity: 0.8;
    }
    &:active {
        background-color: black;
        color: white;
        opacity: 0.8;
    }
`


const EditIcon = styled(EditFilled)`
    font-size: 24px;
    color: #00bfff;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`


const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
    margin-left: 30px;
`



const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

`








const IconContainer = styled.div`

`


const client = ClientService.getInstance()
const SupplierTable: FunctionComponent = () => {


    const [isLoading, setIsLoaindg] = useState(false)
    const [suppliers, setSuppliers] = useState<Array<SupplierResponse>>()
    const [totalCount, setTotalCount] = useState(0)
    const [isCreate, setIsCreate] = useState(false)
    const [selectedSupplier,setSelectedSupplier] = useState<SupplierResponse>()
    const [isUpdate,setIsUpdate] = useState(false)

    useEffect(() => {
        LoadData()
    }, [])

    const LoadData = () => {
        setIsLoaindg(true)
        client.getAllSupplierService({
            size: 10
        }).then(r => {
            setSuppliers(r.data)
            setTotalCount(r.pages.totalCount)
        }).finally(() => setIsLoaindg(false))
    }

    const columns: Array<ColumnType<any>> = [
        {
            title: 'Supplier Code',
            dataIndex: 'code',
            align: "center",
            key: 'code',
            width: 200,
        },
        {
            title: 'Supplier Name',
            dataIndex: 'name',
            align: "center",
            key: 'name',
            width: 200,
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            align: "center",
            key: 'contact',
            width: 200,
        },
        {
            title: 'Memo',
            dataIndex: 'memo',
            align: "center",
            key: 'memo',
            width: 200,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: "center",
            key: 'code',
            width: 200,
            render:(v,row) => {

                return(
                    <IconContainer>
                        <EditIcon onClick={()=>{
                            setSelectedSupplier(row)
                            onIsUpdateHandle()
                        }}/>

                        <Popconfirm title={`Are you sure to delete ( ${row.name} ) ? `} 
                                    onConfirm={()=> onRemoveSupplier(row)}
                                    icon={<QuestionCircleOutlined 
                                    style={{ color: 'red', fontSize: 16 }} />}>
                            <DeleteIcon/>
                        </Popconfirm>
                    </IconContainer>
                )
            }
        }
    ]




    const keyChange = (key: any): string => {
        return key.id
    }


    const PaginationConfig: TablePaginationConfig = {
        onChange: (v) => {
            client.getAllSupplierService({
                page: (v - 1),
                size: 10
            }).then(r => {
                setSuppliers(r.data)
                setTotalCount(r.pages.totalCount)
            })
        },
        total: totalCount,
    }



    const onCreateHandle = () => {
        setIsCreate(!isCreate)
    }

    const onIsUpdateHandle = () => {
        setIsUpdate(!isUpdate)
    }


    const UpdateSupplierModal = () => {
        if (selectedSupplier === undefined)
            return <></>
        return (
            <ModalCustom visible={isUpdate}
                footer={null}
                width={500}
                onCancel={onIsUpdateHandle}
                children={<UpdateSUpplierForm supplier={selectedSupplier}/>}
                 />
        )
    }


    const onRemoveSupplier = (supplier: SupplierResponse) => {
        client.deleteSupplierService(supplier.code).then(r => {
            const data = suppliers?.filter(it => it.code !== supplier.code)
            setSuppliers([...data || []])
            message.success("Delete Supplier Successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }

    return (
        <Container>
            <UpdateSupplierModal/>
            <ModalCustom footer={null}
                visible={isCreate}
                onCancel={onCreateHandle}
                children={<CreateSupplierForm/>}

            />
            <ButtonContainer>
                <CreateButton icon={<AddIconCustom />} children={"Create"} onClick={onCreateHandle} />
                <ReloadButton icon={<ReloadIcon />} children={"Reload"} onClick={LoadData} style={{ marginLeft: 10 }} />
            </ButtonContainer>
            {
                <TableCustom columns={columns}
                    rowKey={(value) => keyChange(value)}
                    bordered
                    loading={isLoading}
                    pagination={PaginationConfig}
                    dataSource={suppliers} />
            }
        </Container>
    )
}

export default SupplierTable