import React from 'react'
import styled from "styled-components";
import {Button, Col, Form, Input, Row} from "antd";
import {MinusCircleOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import {FormProps} from "antd/es/form";


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 24px;
  font-weight: 600;



`

const MinusStyle = styled.span`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const HeaderTitle = styled.div`
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
`


const AddButton = styled(Button)`
  width: 100%;
  border: none;
  background-color: #19aa8d;

  color: white;
  letter-spacing: 1px;
`

const SaveButton = styled(Button)`
  width: 100%;
  border: none;
  background-color: #00bfff;

  color: white;
  letter-spacing: 1px;


  &:hover {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }

  &:focus {
    background-color: #00bfff;
    color: white;
    opacity: 0.8;
  }
  transition: 0.2s;
  
  &:active {
    transform: scale(0.9);
  }

`


interface SerialNumberFormProps extends FormProps {
    values: any[]
    limit?: number
}


const SerialNumberForm:React.FC<SerialNumberFormProps>  = (props) => {


    const { values, limit = 1,  ...rest} = props

    return (
        <Container>
            <HeaderTitle children={"Serial Number"}/>

            <Form name="dynamic_form_item" style={{width: 400}} {...rest}>
                <Form.List
                    name="serials"
                    initialValue={values}
                >
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={[15, 15]} style={{
                                    width: 400,
                                    margin: 0,
                                    height: 60
                                }} key={index}>
                                    <Col style={{
                                        padding: 0,
                                        margin: 0
                                    }}
                                         span={24}>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            initialValue={" "}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input serial number.",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Serial number"
                                                   style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Col>
                                    {/*<Col span={2}>*/}
                                    {/*    <MinusStyle>*/}
                                    {/*        <Form.Item style={{*/}
                                    {/*            display: "flex",*/}
                                    {/*            justifyContent: "center",*/}
                                    {/*            alignItems: "center"*/}
                                    {/*        }}>*/}
                                    {/*            <MinusCircleOutlined*/}
                                    {/*                className="dynamic-delete-button"*/}
                                    {/*                onClick={() => remove(field.name)}*/}
                                    {/*            />*/}
                                    {/*        </Form.Item>*/}
                                    {/*    </MinusStyle>*/}
                                    {/*</Col>*/}
                                </Row>
                            ))}
                            <Form.Item>
                                <Row gutter={[15, 15]}>
                                    <Col span={24}>
                                        <SaveButton
                                            icon={<SaveOutlined/>}
                                            children={"Save"}
                                            htmlType={"submit"}
                                        />
                                        <Form.ErrorList errors={errors}/>
                                    </Col>

                                    {/*<Col span={12}>*/}
                                    {/*    <AddButton*/}
                                    {/*        onClick={() => {*/}
                                    {/*            if(fields.length >= limit) return*/}
                                    {/*            add()*/}

                                    {/*        }}*/}
                                    {/*        icon={<PlusOutlined/>}*/}
                                    {/*    >*/}
                                    {/*        Add Serial*/}
                                    {/*    </AddButton>*/}
                                    {/*    <Form.ErrorList errors={errors}/>*/}
                                    {/*</Col>*/}

                                </Row>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Container>
    )
}

export default SerialNumberForm