import React, {Component, FunctionComponent, useEffect} from "react";
import {useAuth} from "./AuthProvider";
import LoginPage from "../pages/login";
import {ROUTE_URI_ENUM} from "../routes/paths";
import {useHistory} from "react-router-dom";
import LoadingCustom from "../components/loading/LoadingCustom";

interface AuthCheckComponentConfig{
    allowAnonymous?: boolean
}

interface AuthCheckComponentPops extends AuthCheckComponentConfig{}

const AuthCheckComponent: React.FC<{} & AuthCheckComponentPops | undefined> = (props) => {

    const router = useHistory();
    const {isLogin, isFetching, fetchUser, enableAllowAnonymous} = useAuth()

    const {allowAnonymous} = props

    useEffect(()=>{
        if(allowAnonymous){
            enableAllowAnonymous!()
        }
    },[])

    useEffect(()=>{
        if(isLogin === undefined){
            if(isFetching === false){
                typeof fetchUser === "function" && fetchUser()
            }
        }
    },[isLogin, isFetching])

    if(isLogin === undefined){
        if(allowAnonymous === false)
            return  <LoadingCustom/>
    }

    if(!isLogin){
        if(allowAnonymous === false){
            router.push(ROUTE_URI_ENUM.LOGIN)
            return<></>
        }
    }

    return(
        <div>{props.children}</div>
    )
}

AuthCheckComponent.defaultProps = {
    allowAnonymous: false
}

export const withAuth = (config?: AuthCheckComponentConfig) => (Component: React.FunctionComponent) => {
    return (props: any) => (
        <AuthCheckComponent {...config} >
            <Component {...props}/>
        </AuthCheckComponent>
    )
}

export default AuthCheckComponent