// @ts-nocheck

import { DeleteFilled, PlusCircleOutlined } from "@ant-design/icons"
import { ClientService, ItemResponse, ItemSpecificationRequest, Specification, SpecificationResponse } from "@cubetiq/computer-web-sdk"
import { SpecificationRequest } from "@cubetiq/computer-web-sdk/dist/interface/request/specification/SpecificationRequest"
import { Col, Input, message } from "antd"
import { Button } from "antd"
import { Spin } from "antd"
import { Row } from "antd"
import { Form } from "antd"
import React,{ FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"
import SelectCustom from "../SelectCustom"




interface UpdateItemSpecificationFormProps {
    item: ItemResponse
}


const Container = styled.div`


`


const ImportButton = styled(Button)`
    background-color: #00bfff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: white;
     font-family: 'Poppins', sans-serif;
     width: 120px;
     &:hover {
        background-color: #808080;
        color: white;
     }
`

const DeleteIcon = styled(DeleteFilled)`
    font-size: 24px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`

const ButtonSubmit= styled(Button)`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 5px;
    border: none;
    background-color: #19aa8d;
    color: white;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
`

const client= ClientService.getInstance()
const UpdateItemSpecificationForm:FunctionComponent<UpdateItemSpecificationFormProps> = (props) => {

    const {  item  } = props
    const [specifications,setSpecification] = useState<Array<SpecificationResponse>>([])
    const [itemSpecification,setItemSpecification] = useState<Array<Specification>>(item.specifications)
    const [isLoading,setIsLoading] = useState(false)



    

    useEffect(()=>{
        client.getAllSpecificationService({
            paged: false
        }).then( r=> setSpecification(r.data))
    },[])


    useEffect(()=>{
        if(item === null)
            return
            setItemSpecification(item.specifications)
    },[item])

    const form = Form.useForm()[0]

    const onFinish = (values:any) => {
        setIsLoading(true)

        const request = values["itemSpecification"].map((it:any) => {

            if(typeof it["specification"] === "string"){
               
                const json = JSON.parse(it["specification"])
                const req : ItemSpecificationRequest = {
                    id: json.id,
                    value: it["value"]
                }
                return req
            }

            const req: ItemSpecificationRequest = {
                id: it["specification"].id,
                value: it["value"]
            }
            return req
        })

        client.updateItemSpecificationService(item.code,request).then(r => {
            message.success("Update Successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=> setIsLoading(false))
    }



    return (
       <Container>
           <Form onFinish={onFinish} form={form}>
           <Form.List name="itemSpecification" initialValue={itemSpecification}>
                    {(fields, { add, remove }) => (
                        <>
                            <Row>
                                <Col md={24} lg={24}>
                                   <Form.Item>
                                       <ImportButton children={"Add Item"}
                                                     onClick={() => {
                                                         add()
                                                     }}
                                                     icon={<PlusCircleOutlined style={{ fontSize: 18 }}/>}/>
                                   </Form.Item>
                                </Col>
                            </Row>

                            {fields.map(field => (
                                <div key={field.key} >

                                    <Row gutter={[15,15]}>
                                        <Col span={12}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'specification']}
                                                fieldKey={[field.fieldKey, 'specification']}
                                                rules={[{ required: true, message: 'Please choose items to import!' }]}
                                                initialValue={itemSpecification?.[field.key]}
                                            >

                                                <SelectCustom data={specifications} 
                                                              defaultValue={itemSpecification?.[field.key]?.name}/>

                                            </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'value']}
                                                fieldKey={[field.fieldKey, 'value']}
                                                rules={[{ required: true, message: 'Please input quantity !' }]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                       

                                        <Col span={1} style={{paddingTop: 5}}>
                                            <DeleteIcon onClick={() => {
                                                remove(field.name)
                                            }} />
                                        </Col>
                                    </Row>

                                </div>
                            ))}
                        </>
                    )}
                </Form.List>

                <Form.Item style={{marginBottom: 0}}>
                    <Spin spinning={isLoading}>
                        <ButtonSubmit children={"Save"} htmlType={"submit"}/>
                    </Spin>
                </Form.Item>
                
           </Form>
       </Container>
    )
}

export default UpdateItemSpecificationForm