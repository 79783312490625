// @ts-nocheck

import {MinusCircleOutlined} from "@ant-design/icons";
import {
    AdminService,
    BrandResponse,
    CategoryResponse,
    ClientService, DiscountType,
    ItemResponse,
    ItemSpecificationRequest,
    ItemUpdateRequest,
    ModelResponse,
    Specification,
    SpecificationResponse,
    SubCategoryResponse,
} from "@cubetiq/computer-web-sdk";
import {AutoComplete, Button, Col, Form, Input, InputNumber, message, Row, Spin, Switch} from "antd";

import React, {FunctionComponent, useEffect, useState} from "react";
import styled from "styled-components";
import SelectCustom from "../../components/SelectCustom";
import UpdateItemUploadCustom from "../../components/upload/UpdateItemUploadCustom";
import MainLayout from "../MainLayout";
import {arrayNotInValue} from "../../util/ArrayUtils";
import {getErrorMessage} from "../../util/utils";
import TextEditor from "../../components/text-editor/TextEditor";
import SpecificationUpdateButtonCustom from "../../components/CustomButton/SpecificationUpdateButtonCustom";
import {withAuth} from "../../provider/AuthWrapper";
import {ProductUsedData} from "./CreateItemPage";
import SelectDiscountType from "../order/component/SelectDiscountType";
import { useHistory } from "react-router";


const Container = styled.div`

`

const FormCustom = styled(Form)`
  margin-top: 10px;
`


const ButtonSubmit = styled(Button)`
  width: 100%;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 5px;
  border: none;
  background-color: #19aa8d;
  color: white;
  height: 45px;
  font-size: 24px;
  font-weight: bold;
`


const admin = AdminService.getInstance()

const client = ClientService.getInstance()
const UpdateItemPage: FunctionComponent = () => {

    const [item, setItem] = useState<ItemResponse>()
    const [urls, setUrls] = useState<Array<string>>([])
    const [specifications, setSpecifications] = useState<Array<SpecificationResponse>>()
    const [selectedSpecification, setSelectedSpecification] = useState<Array<Specification>>([])
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory();

    useEffect(() => {

        setIsLoading(true)
        const path = window.location.pathname
        const variable = path.split("/items/update/")
        const code = variable[1] ?? ""
        admin.adminGetItemByCodeService(code).then((res: any) => {
            console.log(res)
            setItem(res.data)
            client.getAllSpecificationService({
                paged: false
            }).then(r => {
                const unselected = arrayNotInValue(r.data, res.data?.specifications || [])
                setSelectedSpecification(res.data?.specifications)
                setSpecifications(unselected)
            }).finally(() => setIsLoading(false))
        })
    }, [])


    useEffect(() => {
        if (item === undefined)
            return
        setUrls(item?.attachments?.map((it: any) => it.originalLink!!) || [])
        setIsNewItem(item.isNewItem)
    }, [item])


    const [categories, setCategories] = useState<Array<CategoryResponse>>([])
    const [subCategories, setSubCategories] = useState<Array<SubCategoryResponse>>([])
    const [brands, setBrand] = useState<Array<BrandResponse>>([])
    const [models, setModels] = useState<Array<ModelResponse>>([])
    const [isCreate, setIsCreate] = useState(false)
    const [isNewItem, setIsNewItem] = useState(false)


    useEffect(() => {
        client.getAllCategoryService({paged: false}).then((r) => {
            setCategories(r.data)
        })
        client.getAllBrandService({brands: false}).then(r => {
            setBrand(r.data)
        })
    }, [])


    const onSpecificationSelectedHandle = (value: Specification) => {
        setSelectedSpecification([...selectedSpecification || [], value])
        const newValue = specifications?.filter(it => it.id !== Number(value.id))
        setSpecifications([...newValue || []])
        setIsLoading(false)
    }


    const onFinish = (values: any) => {
        setIsCreate(true)
        const spec: ItemSpecificationRequest[] = selectedSpecification.map((it, index) => {
            const req: ItemSpecificationRequest & { name: string } = {
                id: it.id!!,
                value: values["specification"][index].value,
                name: it.name!!
            }
            return req
        })


        const categoryId = getSelectedValue(values["category"]) === "null" ? null : getSelectedValue(values["category"])

        const subCategoryId = getSelectedValue(values["subCategory"]) === "null" ? null : getSelectedValue(values["subCategory"])

        const brandId = getSelectedValue(values["brand"]) === "null" ? null : getSelectedValue(values["brand"])

        const modelId = getSelectedValue(values["model"]) === "null" ? null : getSelectedValue(values["model"])


        const request: ItemUpdateRequest = {
            name: values["name"],
            price: values["price"],
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            brandId: brandId,
            modelId: modelId,
            memo: values["memo"] || item?.memo,
            specifications: spec,
            discount: values["discount"] || 0,
            isNewItem: isNewItem,
            attachments: urls,
            productUsed: values["productUsed"],
            isShowUnavailable: values["isShowUnavailable"],
            discountType: values["discountType"] ?? DiscountType.PERCENTAGE
        }

        client.updateItemService(item?.code!!, request).then(r => {
            message.success("Update Item Successfully").then()
            history.push("/items");
        }).catch(r => {
            message.error(getErrorMessage(r)).then()
        }).finally(() => setIsCreate(false))

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    const onCategoryChange = (v: any) => {
        const json: CategoryResponse = JSON.parse(v)
        setSubCategories(json.subCategory)
    }


    const onBrandChange = (v: any) => {
        const json: BrandResponse = JSON.parse(v)
        setModels(json.model)
    }


    const getSelectedValue = (value: any) => {
        if (value === undefined || value === null)
            return 0

        if (typeof value === "string") {
            return JSON.parse(value).id
        } else {
            return value.id
        }
    }


    const onRemoveSpecificationSelected = (index: number) => {
        const remove = selectedSpecification

        const add = selectedSpecification[index]
        const spec: SpecificationResponse = {
            id: Number(add.id),
            code: add.id!!,
            name: add.name!!,
            config: {}
        }
        setSpecifications([...specifications || [], spec])
        remove.splice(index, 1)
        setSelectedSpecification([...remove])
    }





    return (
        <MainLayout>
            {
                item &&
                <Container>
                    {/* <MultipleUploadCustom onChange={setFiles} /> */}

                    <UpdateItemUploadCustom defaultUrls={item?.attachments?.map(it => it.originalLink!!) || []}
                                            onChange={setUrls}/>


                    <FormCustom onFinish={onFinish}
                                layout={"vertical"}
                                onFinishFailed={onFinishFailed}>

                        <Row gutter={[16, 0]}>
                            <Col span={6}>
                                <Form.Item name={"name"}
                                           label={"Name"}
                                           initialValue={item.name}
                                           required>
                                    <Input/>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name={"price"}
                                           label={"Price"}
                                           initialValue={item.price}
                                           required>
                                    <InputNumber style={{width: "100%"}}/>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name={"category"}
                                           initialValue={item.category}
                                           label={"Category"}>
                                    <SelectCustom data={categories}
                                                  defaultValue={item.category.name ?? ""}
                                                  onChange={(v) => onCategoryChange(v)}/>
                                </Form.Item>
                            </Col>


                            <Col span={6}>
                                <Form.Item name={"subCategory"}
                                           initialValue={item.subCategory}
                                           label={"Sub Category"}>
                                    <SelectCustom data={subCategories}
                                                  defaultValue={item.subCategory.name ?? ""}/>
                                </Form.Item>
                            </Col>


                            <Col span={6}>
                                <Form.Item name={"brand"}
                                           initialValue={item.brand}
                                           label={"Brand"}>
                                    <SelectCustom data={brands}
                                                  defaultValue={item.brand?.name ?? ""}
                                                  onChange={(v) => onBrandChange(v)}/>
                                </Form.Item>
                            </Col>


                            <Col span={6}>
                                <Form.Item name={"model"}
                                           initialValue={item.model}
                                           label={"Model"}>
                                    <SelectCustom data={models}
                                                  defaultValue={item.model?.name ?? ""}/>
                                </Form.Item>
                            </Col>


                            <Col span={3}>
                                <Form.Item name={"discount"}
                                           label={"Discount"}
                                           initialValue={item.discount?.discountValue}>
                                    <InputNumber style={{width: "100%"}} min={0} max={100}/>
                                </Form.Item>
                            </Col>


                            <Col span={3}>
                                <Form.Item name={"discountType"}
                                           label={"Discount Type"}
                                           initialValue={item.discount?.discountType}>
                                    <SelectDiscountType/>
                                </Form.Item>
                            </Col>


                            <Col span={6}>
                                <Form.Item name={"productUsed"}
                                           label={"Product Used"} initialValue={item.productUsed}>
                                    <AutoComplete
                                        options={ProductUsedData}
                                        filterOption={(inputValue: any, option: any) =>
                                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name={"isShowUnavailable"}
                                           valuePropName="checked"
                                           initialValue={item.isShowUnavailable}
                                           label={"Show Unavailable"}>
                                    <Switch checkedChildren="On" unCheckedChildren="Off" defaultChecked/>
                                </Form.Item>
                            </Col>
                        </Row>


                        {
                            isLoading ?
                                <></>
                                :
                                <Form.List name="specification" initialValue={selectedSpecification}>
                                    {
                                        (fields, {add, remove}) => (

                                            <>
                                                <SpecificationUpdateButtonCustom onChange={(v) => {
                                                    add()
                                                    onSpecificationSelectedHandle(v)
                                                }} defaultValue={specifications || []}/>

                                                <Row gutter={[16, 0]}>
                                                    {
                                                        fields.map((field, index) => (
                                                            <React.Fragment key={index}>
                                                                <Col span={5}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[field.name, 'value']}
                                                                        label={selectedSpecification?.[index]?.name}
                                                                        fieldKey={[field.fieldKey, 'value']}
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'Please choose items to import!'
                                                                        }]}>
                                                                        <Input/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={1} style={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <MinusCircleOutlined onClick={() => {
                                                                        remove(field.name)
                                                                        onRemoveSpecificationSelected(index)
                                                                    }}/>
                                                                </Col>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </Row>
                                            </>

                                        )
                                    }
                                </Form.List>

                        }


                        <Row>
                            <Col md={24} lg={24}>
                                <Form.Item name={"memo"}>
                                    <TextEditor defaultValue={item.memo ?? ''}/>
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row>
                            <Col md={24} lg={24}>
                                <Form.Item>
                                    <Spin spinning={isCreate} size={"large"}>
                                        <ButtonSubmit children={"Save"} htmlType={"submit"}/>
                                    </Spin>
                                </Form.Item>
                            </Col>
                        </Row>
                    </FormCustom>
                </Container>
            }
        </MainLayout>
    )
}

export default withAuth()(UpdateItemPage)