import { ClientService, ItemCostUpdate, ItemRequest, ItemResponse } from "@cubetiq/computer-web-sdk";
import { Form, Input, InputNumber, message } from "antd";
import React, { FunctionComponent, useEffect } from "react";
import { CreateButton } from "../../pages/import";
import { getErrorMessage } from "../../util/utils";


interface UpdateCostFormProps {
    item: ItemResponse
}


const client = ClientService.getInstance()
const UpdateCostForm: FunctionComponent<UpdateCostFormProps> = (props) => {

    const { item } = props


    const onFinish = (values: any) => {
        const request: ItemCostUpdate = {
            cost: values["cost"]
        }
        client.updateCostItemService(item.code, request).then(r => {
            message.success("Cost update successfully")
        }).catch(r => {
            message.error(getErrorMessage(r))
        })
    }


    return (
        <Form onFinish={onFinish}
            layout={"vertical"}>
            <Form.Item
                initialValue={item.cost}
                label={"Cost"}
                name={"cost"}
                required
            >
                <InputNumber min={0} max={9999999} style={{ width: "100%" }} defaultValue={item.cost} />
            </Form.Item>
            <Form.Item>
                <CreateButton children={"Save Change"} style={{ width: "100%" }} htmlType={"submit"} />
            </Form.Item>
        </Form>
    )
}

export default UpdateCostForm