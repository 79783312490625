import {Select, SelectProps} from "antd";
import React, {useEffect, useState} from "react";


interface SelectTagCustomProps extends SelectProps<any> {
    data: any[],
    field?: string,
    limit: number,
}

const {Option} = Select;

const SelectTagCustom: React.FC<SelectTagCustomProps> = (props) => {



    const {  data, field , limit } = props

    const [selected,setSelected] = useState<any[]>([])

    const handleChange = (value: any[],option:any) => {
        typeof props.onChange === "function" && props.onChange(value,option)
        setSelected([...value])
        return value
    };


    useEffect(()=> {
        if(props.defaultValue !== undefined){
            const d = props.defaultValue as any[]
            const curr = d.map(it => it.code)
            setSelected([...curr])
        }
    },[props.defaultValue])



    return (
        <Select
            mode="multiple"
            style={{width: '100%'}}
            optionLabelProp="label"
            onChange={handleChange}
            placeholder={props.placeholder}
            value={selected}>
            {
                data.map((it,index) => {
                    return(
                        <Option value={it.code}
                                label={
                                    field !== undefined ? it[field] : it
                                }
                                disabled={
                                    selected.length >= limit
                                        ? !selected.includes(it.code)
                                        : false
                                }
                                key={index}>
                            {
                                field !== undefined ? it[field] : it
                            }
                        </Option>
                    )
                })
            }

        </Select>
    )
}
export default SelectTagCustom