
import { BrandResponse, CategoryRequest, ClientService } from "@cubetiq/computer-web-sdk"
import { BrandRequest } from "@cubetiq/computer-web-sdk/src/interface/request/brand/BrandRequest"
import { ModelRequest } from "@cubetiq/computer-web-sdk/src/interface/request/model/ModelRequest"
import { Button, message, Spin } from "antd"
import { Form, Input } from "antd"
import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { getErrorMessage } from "../../util/utils"
import FileSelectWithPreview from "../upload/FileSelectWithPreview"





const Container = styled.div`


`


interface CreateBrandFormProps {
    
}

const ButtonSubmit= styled(Button)`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 5px;
    border: none;
    background-color: #19aa8d;
    color: white;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
`

const Style = styled.div`
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`



const client = ClientService.getInstance()
const CreateCategoryForm: FunctionComponent<CreateBrandFormProps> = (props) => {
    const [isLoading,setIsLoading] = useState(false)
    const [file,setFile] = useState<File>()
    const form = Form.useForm()[0]
    

    const onFinish = (values:any) => {
        setIsLoading(true)
        if(file === undefined){
            message.error("File image can not empty !")
            return
        }
        

        client.otherUploadFileService(file).then(r => {
            const request: CategoryRequest = {
                name: values["name"],
                config: {
                    icon: r.data.url
                }
            }
            client.createCategoryService(request).then(r => {
                message.success("Create Category Successfully")
            }).catch(r => {
                message.error(getErrorMessage(r))
            })
        }).catch(r => {
            message.error(getErrorMessage(r))
        }).finally(()=> setIsLoading(false))

        form.resetFields()
    }


    


    return (
        <Container>
            <Form layout={"vertical"} onFinish={onFinish} form={form}>

                <Style>
                    <Form.Item name={"file"}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                        <FileSelectWithPreview onChange={setFile}/>
                    </Form.Item>
                </Style>


                <Form.Item name={"name"}
                    hasFeedback
                    label={"Category Name"}
                    required rules={[
                        {
                            required: true,
                            message: "Please input name"
                        }]}>
                        <Input/>
                </Form.Item>

                <Form.Item style={{marginBottom: 0}}>
                    <Spin spinning={isLoading}>
                        <ButtonSubmit children={"Create"} htmlType={"submit"}/>
                    </Spin>
                </Form.Item>
            </Form>
        </Container>
    )

}

export default CreateCategoryForm