import {CloseCircleOutlined} from "@ant-design/icons";
import React, {FunctionComponent} from "react";
import styled from "styled-components";


interface ImageCardProps {
    file: File,
    index: number,
    setFile: (value:any) => void,
    files: FileList
}


const Container = styled.div`
    width: 150px ; 
    height: 150px;
    object-fit: contain;
    border: 1px solid #ccc;
    margin-top: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: white;
    border-radius: 4px;
`


const IconCustom = styled(CloseCircleOutlined)`
    font-size: 24px;
    margin: 5px 5px 0px 0px;
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
`

const ImageCard:FunctionComponent<ImageCardProps> = (props) => {

    const { file,setFile,index,files } = props 


    const onRemoveHandle = () => {
        const arrayFile = Array.from(files)
        arrayFile.splice(index,1);
        setFile(arrayFile)
    }

    return(
        <Container style={{backgroundImage: `url(${URL.createObjectURL(file)})`}}>
            <IconCustom onClick={onRemoveHandle}/>
        </Container>
    )
}

export default ImageCard;